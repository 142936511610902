import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, useState } from "react";
import Inputfield from "../../common/component/InputField";
import translate from "../../../i18n/translate";
import { urlPatternValidation, } from "../../../static/js/commons";
import { useLocation } from "react-router-dom";
import { updateStudentApplication } from "../ApplicationSlice";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
export default function StudentInputLink({ id, componentId, displayLabel, appTemplateView, isMandatory, componentObj, setUpdatedTemplateComponentsList, findUrlValue, errorMap, setErrorMap, applicationTemplateUuid, isExternal, }) {
    const inputRef = useRef();
    const [invalidUrl, setInvalidUrl] = useState(false);
    const [responseText, setResponseText] = useState("");
    const [linkTitle, setLinkTitle] = useState("");
    const location = useLocation();
    const dispatch = useDispatch();
    const requiredErrorMessage = useIntl().formatMessage({
        id: "app.programApplications.student.application.card.response.required.error.message",
    });
    const validateURLHandler = (event) => {
        const { value } = event.target;
        setInvalidUrl(value ? !urlPatternValidation(value) : false);
    };
    const changeUrlResponseHandler = (templateUuid, templateComponentUuid, responseText) => {
        if (setErrorMap) {
            setErrorMap(componentObj, templateUuid);
        }
        setResponseText(responseText);
        let title = findUrlValue(templateUuid, templateComponentUuid, false);
        dispatch(updateStudentApplication({
            templateUuid,
            templateComponentUuid,
            responseText,
            linkTitle: linkTitle ? linkTitle : title,
        }));
    };
    const updateLinkTitleHandler = (templateUuid, templateComponentUuid, linkTitle) => {
        setLinkTitle(linkTitle);
        let displayText = findUrlValue(templateUuid, templateComponentUuid, true);
        dispatch(updateStudentApplication({
            templateUuid,
            templateComponentUuid,
            responseText: responseText ? responseText : displayText,
            linkTitle,
        }));
    };
    const urlChangeHandler = (event, compId) => {
        event.preventDefault();
        if (id.length >= 36) {
            setUpdatedTemplateComponentsList((l) => l === null || l === void 0 ? void 0 : l.map((parentObj) => {
                let updatedChild = parentObj === null || parentObj === void 0 ? void 0 : parentObj.templateComponentList.map((childObj) => {
                    return childObj.sortOrder === compId
                        ? Object.assign(Object.assign({}, childObj), { value: event.target.value }) : childObj;
                });
                return Object.assign(Object.assign({}, parentObj), { templateComponentList: updatedChild });
            }));
        }
        else {
            setUpdatedTemplateComponentsList((l) => l === null || l === void 0 ? void 0 : l.map((list, index) => index === compId ? Object.assign(Object.assign({}, list), { value: event.target.value }) : list));
        }
    };
    const displayTextChangeHandler = (event, compId) => {
        event.preventDefault();
        if (id.length >= 36) {
            setUpdatedTemplateComponentsList((l) => l === null || l === void 0 ? void 0 : l.map((parentObj) => {
                let updatedChild = parentObj === null || parentObj === void 0 ? void 0 : parentObj.templateComponentList.map((childObj) => {
                    return childObj.sortOrder === compId
                        ? Object.assign(Object.assign({}, childObj), { displayText: event.target.value }) : childObj;
                });
                return Object.assign(Object.assign({}, parentObj), { templateComponentList: updatedChild });
            }));
        }
        else {
            setUpdatedTemplateComponentsList((l) => l === null || l === void 0 ? void 0 : l.map((list, index) => index === compId ? Object.assign(Object.assign({}, list), { displayText: event.target.value }) : list));
        }
    };
    const urlValueHandler = () => {
        var _a, _b;
        return ((_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.sourcePage) === "StudentLandingPage" ||
            ((_b = location === null || location === void 0 ? void 0 : location.state) === null || _b === void 0 ? void 0 : _b.sourcePage) === "StudentLicensurePlanView" ||
            isExternal
            ? findUrlValue(applicationTemplateUuid == undefined ? "" : applicationTemplateUuid, componentObj.uuid, true)
            : appTemplateView
                ? ""
                : componentObj.value;
    };
    const updateUrl = (event) => {
        var _a, _b;
        event.preventDefault();
        return ((_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.sourcePage) === "StudentLandingPage" ||
            ((_b = location === null || location === void 0 ? void 0 : location.state) === null || _b === void 0 ? void 0 : _b.sourcePage) === "StudentLicensurePlanView" ||
            isExternal
            ? changeUrlResponseHandler(applicationTemplateUuid == undefined ? "" : applicationTemplateUuid, componentObj.uuid, event.target.value)
            : appTemplateView
                ? () => { }
                : urlChangeHandler(event, componentObj.sortOrder);
    };
    const displayTextValueHandler = () => {
        var _a, _b;
        return ((_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.sourcePage) === "StudentLandingPage" ||
            ((_b = location === null || location === void 0 ? void 0 : location.state) === null || _b === void 0 ? void 0 : _b.sourcePage) === "StudentLicensurePlanView" ||
            isExternal
            ? findUrlValue(applicationTemplateUuid == undefined ? "" : applicationTemplateUuid, componentObj.uuid, false)
            : appTemplateView
                ? ""
                : componentObj.displayText;
    };
    const updateDisplayText = (event) => {
        var _a, _b;
        event.preventDefault();
        return ((_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.sourcePage) === "StudentLandingPage" ||
            ((_b = location === null || location === void 0 ? void 0 : location.state) === null || _b === void 0 ? void 0 : _b.sourcePage) === "StudentLicensurePlanView" ||
            isExternal
            ? updateLinkTitleHandler(applicationTemplateUuid == undefined ? "" : applicationTemplateUuid, componentObj.uuid, event.target.value)
            : appTemplateView
                ? () => { }
                : displayTextChangeHandler(event, componentObj.sortOrder);
    };
    const isError = errorMap === null || errorMap === void 0 ? void 0 : errorMap.get(componentObj.componentType.toLowerCase() +
        (applicationTemplateUuid === undefined
            ? ""
            : applicationTemplateUuid + componentObj.uuid));
    return (_jsxs("div", Object.assign({ className: "student-link-input-component" }, { children: [_jsx("label", Object.assign({ className: "link-student-label", htmlFor: displayLabel + componentId + (componentObj === null || componentObj === void 0 ? void 0 : componentObj.uuid), tabIndex: 0 }, { children: displayLabel })), _jsxs("div", Object.assign({ className: "flex-row -justified -verticallycentered student-link-input-fields", id: displayLabel + componentId + (componentObj === null || componentObj === void 0 ? void 0 : componentObj.uuid) }, { children: [_jsx("div", Object.assign({ className: "link-studnt-comp-inputs url-input" }, { children: _jsxs(_Fragment, { children: [_jsxs("label", Object.assign({ className: "textfieldlabel " + (invalidUrl || isError ? "alert-label" : ""), htmlFor: componentId ? "input-" + componentId + "-url-" + id : "" }, { children: ["URL", isMandatory && _jsx("span", Object.assign({ className: "reqd" }, { children: "*" })), invalidUrl ||
                                            (isError && _jsx("span", { className: "alert-icon mdi mdi-alert" }))] })), _jsxs("div", Object.assign({ className: "textfield " + (invalidUrl || isError ? "alert-box" : "") }, { children: [_jsx("div", Object.assign({ className: "charcount" }, { children: _jsx("input", { className: "textfieldinput " + (invalidUrl ? "invalid" : ""), id: componentId ? "input-" + componentId + "-url-" + id : "", type: "text", value: urlValueHandler(), onChange: (event) => updateUrl(event), onBlur: validateURLHandler, ref: inputRef, "aria-describedby": "title-error-" + componentId + id, disabled: appTemplateView }) })), !appTemplateView && (_jsx("div", Object.assign({ className: "error-info" }, { children: _jsx("div", Object.assign({ className: "error input-error", id: "title-error-" + componentId + id }, { children: invalidUrl
                                                    ? translate("app.programApplications.insertComponent.resource.link.error.message.invalid")
                                                    : isError
                                                        ? requiredErrorMessage
                                                        : "" })) })))] }))] }) })), _jsx("div", Object.assign({ className: "link-studnt-comp-inputs display-txt" }, { children: _jsx(Inputfield, { titleError: false, dataLabel: "Link Display Text", modalTitle: displayTextValueHandler(), updateTitleHandler: (event) => updateDisplayText(event), inputRef: inputRef, modalFor: "display-text", modalId: componentId + "-display-text-" + id, disabled: appTemplateView, maxLength: 200 }) }))] }))] })));
}
