import translate from "../../../i18n/translate";

export const RecordMatchingTagList = [
  {
    value_i18n: translate("app.programApplications.external.application.no.available.system.match.label"),
    tag_i18n: translate("app.programApplications.external.application.no.available.system.match.label"),
    key: "NO_MATCH",
    value: "NO MATCH",
  },
  {
    value_i18n: translate("app.programApplications.external.application.user.updated.match.label"),
    tag_i18n: translate("app.programApplications.external.application.user.updated.match.label"),
    key: "UPDATED_MATCH",
    value: "UPDATED MATCH",
  },
];