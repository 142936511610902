import translate from "../../../i18n/translate";

export const requirementResultStatus = [
  {
    value_i18n: translate("app.requirementResultStatus.notSubmitted"),
    key: "NOT_SUBMITTED",
    value: "Not Submitted",
  },
  {
    value_i18n: translate("app.requirementResultStatus.awaitingReview"),
    key: "AWAITING_REVIEW",
    value: "Awaiting Review",
  },
  {
    value_i18n: translate("app.requirementResultStatus.met"),
    key: "MET",
    value: "Met",
  },
  {
    value_i18n: translate("app.requirementResultStatus.notMet"),
    key: "NOT_MET",
    value: "Not Met",
  },  
  {
    value_i18n: translate("app.requirementResultStatus.notSubmitted"),
    key: "NOT_SUBMITTED_DRAFT",
    value: "Not Submitted",
  },
];
