import translate from "../../../i18n/translate";

export const PlanStatus = [
  {
    value_i18n: translate("app.programCohortstatus.notConnected"),
    tag_i18n: translate("app.programCohortstatus.notConnected"),
    key: "NOT_CONNECTED",
    value: "Not Connected",
  },
  {
    value_i18n: translate("app.programCohortstatus.notInUse"),
    tag_i18n: translate("app.programCohortstatus.notInUse"),
    key: "NOT_IN_USE",
    value: "Not In Use",
  },
  {
    value_i18n: translate("app.programCohortstatus.inUse"),
    tag_i18n: translate("app.programCohortstatus.inUse"),
    key: "IN_USE",
    value: "In Use",
  },
  {
    value_i18n: translate("app.programCohortstatus.archived"),
    tag_i18n: translate("app.programCohortstatus.archived"),
    key: "ARCHIVED",
    value: "Archived",
  },

];
