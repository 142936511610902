import translate from "../../../i18n/translate";

export const cohortStatusFilterData = [
  {
    value_i18n: translate("app.cohortStatus.filter.active"),
    value: "Active",
    key: "ACTIVE",
  },
  {
    value_i18n: translate("app.cohortStatus.filter.archived"),
    value: "Archived",
    key: "ARCHIVED",
  },
];
