var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from "react";
import FileType from "file-type/browser";
import { useDispatch } from "react-redux";
import { fetchWrapper } from "../fetchWrapper";
import { API_APPLICATION_ROOT, API_ROOT } from "../../../env-config";
import { isNullOrEmpty, isValidUuid } from "../../../static/js/commons";
const useFileupload = (config) => {
    let backendHost = config.app === "APPLICATIONS" ? API_APPLICATION_ROOT : API_ROOT;
    const acceptedMIMETypesForApplicationTemplate = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "image/jpeg",
        "image/png",
        "image/gif",
        "text/csv",
        "text/plain",
        "application/xml",
        "text/xml",
    ];
    let [attachedFiles, setAttachedFiles] = useState(config.initialAttachments ? config.initialAttachments : []);
    let [deletedFiles, setDeletedFiles] = useState(config.initialDeletedFiles ? config.initialDeletedFiles : []);
    let [rejectedFiles, setRejectedFiles] = useState([]);
    let [count, setCount] = useState(0);
    let [uploadInProgress, setUploadInProgress] = useState(false);
    let [deleteInProgress, setDeleteInProgress] = useState(false);
    let [hookMetadata, setHookMetadata] = useState(config.metadata);
    let [currentFileId, setCurrentFileId] = useState([]);
    useEffect(() => {
        if (count === 0) {
            setUploadInProgress(false);
        }
    }, [count]);
    let dispatch = useDispatch();
    const validateFile = (event, file) => {
        const sizeLimit = event.target.maxSize && parseInt(event.target.maxSize.split(" ")[0]);
        let fileTypeList = event.target.fileTypes.split(" ");
        let fileType = file.name.substr(file.name.lastIndexOf(".") + 1, file.name.length);
        if (event.target.maxSize && (file === null || file === void 0 ? void 0 : file.size) / (1024 * 1024) > sizeLimit) {
            return "The file is larger than maximum size " + event.target.maxSize;
        }
        else if (attachedFiles.some((currentFile) => currentFile.file_name === file.name)) {
            return "A file named " + file.name + " has already been uploaded.";
        }
        else if (!fileTypeList.includes(fileType)) {
            return "." + fileType + " is not an accepted file type.";
        }
        else {
            return "";
        }
    };
    const onWmUploaderFilesSelected = (event) => __awaiter(void 0, void 0, void 0, function* () {
        event.preventDefault();
        setRejectedFiles([]);
        let files = event.detail;
        let data = null;
        let legalFiles = [];
        if (event.target.maxFiles &&
            attachedFiles.length + files.length > event.target.maxFiles) {
            event.target.errorMessage =
                "Upload unsuccessful because file selection would exceed the maximum number of files";
            return;
        }
        else {
            for (var i = 0; i < files.length; i++) {
                let msg = validateFile(event, files[i]);
                let name = files[i].name;
                let fileExtType = files[i].type === "" && name.endsWith(".log")
                    ? "text/x-log"
                    : files[i].type;
                if (!isNullOrEmpty(msg)) {
                    setRejectedFiles((prev) => {
                        return [
                            ...prev,
                            {
                                uuid: name + rejectedFiles.length,
                                file_name: name,
                                file_path: "",
                                progress: 0,
                                errorMessage: msg,
                            },
                        ];
                    });
                }
                else {
                    yield FileType.fromBlob(files[i]).then((res) => {
                        if (acceptedMIMETypesForApplicationTemplate.includes(res === null || res === void 0 ? void 0 : res.mime)) {
                            legalFiles.push(files[i]);
                        }
                        else if (["text/x-log", "text/csv", "text/xml", "text/plain"].includes(fileExtType)) {
                            legalFiles.push(files[i]);
                        }
                    });
                }
            }
        }
        if ((legalFiles === null || legalFiles === void 0 ? void 0 : legalFiles.length) > 0) {
            setCount(legalFiles.length);
            if (config.useBase64Format) {
                setUploadInProgress(true);
                legalFiles === null || legalFiles === void 0 ? void 0 : legalFiles.map((obj, index) => __awaiter(void 0, void 0, void 0, function* () {
                    let base64 = (yield getBase64(obj));
                    setAttachedFiles((prev) => {
                        return [
                            ...prev,
                            {
                                uuid: obj.name,
                                file_name: obj.name,
                                file_path: obj.path,
                                base64: base64,
                                mime_type: obj.type === "" && obj.name.endsWith(".log")
                                    ? "text/x-log"
                                    : obj.type,
                                inserted_at: new Date().toISOString(),
                            },
                        ];
                    });
                }));
                setUploadInProgress(false);
            }
            else {
                setUploadInProgress(true);
                legalFiles === null || legalFiles === void 0 ? void 0 : legalFiles.map((obj, index) => __awaiter(void 0, void 0, void 0, function* () {
                    uploadFilesToS3AndBackend(index, obj);
                }));
            }
        }
    });
    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let encoded = reader.result != null
                    ? reader.result.toString().replace(/^data:(.*,)?/, "")
                    : "";
                if (encoded.length % 4 > 0) {
                    encoded += "=".repeat(4 - (encoded.length % 4));
                }
                let fileObj = {
                    name: file.name,
                    code: encoded.toString(),
                };
                resolve(encoded);
            };
            reader.onerror = (error) => reject(error);
        });
    }
    function calculateUploadProgress(evt) {
        let completion = evt.lengthComputable && evt.total !== 0 ? evt.loaded / evt.total : 0;
        return completion === 1 ? 0.99 : completion;
    }
    const uploadFilesToS3AndBackend = (index, fileObj) => __awaiter(void 0, void 0, void 0, function* () {
        let url = `${backendHost}api/files/sign-url-upload/${fileObj.name}`;
        if (config.type === "REVIEW" ||
            config.type === "STUDENT_SUBMISSION_GATEWAYS") {
            url = `${backendHost}api/files/submissions/${hookMetadata}/sign-url-upload/${fileObj.name}`;
        }
        fetchWrapper("GET", url, {}, null).then((data) => {
            let [signedUrlPromise, error] = data;
            if (signedUrlPromise) {
                signedUrlPromise.then((data) => {
                    let uploadFilePromise = new Promise((resolve, reject) => {
                        const req = new XMLHttpRequest();
                        function dispatchWmUploadProgress(event) {
                            return __awaiter(this, void 0, void 0, function* () {
                                const progress = yield calculateUploadProgress(event); // fake progress percentage as an example
                                if (config.componentRef) {
                                    let currentFiles = Array.from(config.componentRef.current.querySelectorAll("wm-file")).filter((fl) => fl.id == data.path);
                                    if (currentFiles[0] != undefined) {
                                        currentFiles[0].progress = progress * 100;
                                    }
                                }
                            });
                        }
                        req.upload.addEventListener("progress", dispatchWmUploadProgress);
                        req.addEventListener("readystatechange", function () {
                            if (this.readyState === 4 && this.status === 200) {
                                resolve(this);
                            }
                            else if (this.readyState === 4 && this.status !== 200) {
                                reject(this);
                            }
                        });
                        req.open("PUT", data.url, true);
                        req.setRequestHeader("Content-Disposition", `filename="${fileObj.name}"`);
                        req.send(fileObj);
                        setAttachedFiles((prev) => {
                            return [
                                ...prev,
                                {
                                    uuid: data.path,
                                    file_name: fileObj.name,
                                    file_path: data.path,
                                    progress: fileObj.progress,
                                    errorMessage: "",
                                },
                            ];
                        });
                        setCurrentFileId((prev) => {
                            prev.push(data.path);
                            return prev;
                        });
                        return req.response;
                    });
                    uploadFilePromise.then((response) => {
                        if (response.status === 200) {
                            // Save to DB
                            let saveFileUrl = `${backendHost}api/files/save-file`;
                            if (config.type === "REVIEW" ||
                                config.type === "STUDENT_SUBMISSION_GATEWAYS") {
                                saveFileUrl = `${backendHost}api/files/submissions/${hookMetadata}/save-file`;
                            }
                            let metadata = {};
                            if (config.type === "APPLICATION_TEMPLATE_REVIEW") {
                                metadata = {
                                    student_application_uuid: hookMetadata,
                                };
                            }
                            fetchWrapper("POST", saveFileUrl, {}, {
                                file_name: fileObj.name,
                                file_path: data.path,
                                mime_type: fileObj.type === "" && fileObj.name.endsWith(".log")
                                    ? "text/x-log"
                                    : fileObj.type,
                                save_type: config.type,
                                metadata: metadata,
                            }).then((resData) => {
                                let [saveFilePromise, error] = resData;
                                if (saveFilePromise) {
                                    saveFilePromise.then((fileData) => {
                                        // file saved
                                        setAttachedFiles((prev) => {
                                            prev = prev.filter((obj) => obj.file_path !== fileData.file_path);
                                            fileData.errorMessage = "";
                                            return [...prev, fileData];
                                        });
                                        setCount((prev) => prev - 1);
                                        if (config.uploadCallback) {
                                            config.uploadCallback(fileObj);
                                        }
                                    });
                                }
                                else {
                                    throw Error(error);
                                }
                            });
                        }
                        else {
                            throw Error(error);
                        }
                    });
                });
            }
            else {
                throw Error(error);
            }
        });
    });
    const onWmUploaderDownloadFile = (event) => {
        event.preventDefault();
        if (config.useBase64Format) {
            let base64String = attachedFiles.filter((x) => x.uuid === event.target.id)[0].base64;
            const downloadLink = document.createElement("a");
            if (base64String) {
                downloadLink.href = `data:${attachedFiles.filter((x) => x.uuid === event.target.id)[0].mime_type};base64,${base64String}`;
                downloadLink.download = attachedFiles.filter((x) => x.uuid === event.target.id)[0].file_name;
                downloadLink.click();
            }
        }
        else {
            downloadFile(event.target.id);
        }
    };
    const downloadFile = (fileUuid) => {
        let uploadedFile = attachedFiles.filter((file) => file.uuid === fileUuid)[0];
        let getUrlForDownload = `${backendHost}api/files/${fileUuid}/sign-url-download`;
        if (config.type === "REVIEW" ||
            config.type === "STUDENT_SUBMISSION_GATEWAYS") {
            getUrlForDownload = `${API_ROOT}api/files/submissions/${hookMetadata}/sign-url-download`;
        }
        fetchWrapper("POST", getUrlForDownload, {}, {
            file_uuid: fileUuid,
            file_path: uploadedFile.file_path,
        }).then((data) => {
            let [downloadUrlPromise, error] = data;
            if (downloadUrlPromise) {
                downloadUrlPromise.then((response) => {
                    window.open(response.url);
                });
            }
            else {
                throw Error(error);
            }
        });
    };
    const onWmUploaderDeleteFile = (event, generateActivity = false) => {
        event.preventDefault();
        if (config.useBase64Format) {
            if (!isValidUuid(event.target.id)) {
                setAttachedFiles((prevAttachedFiles) => {
                    let files = [...prevAttachedFiles];
                    files.splice(prevAttachedFiles.findIndex((file) => file.uuid === event.target.id), 1);
                    return files;
                });
            }
            else {
                deleteFileSoft(event.target ? event.target.id : event.detail);
            }
        }
        else if (config.type === "APPLICATION_TEMPLATE_REVIEW" ||
            config.type === "REVIEW" ||
            config.type === "STUDENT_SUBMISSION_GATEWAYS") {
            deleteFileHard(event.target ? event.target.id : event.detail, generateActivity);
        }
        else {
            deleteFileSoft(event.target ? event.target.id : event.detail);
        }
    };
    const deleteFileHard = (deleteFileUuid, generateActivity) => __awaiter(void 0, void 0, void 0, function* () {
        let urlToDelete = `${backendHost}api/files/delete/${deleteFileUuid}`;
        const deleteFn = () => __awaiter(void 0, void 0, void 0, function* () {
            yield fetchWrapper("DELETE", urlToDelete, {}, { generateActivity: generateActivity });
            setAttachedFiles((prevAttachedFiles) => {
                let files = [...prevAttachedFiles];
                files.splice(prevAttachedFiles.findIndex((file) => file.uuid === deleteFileUuid), 1);
                return files;
            });
            setDeleteInProgress(false);
        });
        deleteFn();
        setDeleteInProgress(true);
    });
    const deleteFileSoft = (deleteFileUuid) => {
        setDeletedFiles((prev) => [...prev, deleteFileUuid]);
        setAttachedFiles((prevAttachedFiles) => {
            let files = [...prevAttachedFiles];
            files.splice(prevAttachedFiles.findIndex((file) => file.uuid === deleteFileUuid), 1);
            return files;
        });
    };
    return [
        attachedFiles,
        deletedFiles,
        uploadInProgress,
        deleteInProgress,
        onWmUploaderFilesSelected,
        onWmUploaderDownloadFile,
        onWmUploaderDeleteFile,
        setAttachedFiles,
        setHookMetadata,
        currentFileId,
        rejectedFiles,
        setRejectedFiles,
    ];
};
export default useFileupload;
