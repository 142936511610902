import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import WmActionModal from "../common/component/WmActionModal";
import translate from "../../i18n/translate";
import Flyout from "../common/component/Flyout";
import Card from "../common/component/Card";
import { closeFlyout, openFlyout } from "../../static/js/commons";
const SelectApplicationType = ({ isOpen, type, closeModal, primaryClick, selectedAudience, }) => {
    const [selectedType, setSelectedType] = useState(selectedAudience !== null && selectedAudience !== void 0 ? selectedAudience : null);
    const [error, setError] = useState(false);
    const modalHeadingText = useIntl().formatMessage({
        id: "app.programApplications.distribute.header.title",
    });
    const modalSubHeadingText = useIntl().formatMessage({
        id: "app.requirement.required",
    });
    const primaryText = useIntl().formatMessage({
        id: "global.continue",
    });
    const secondaryText = useIntl().formatMessage({
        id: "global.cancel",
    });
    const flyoutHeading = useIntl().formatMessage({
        id: "app.programApplications.distribute.header.title",
    });
    useEffect(() => {
        if (type === "FLYOUT") {
            openFlyout("application-type-flyout", "wm-primary-publish-modal", "close-application-type-flyout");
        }
    }, []);
    const handleCategorySelection = (type) => {
        setSelectedType(type);
        setError(false);
    };
    const handleCategoryKeyDown = (e, type) => {
        if (e.key === "Enter") {
            handleCategorySelection(type);
        }
    };
    const renderAudienceCategory = (selected, type, icon, title, desc) => {
        return (_jsxs("div", Object.assign({ className: `audience-category-container ${selected ? "selected" : ""}`, onClick: () => handleCategorySelection(type), onKeyDown: (event) => handleCategoryKeyDown(event, type), tabIndex: 0, role: "option", "aria-selected": type === selectedType }, { children: [_jsx("span", { className: "checked-icon mdi mdi-checkbox-marked-circle" }), _jsxs("div", Object.assign({ className: "audience-category" }, { children: [_jsx("span", { className: `audience-category-icon mdi mdi-${icon}` }), _jsx("span", Object.assign({ className: "audience-category-title" }, { children: translate(title) })), _jsx("span", Object.assign({ className: "audience-category-desc" }, { children: translate(desc) }))] }))] })));
    };
    const modalContent = () => {
        return (_jsxs("div", { children: [_jsxs("h4", Object.assign({ className: `audience-heading ${error ? "error" : ""}` }, { children: [translate("global.audience"), _jsx("span", Object.assign({ className: "reqd" }, { children: "*" }))] })), _jsx("span", Object.assign({ id: "error-text", tabIndex: 0 }, { children: error && (_jsx("p", Object.assign({ className: "audience-selection-error" }, { children: translate("app.programApplications.distribute.audience.error.message") }))) })), _jsxs("div", Object.assign({ className: "audience-category-types" }, { children: [renderAudienceCategory(selectedType === "INTERNAL", "INTERNAL", "bank", "app.programApplications.distribute.audience.internal.applicants", "app.programApplications.distribute.audience.internal.applicants.info.message"), renderAudienceCategory(selectedType === "EXTERNAL", "EXTERNAL", "web", "app.programApplications.distribute.audience.external.applicants", "app.programApplications.distribute.audience.external.applicants.info.message")] }))] }));
    };
    const flyoutContent = (_jsx("section", Object.assign({ className: "flyout-body" }, { children: _jsx(Card, Object.assign({ classList: "content", headerTitle: translate("app.programApplications.distribute.application.type.change.card.header.text") }, { children: modalContent() })) })));
    return type === "MODAL" ? (_jsx(WmActionModal, { isOpen: isOpen, onPrimaryClick: () => {
            if (selectedType) {
                primaryClick(selectedType);
                setError(false);
            }
            else {
                setError(true);
            }
        }, onSecondaryClick: () => {
            closeModal();
            setSelectedType(selectedAudience !== null && selectedAudience !== void 0 ? selectedAudience : null);
            setError(false);
        }, onClose: () => {
            closeModal();
            setSelectedType(selectedAudience !== null && selectedAudience !== void 0 ? selectedAudience : null);
            setError(false);
        }, modalContent: modalContent(), modalHeading: modalHeadingText, modalSubHeading: modalSubHeadingText, ariaLabelValue: modalHeadingText, modalId: "type-selection-modal", contentId: "content-type-selection-modal", primaryText: primaryText, secondayText: secondaryText, modalType: "modal", elementToFocus: "secondary" })) : (_jsxs(_Fragment, { children: [_jsx("div", { className: "sr-only", id: "dialog-wrapper-top", tabIndex: 0 }), _jsx(Flyout, { id: "application-type-flyout", mainContent: flyoutContent, closeFlyoutHandler: () => {
                    closeFlyout();
                    closeModal();
                    setSelectedType(selectedAudience !== null && selectedAudience !== void 0 ? selectedAudience : null);
                    setError(false);
                }, heading: flyoutHeading, subHeading: "Fields marked with * are required.", primaryButton: translate("global.continue"), primaryActionHandler: () => {
                    var _a;
                    if (selectedType) {
                        primaryClick(selectedType);
                        setError(false);
                    }
                    else {
                        setError(true);
                        (_a = document.getElementById("error-text")) === null || _a === void 0 ? void 0 : _a.focus();
                    }
                }, breadCrum: "", showSecondaryButton: false, secondaryButton: translate("app.cohort.secondaryButtonText"), secondaryButtonActionHandler: undefined, disableActionButtons: false, showStatus: "" }), _jsx("div", { className: "sr-only", id: "dialog-wrapper-bottom", tabIndex: 0 })] }));
};
export default SelectApplicationType;
