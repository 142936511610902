import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import translate from "../../../i18n/translate";
import { userSelector } from "../../../features/login/UserSlice";
import { useSelector } from "react-redux";
export default function Footer() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const fullYearValue = new Date().getUTCFullYear();
    const { userInfo } = useSelector(userSelector);
    return (_jsxs("footer", Object.assign({ className: "footer" }, { children: [_jsxs("div", Object.assign({ className: "copyright" }, { children: ["\u00A9 ", fullYearValue, " Watermark Insights, LLC and its affiliates. All Rights Reserved."] })), _jsx("div", Object.assign({ className: "footernav" }, { children: _jsxs("ul", Object.assign({ className: "nobullets" }, { children: [_jsx("li", { children: _jsx("a", Object.assign({ className: "footerlink", href: (_c = (_b = (_a = userInfo === null || userInfo === void 0 ? void 0 : userInfo.sllUser) === null || _a === void 0 ? void 0 : _a.navigation) === null || _b === void 0 ? void 0 : _b.footer) === null || _c === void 0 ? void 0 : _c.terms, target: "_blank" }, { children: translate("global.termsAndConditions") })) }), _jsx("li", { children: _jsx("a", Object.assign({ className: "footerlink", href: (_f = (_e = (_d = userInfo === null || userInfo === void 0 ? void 0 : userInfo.sllUser) === null || _d === void 0 ? void 0 : _d.navigation) === null || _e === void 0 ? void 0 : _e.footer) === null || _f === void 0 ? void 0 : _f.privacy_policy, target: "_blank" }, { children: translate("global.privacyPolicy") })) }), _jsx("li", { children: _jsx("a", Object.assign({ className: "footerlink", href: (_j = (_h = (_g = userInfo === null || userInfo === void 0 ? void 0 : userInfo.sllUser) === null || _g === void 0 ? void 0 : _g.navigation) === null || _h === void 0 ? void 0 : _h.footer) === null || _j === void 0 ? void 0 : _j.accessibility_policy, target: "_blank" }, { children: translate("global.accessibilityPolicy") })) }), _jsx("li", { children: _jsx("a", Object.assign({ className: "footerlink", href: (_m = (_l = (_k = userInfo === null || userInfo === void 0 ? void 0 : userInfo.sllUser) === null || _k === void 0 ? void 0 : _k.navigation) === null || _l === void 0 ? void 0 : _l.footer) === null || _m === void 0 ? void 0 : _m.customer_support, target: "_blank" }, { children: translate("global.customerSupport") })) })] })) }))] })));
}
