let backendHost;
let sllLogin;
let backendApplicationHost;

const hostname = window && window.location && window.location.hostname;
let SITE_KEY = "6LdjwgYpAAAAAKxoEpnJFpCc6ogkLjbSiI83f_zu";
if (hostname === "localhost") {
  backendApplicationHost = "http://localhost:8082/";
  backendHost = "http://localhost:8080/";
  sllLogin = "http://localhost:4000/login";
} else if (hostname === "gatewaysqa.watermarkinsights.com") {
  backendApplicationHost =
    "https://applications-qaapi.gatewaysqa.watermarkinsights.com/";
  backendHost = "https://api.gatewaysqa.watermarkinsights.com/";
  sllLogin = "https://sll-qa.watermarkinsights.com/login";
} else if (hostname === "gateways.watermarkinsights.com") {
  backendApplicationHost =
    "https://applications-api.gateways.watermarkinsights.com/";
  backendHost = "https://api.gateways.watermarkinsights.com/";
  sllLogin = "https://sll.watermarkinsights.com/login";
  SITE_KEY = "6LeybcQpAAAAAIaJ3mDxVY0dzq_Sn-LeY_RPw01f";
} else if (hostname === "dev.gatewaysqa.watermarkinsights.com") {
  backendApplicationHost =
    "https://applications-devapi.gatewaysqa.watermarkinsights.com/";
  backendHost = "https://devapi.gatewaysqa.watermarkinsights.com/";
  sllLogin = "https://sll-qa.watermarkinsights.com/login";
} else if (hostname === "stage.gatewaysqa.watermarkinsights.com") {
  backendApplicationHost =
    "https://applications-stageapi.gatewaysqa.watermarkinsights.com/";
  backendHost = "https://stageapi.gatewaysqa.watermarkinsights.com/";
  sllLogin = "https://sll-stage.watermarkinsights.com/login";
}

export const HOST_NAME = `${
  hostname === "localhost" ? "http://localhost:4000" : `https://${hostname}`
}`;
export const API_ROOT = `${backendHost}`;
export const SLL_LOGIN = `${sllLogin}`;
export const API_APPLICATION_ROOT = `${backendApplicationHost}`;
export const CAPTCHA_SITE_KEY = `${SITE_KEY}`;