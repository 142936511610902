var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import translate from "../../i18n/translate";
import { WmButton, WmDatepicker, WmOption, WmSelect, } from "@watermarkinsights/ripple-react";
import { connectCohortFlyout } from "../cohorts/CohortSlice";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { openFlyout, focusButtonComponent, isNullOrEmpty, closeFlyout, focusActionMenuComponent, } from "../../static/js/commons";
import { cohortSelector, connectPlanToCohort, setApiDateError, } from "./CohortSlice";
import { getAllPlansForProgram, getTimlinesForProgram, resetTimelineData, timelineSelector, } from "../licensurePlans/TimelineSlice";
import TimelineSectionView from "./TimelineSectionView";
import { initialRequirementState } from "../../features/licensurePlans/ViewTimeline";
export default function ConnectCohort(props) {
    var _a, _b;
    const dispatch = useDispatch();
    // This useIntl() hook is to be used inside a functional component only
    let dateErrorMessage = useIntl().formatMessage({
        id: "app.cohort.pastdate.errormessage",
    });
    let heading = useIntl().formatMessage({
        id: "app.cohort.publishPlanButton",
    });
    const flyoutCloseToolTip = useIntl().formatMessage({
        id: "app.cohort.secondaryButtonText",
    });
    const selectLabel = useIntl().formatMessage({
        id: "app.cohort.select.label",
    });
    const licensurePlanError = useIntl().formatMessage({
        id: "app.cohort.licensure.plan.not.selected",
    });
    const reqDateErrorMessage = useIntl().formatMessage({
        id: "global.required.date",
    });
    const invalidDateErrorMessage = useIntl().formatMessage({
        id: "app.programApplications.student.application.card.wrong.date.error.message",
    });
    const { disableAction, apiDateError } = useSelector(cohortSelector);
    const { programPlansData, timelineList } = useSelector(timelineSelector);
    const [selectedPlan, setSelectedPlan] = useState((_b = (_a = props.cohortTimeline) === null || _a === void 0 ? void 0 : _a.timelineUuid) !== null && _b !== void 0 ? _b : "Select Option");
    let todaysDate = new Date();
    let todaysDateStringVal = [
        todaysDate.getMonth() + 1 < 10
            ? "0" + (todaysDate.getMonth() + 1)
            : todaysDate.getMonth() + 1,
        todaysDate.getDate() < 10
            ? "0" + todaysDate.getDate()
            : todaysDate.getDate(),
        todaysDate.getFullYear(),
    ].join("/");
    let utcDate = new Date(todaysDateStringVal);
    if (props.cohortReleaseDate !== "") {
        todaysDate = new Date(props.cohortReleaseDate);
        todaysDateStringVal = [
            todaysDate.getMonth() + 1 < 10
                ? "0" + (todaysDate.getMonth() + 1)
                : todaysDate.getMonth() + 1,
            todaysDate.getDate() < 10
                ? "0" + todaysDate.getDate()
                : todaysDate.getDate(),
            todaysDate.getFullYear(),
        ].join("/");
    }
    const [date, setDate] = useState(todaysDateStringVal);
    const [dateError, setDateError] = useState(false);
    const [reqDateError, setReqDateError] = useState(false);
    const [invalidDateError, setInvalidDateError] = useState(false);
    const [timelineSections, setTimelineSections] = useState([]);
    const [errorPlan, setErrorPlan] = useState(false);
    useEffect(() => {
        if (!isNullOrEmpty(selectedPlan) && selectedPlan !== "Select Option") {
            const timelineSection = Array();
            if (timelineList.length > 0) {
                timelineList.map((plans) => {
                    var _a;
                    (_a = plans.sections) === null || _a === void 0 ? void 0 : _a.map((section) => {
                        if (section.timelineUuid == selectedPlan) {
                            timelineSection.push(section);
                        }
                    });
                });
            }
            setTimelineSections(timelineSection);
        }
        else {
            setTimelineSections([]);
        }
    }, [selectedPlan]);
    useEffect(() => {
        setTimeout(() => openFlyout("connect-lp-flyout", "connect-plan-btn", "filter-cohort-licensure-plan"), 200);
    }, []);
    useEffect(() => {
        if (apiDateError) {
            setDateError(true);
        }
    }, [apiDateError]);
    useEffect(() => {
        const programUuid = props.programUuid;
        const orgUuid = localStorage.getItem("orgUuid");
        if (programUuid !== null &&
            programUuid !== "" &&
            programUuid !== "undefined") {
            dispatch(getAllPlansForProgram({ orgUuid, programUuid }));
            dispatch(getTimlinesForProgram({ orgUuid, programUuid }));
        }
    }, []);
    const renderFlyoutHeader = () => {
        return (_jsxs("section", Object.assign({ className: "immersive-header header" }, { children: [_jsx(WmButton, { buttonType: "navigational", icon: "close", tooltip: flyoutCloseToolTip, tooltipPosition: "bottom", id: "flyout-close", class: "hydrated", onClick: closeFlyoutHandler }), _jsxs("div", Object.assign({ className: "header-details" }, { children: [_jsx("p", Object.assign({ className: "subtitle" }, { children: heading })), _jsx("p", Object.assign({ className: "title" }, { children: props.cohortTitle }))] }))] })));
    };
    const renderFlyoutFooter = () => {
        return (_jsxs("section", Object.assign({ className: "flyout-footer" }, { children: [_jsx("div", Object.assign({ className: "left-contents" }, { children: " " })), _jsxs("div", Object.assign({ className: "button-collection" }, { children: [_jsx(WmButton, Object.assign({ buttonType: "secondary", id: "close-flyout-button", onClick: closeFlyoutHandler }, { children: translate("app.cohort.secondaryButtonText") })), _jsx(WmButton, Object.assign({ buttonType: "primary", id: "connect-btn", onClick: primaryActionHandler }, { children: translate("app.cohort.licensurePlan.footer.connect") }))] }))] })));
    };
    const handleDateSelected = (e) => __awaiter(this, void 0, void 0, function* () {
        // on user date selection, set component value
        const datepicker = e.target;
        const selectedISODate = e.detail.date;
        setReqDateError(false);
        const displayDate = yield datepicker.reformatDate("ISO", "US", selectedISODate);
        setDate(displayDate);
        setInvalidDateError(false);
    });
    const validateSelectedDate = (event) => __awaiter(this, void 0, void 0, function* () {
        if (!isNullOrEmpty(event.target.value)) {
            setReqDateError(false);
            const isoDate = yield event.target.reformatDate("US", "ISO", event.target.value);
            if (yield event.target.isValidIso(isoDate)) {
                const usDate = yield event.target.reformatDate("ISO", "US", isoDate);
                setDate(usDate);
                setInvalidDateError(false);
            }
            else {
                setInvalidDateError(true);
            }
            if (dateError) {
                setDateError(false);
                dispatch(setApiDateError(false));
            }
        }
        else {
            setDate("");
            setInvalidDateError(false);
            setReqDateError(true);
        }
    });
    const closeFlyoutHandler = () => {
        var _a;
        setSelectedPlan("");
        props.setNewPlan("");
        dispatch(connectCohortFlyout({
            publishingCohort: null,
            cohortUuid: props.cohortUuid,
            cohortTitle: props.cohortTitle,
            releaseDate: props.cohortReleaseDate,
            sendNotification: props.cohortSendNotification,
        }));
        closeFlyout();
        if ((_a = props.cohortTimeline) === null || _a === void 0 ? void 0 : _a.timelineUuid) {
            focusActionMenuComponent("cohort-modify-options-" + props.cohortUuid);
        }
        else {
            focusButtonComponent("connect-plan-btn");
        }
    };
    useEffect(() => {
        setDateError(new Date(date).getTime() < utcDate.getTime());
        if (!isNullOrEmpty(selectedPlan) && selectedPlan !== "Select Option") {
            const timelineSection = Array();
            if (timelineList.length > 0) {
                timelineList.map((plans) => {
                    var _a;
                    (_a = plans.sections) === null || _a === void 0 ? void 0 : _a.map((section) => {
                        if (section.timelineUuid == selectedPlan) {
                            timelineSection.push(section);
                        }
                    });
                });
            }
            setTimelineSections(timelineSection);
        }
        else {
            setTimelineSections([]);
        }
    }, [date]);
    const primaryActionHandler = () => {
        var _a, _b, _c;
        let count = 0;
        if (new Date(date).getTime() < utcDate.getTime()) {
            setDateError(true);
            (_a = document.getElementById("release-date-picker")) === null || _a === void 0 ? void 0 : _a.focus();
            count++;
        }
        if (isNullOrEmpty(date) || invalidDateError) {
            (_b = document.getElementById("release-date-picker")) === null || _b === void 0 ? void 0 : _b.focus();
            count++;
        }
        if (isNullOrEmpty(selectedPlan) || selectedPlan === "Select Option") {
            setErrorPlan(true);
            (_c = document.getElementById("filter-cohort-licensure-plan")) === null || _c === void 0 ? void 0 : _c.focus();
            count++;
        }
        if (count === 0) {
            if (!disableAction) {
                dispatch(connectPlanToCohort({
                    organizationUuid: props.orgUuid,
                    programUuid: props.programUuid,
                    timelineUuid: selectedPlan,
                    cohortUuid: props.cohortUuid,
                    releaseDate: date,
                    notif: false,
                }));
            }
            setErrorPlan(false);
            setDateError(false);
            setReqDateError(false);
            setInvalidDateError(false);
            closeFlyoutHandler();
            dispatch(resetTimelineData());
            props.setCurrentRequirement(initialRequirementState);
        }
    };
    const selectPlanHandler = (planUuid) => {
        setSelectedPlan(planUuid);
        props.setNewPlan(planUuid);
        setErrorPlan(false);
    };
    const datePickerLabel = useIntl().formatMessage({
        id: "app.cohort.datepicker.label",
    });
    const renderReleasePanel = () => {
        return (_jsxs("div", Object.assign({ className: "col paper-card releaseDate-paper-card " }, { children: [_jsxs("div", Object.assign({ className: "header " }, { children: [_jsx("h3", Object.assign({ className: "title" }, { children: translate("app.cohort.schedule.plan.release") })), _jsx("div", Object.assign({ className: "description" }, { children: translate("app.cohort.schedule.plan.release.desc") }))] })), _jsx("div", Object.assign({ className: "body" }, { children: _jsx("div", Object.assign({ className: "row" }, { children: _jsx(WmDatepicker, { id: "release-date-picker", label: datePickerLabel, labelPosition: "top", requiredField: true, value: date, onChange: (event) => validateSelectedDate(event), onWmDatepickerInputBlurred: (event) => validateSelectedDate(event), onWmDatepickerDateSelected: (event) => handleDateSelected(event), errorMessage: dateError
                                ? dateErrorMessage
                                : invalidDateError
                                    ? invalidDateErrorMessage
                                    : reqDateError
                                        ? reqDateErrorMessage
                                        : "" }) })) }))] })));
    };
    const renderSelectPanel = () => {
        return (_jsxs("div", Object.assign({ className: "col paper-card select-paper-card section-card" }, { children: [_jsxs("div", Object.assign({ className: "header" }, { children: [_jsx("h3", Object.assign({ className: "title" }, { children: translate("app.cohort.choose.licensure.plan") })), _jsx("div", Object.assign({ className: "description" }, { children: translate("app.cohort.choose.licensure.plan.description") }))] })), _jsx("div", Object.assign({ className: "body" }, { children: _jsx("div", Object.assign({ className: "row select-row" }, { children: _jsxs(WmSelect, Object.assign({ label: selectLabel, requiredField: true, id: "filter-cohort-licensure-plan", errorMessage: errorPlan ? licensurePlanError : "" }, { children: [_jsx(WmOption, Object.assign({ "aria-label": "All Statuses", selected: selectedPlan === "Select Option", onWmOptionSelected: () => setSelectedPlan("Select Option") }, { children: translate("app.cohort.select.option") }), "Select Option"), timelineList.length > 0 &&
                                    timelineList
                                        .filter((filterPlan) => filterPlan.status !== "ARCHIVED")
                                        .map((plan) => (_jsx(WmOption, Object.assign({ "aria-label": plan.title, onWmOptionSelected: () => selectPlanHandler(plan.uuid), selected: selectedPlan === plan.uuid }, { children: plan.title }), "option" + plan.uuid)))] }), "select-plan") })) }))] })));
    };
    const renderReviewPanel = () => {
        return (_jsx("div", Object.assign({ className: "flex-row" }, { children: _jsxs("div", Object.assign({ className: "col paper-card review-paper-card" }, { children: [_jsx("div", Object.assign({ className: "header" }, { children: _jsx("h3", Object.assign({ className: "title" }, { children: translate("app.cohort.licensurePlan") })) })), timelineSections.length > 0 ? (_jsx("div", Object.assign({ className: "body plan-selected" }, { children: _jsx(TimelineSectionView, { timelineSections: timelineSections }) }))) : (_jsxs("div", Object.assign({ className: "body no-plan-content" }, { children: [_jsx("div", Object.assign({ className: "not-selected" }, { children: translate("app.cohort.no.licensure.plan") })), _jsx("p", Object.assign({ className: "not-selected-desc" }, { children: translate("app.cohort.no.licensure.plan.desc") }))] })))] })) })));
    };
    return (_jsxs("div", Object.assign({ className: "flyout-panel half-flyout show", role: "dialog", id: "connect-lp-flyout", tabIndex: -1, "aria-label": "Connect Licensure Plan" }, { children: [_jsx("div", { className: "overlay" }), _jsxs("div", Object.assign({ className: "container" }, { children: [renderFlyoutHeader(), _jsx("section", Object.assign({ className: "content" }, { children: _jsxs("div", Object.assign({ className: "main-section" }, { children: [_jsxs("div", Object.assign({ className: "required-fields-text" }, { children: [translate("app.cohort.requiredText.prefix"), _jsx("span", Object.assign({ className: "reqd" }, { children: "*" })), translate("app.cohort.requiredText.suffix")] })), _jsxs("div", Object.assign({ className: "flex-row first-row" }, { children: [renderSelectPanel(), renderReleasePanel()] })), renderReviewPanel()] })) })), renderFlyoutFooter()] }))] })));
}
