import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
const DraggableArea = (props) => {
    const dragOverHandler = (event) => {
        event.stopPropagation();
        event.preventDefault();
        let ghostElement = document.getElementById("ghost-element-container");
        if (ghostElement !== null) {
            ghostElement.style.left = event.clientX + 2 + "px";
            ghostElement.style.top = event.clientY + 2 + "px";
        }
    };
    return (_jsxs("div", Object.assign({ className: "draggable-area", onDragEnd: props.dismissDrag, onDragOver: dragOverHandler, onDrop: props.dismissDrag }, { children: [props.children, _jsx("div", Object.assign({ id: "ghost-element-container", className: "ghost-element-container dragging" }, { children: props.category === "section" ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "box" }), _jsx("div", { className: "box stack-top" }), _jsx("div", { className: "box stack-top-top" })] })) : (_jsx("div", { className: "inner-Text" })) }))] })));
};
export default DraggableArea;
