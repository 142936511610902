import translate from "../../../i18n/translate";

export const ApplicationTemplateStatus = [
  {
    value_i18n: translate("app.programApplications.template.draft"),
    tag_i18n: translate("app.programApplications.template.draft"),
    key: "DRAFT",
    value: "Draft",
  },
  {
    value_i18n: translate("app.programApplications.template.published"),
    tag_i18n: translate("app.programApplications.template.published"),
    key: "PUBLISHED",
    value: "Published",
  },
  {
    value_i18n: translate("app.global.archived"),
    tag_i18n: translate("app.global.archived"),
    key: "ARCHIVED",
    value: "Archived",
  },
];
