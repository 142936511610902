import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, useCallback, useMemo, useRef, } from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { WmButton, WmPagination, WmSearch, } from "@watermarkinsights/ripple-react";
import { closeFlyout, isNullOrEmpty, debounce, focusButtonComponent, openFlyout, focusActionMenuComponent, } from "../../static/js/commons.js";
import Flyout from "../common/component/Flyout";
import { applicationSelector, saveMatchedRecords, searchUsers, swapRecordSnack, mergeSingleRecordSnack, updateCurrentMatchResultForStudentApplication, noRecordMergedSnack, } from "./ApplicationSlice";
import translate from "../../i18n/translate";
import ToggleDisplay from "../../features/common/component/ToggleDisplay";
import ViewSubmittedApplication from "./ViewSubmittedApplication";
import Loading from "../../features/common/component/Loading";
const itemsPerPage = 10;
function SystemAdminMatch(props) {
    const { selectedSubmission, openSystemAdminFor, setOpenSystemAdminFor, currentMatch, studentApplicationUuid, applicationObject, source, probableList, } = props;
    const dispatch = useDispatch();
    const { probableMatchRecord, isFetchingSearchRecords, searchRecords, probableMatches, } = useSelector(applicationSelector);
    const currentprobableList = probableMatches[selectedSubmission.studentApplicationUuid];
    //states
    const [viewSubmittedApp, setViewSubmittedApp] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState([]);
    const [selectedMatch, setSelectedMatch] = useState(currentMatch !== null && currentMatch !== void 0 ? currentMatch : null);
    const [noAvailableMatchSelected, setNoAvailableMatchSelected] = useState(false);
    //ref
    const controllerRef = useRef();
    // constants
    const studentInfoCardTitle = useIntl().formatMessage({
        id: "app.programApplications.external.application.accepted.student.info",
    });
    const firstNameLabel = useIntl().formatMessage({
        id: "app.programApplications.external.application.first.name.label",
    });
    const lastNameLabel = useIntl().formatMessage({
        id: "app.programApplications.external.application.last.name.label",
    });
    const emailLabel = useIntl().formatMessage({
        id: "app.programApplications.external.application.emailaddress.label",
    });
    const universityEmailLabel = useIntl().formatMessage({
        id: "app.programApplications.external.application.university.email.label",
    });
    const termLabel = useIntl().formatMessage({
        id: "app.programApplications.external.application.app.term.label",
    });
    const showRecordSearchLabel = useIntl().formatMessage({
        id: "app.programApplications.external.application.show.record.search",
    });
    const hideRecordSearchLabel = useIntl().formatMessage({
        id: "app.programApplications.external.application.hide.record.search",
    });
    const showRecordSearchPlaceholder = useIntl().formatMessage({
        id: "app.programApplications.external.application.sys.admin.records.search.placeholder",
    });
    const swapflyoutHeader = useIntl().formatMessage({
        id: "app.programApplications.external.application.swap.system.admin.match",
    });
    const searchflyoutHeader = useIntl().formatMessage({
        id: "app.programApplications.external.application.search.system.admin.match",
    });
    const headerData = [
        { className: "fname-header", label: firstNameLabel },
        { className: "lname-header", label: lastNameLabel },
        { className: "email-header", label: universityEmailLabel },
    ];
    // effects
    useEffect(() => {
        setRecordsPerPage(searchRecords.slice(0, searchRecords.length < itemsPerPage
            ? searchRecords.length
            : itemsPerPage));
    }, [searchRecords]);
    useEffect(() => {
        const flyoutFocusTimer = setTimeout(() => openFlyout("system-admin-match-flyout", openSystemAdminFor === "search"
            ? "search-system-admin"
            : "probable-match-" + (probableMatchRecord === null || probableMatchRecord === void 0 ? void 0 : probableMatchRecord.uuid)), 200);
        return () => clearTimeout(flyoutFocusTimer);
    }, []);
    //functions
    const closeHandler = (event) => {
        event.preventDefault();
        closeFlyout();
        setOpenSystemAdminFor("");
        if (source === "MergeApp") {
            setTimeout(() => {
                focusButtonComponent(openSystemAdminFor === "search"
                    ? "search-system-admin"
                    : "probable-match-" + (probableMatchRecord === null || probableMatchRecord === void 0 ? void 0 : probableMatchRecord.uuid));
            }, 200);
        }
        if (source === "openApp") {
            setTimeout(() => {
                focusActionMenuComponent(`action-${selectedSubmission.studentApplicationUuid}`);
            }, 200);
        }
    };
    const flyoutDetails = useMemo(() => {
        const detailsObj = {
            header: "",
            subHeader: `${(selectedSubmission === null || selectedSubmission === void 0 ? void 0 : selectedSubmission.lastName) || ""}, ${(selectedSubmission === null || selectedSubmission === void 0 ? void 0 : selectedSubmission.firstName) || ""}`,
            primaryButtonText: _jsx(_Fragment, {}),
        };
        switch (openSystemAdminFor) {
            case "search": {
                detailsObj.header = searchflyoutHeader;
                detailsObj.primaryButtonText =
                    source == "MergeApp"
                        ? translate("app.programApplications.external.application.accepted.search.save")
                        : translate("app.programApplications.external.application.accepted.search.save.and.merge");
                break;
            }
            case "swap": {
                detailsObj.header = swapflyoutHeader;
                detailsObj.primaryButtonText =
                    source == "MergeApp"
                        ? translate("app.programApplications.external.application.sys.admin.records.swap.primarybtn.text")
                        : translate("app.programApplications.external.application.accepted.search.save");
                break;
            }
        }
        return detailsObj;
    }, [openSystemAdminFor]);
    const renderStudentInfo = () => {
        const { firstName, lastName, email } = selectedSubmission.externalUser || {};
        return (_jsxs("div", Object.assign({ className: "student-info-card" }, { children: [_jsx("div", Object.assign({ className: "card-header flex-row -justified -verticallycentered" }, { children: _jsx("h2", Object.assign({ className: "header-title" }, { children: studentInfoCardTitle })) })), _jsxs("div", Object.assign({ className: "card-content" }, { children: [_jsxs("div", Object.assign({ className: "student-details-section" }, { children: [_jsx("h4", Object.assign({ className: "student-info-label" }, { children: firstNameLabel })), _jsx("span", Object.assign({ className: "student-info-value" }, { children: firstName }))] })), _jsxs("div", Object.assign({ className: "student-details-section" }, { children: [_jsx("h4", Object.assign({ className: "student-info-label" }, { children: lastNameLabel })), _jsx("span", Object.assign({ className: "student-info-value" }, { children: lastName }))] })), _jsxs("div", Object.assign({ className: "student-details-section" }, { children: [_jsx("h4", Object.assign({ className: "student-info-label" }, { children: emailLabel })), _jsx("span", Object.assign({ className: "student-info-value" }, { children: email }))] })), _jsxs("div", Object.assign({ className: "student-details-section" }, { children: [_jsx("h4", Object.assign({ className: "student-info-label" }, { children: termLabel })), _jsx("span", Object.assign({ className: "student-info-value" }, { children: selectedSubmission.admissionTerm }))] })), _jsx(WmButton, Object.assign({ id: "view-submitted-app-btn", buttonType: "textonly", onClick: () => {
                                setViewSubmittedApp(true);
                            } }, { children: translate("app.programApplications.external.application.view.submitted.app") }))] }))] })));
    };
    const renderSuggestedMatches = () => {
        let newList;
        newList = probableList
            ? probableList === null || probableList === void 0 ? void 0 : probableList.probableMatches
            : currentprobableList === null || currentprobableList === void 0 ? void 0 : currentprobableList.probableMatches;
        const newProbableList = newList === null || newList === void 0 ? void 0 : newList.filter((probableMatch) => {
            return probableMatch.uuid !== (currentMatch === null || currentMatch === void 0 ? void 0 : currentMatch.uuid);
        });
        return (_jsxs("div", Object.assign({ className: "suggested-matches-content" }, { children: [currentMatch ? (_jsx("h2", Object.assign({ className: "suggested-matches-label" }, { children: translate("app.programApplications.external.application.suggested.matches") }))) : (_jsxs("div", Object.assign({ className: "suggested-matches-text-container" }, { children: [_jsx("div", Object.assign({ className: "suggested-matches-text" }, { children: translate("app.programApplications.external.application.sys.admin.records.suggested.matches.text") })), _jsx("div", Object.assign({ className: "suggested-matches-subtext" }, { children: translate("app.programApplications.external.application.sys.admin.records.suggested.matches.subtext") }))] }))), _jsxs("table", Object.assign({ className: "data-table", role: "grid", "aria-readonly": true, tabIndex: 0 }, { children: [_jsx("thead", Object.assign({ className: "dataheader" }, { children: _jsxs("tr", Object.assign({ className: "dataheaderrow", role: "row" }, { children: [headerData.map((header) => (_jsx("th", Object.assign({ className: header.className, tabIndex: 0, role: "columnheader", scope: "col", "data-th": header.label }, { children: header.label }), header.label))), _jsx("th", { className: "btn-header" })] }), "headers") })), _jsxs("tbody", Object.assign({ className: "databody" }, { children: [currentMatch ? (_jsxs("tr", Object.assign({ className: "datarow" }, { children: [_jsx("td", Object.assign({ role: "gridcell", className: "td-content" }, { children: _jsxs("div", Object.assign({ className: "student-name" }, { children: [_jsx("input", { type: "radio", name: "student-match", id: "input-" + currentMatch.uuid, value: currentMatch.uuid, className: "radio-input", checked: currentMatch.uuid == (selectedMatch === null || selectedMatch === void 0 ? void 0 : selectedMatch.uuid), onChange: () => {
                                                            setSelectedMatch(currentMatch);
                                                        }, onKeyDown: (e) => {
                                                            if (e.key === "Enter") {
                                                                setSelectedMatch(currentMatch);
                                                            }
                                                        }, tabIndex: 0 }), _jsx("label", Object.assign({ htmlFor: "input-" + currentMatch.uuid, className: "radio student-radio-label" }, { children: currentMatch.firstName }))] })) })), _jsx("td", Object.assign({ role: "gridcell", className: "td-content" }, { children: currentMatch.lastName })), _jsx("td", Object.assign({ role: "gridcell", className: "td-content" }, { children: currentMatch.email }))] }), currentMatch.uuid)) : selectedSubmission.studentUuid ? (_jsxs("tr", Object.assign({ className: "datarow" }, { children: [_jsx("td", Object.assign({ role: "gridcell", className: "td-content" }, { children: _jsxs("div", Object.assign({ className: "student-name" }, { children: [_jsx("input", { type: "radio", name: "student", id: "input-" + selectedSubmission.studentUuid, value: selectedSubmission.studentUuid, className: "radio-input", checked: selectedSubmission && !noAvailableMatchSelected, onChange: () => {
                                                            setSelectedMatch(selectedSubmission);
                                                            setNoAvailableMatchSelected(false);
                                                        } }), _jsx("label", Object.assign({ htmlFor: "input-" + selectedSubmission.studentUuid, className: "radio student-radio-label" }, { children: selectedSubmission.firstName }))] })) })), _jsx("td", Object.assign({ role: "gridcell", className: "td-content" }, { children: selectedSubmission.lastName })), _jsx("td", Object.assign({ role: "gridcell", className: "td-content" }, { children: selectedSubmission.email }))] }), selectedSubmission.studentUuid)) : null, ((probableList === null || probableList === void 0 ? void 0 : probableList.probableMatches) || newProbableList) &&
                                    newProbableList.map((probMatch) => {
                                        return (_jsxs("tr", Object.assign({ className: "datarow" }, { children: [_jsx("td", Object.assign({ role: "gridcell", className: "td-content" }, { children: _jsxs("div", Object.assign({ className: "student-name" }, { children: [_jsx("input", { type: "radio", name: "student-match", id: "input-" + probMatch.uuid, value: probMatch.uuid, className: "radio-input", onChange: () => {
                                                                    setSelectedMatch(probMatch);
                                                                }, onKeyDown: (e) => {
                                                                    if (e.key === "Enter") {
                                                                        setSelectedMatch(probMatch);
                                                                    }
                                                                }, tabIndex: 0 }), _jsx("label", Object.assign({ htmlFor: "input-" + probMatch.uuid, className: "radio student-radio-label" }, { children: probMatch.firstName }))] })) })), _jsx("td", Object.assign({ role: "gridcell", className: "td-content" }, { children: probMatch.lastName })), _jsx("td", Object.assign({ role: "gridcell", className: "td-content" }, { children: probMatch.email }))] }), probMatch.uuid));
                                    }), _jsx("tr", Object.assign({ className: "datarow" }, { children: _jsx("td", Object.assign({ role: "gridcell", className: "td-content" }, { children: _jsxs("div", Object.assign({ className: "no-available-match-radio-btn" }, { children: [_jsx("input", { type: "radio", name: "student-match", id: "input-not-available", value: "", checked: noAvailableMatchSelected, className: "radio-input", onChange: () => {
                                                        setSelectedMatch(null);
                                                        setNoAvailableMatchSelected(true);
                                                    }, onKeyDown: (e) => {
                                                        if (e.key === "Enter") {
                                                            setSelectedMatch(null);
                                                        }
                                                    }, tabIndex: 0 }), _jsx("label", Object.assign({ htmlFor: "input-not-available", className: "radio not-available-radio-label" }, { children: translate("app.programApplications.external.application.no.available.match") }))] })) })) }), "no-available-match")] }))] }), currentMatch === null || currentMatch === void 0 ? void 0 : currentMatch.uuid)] })));
    };
    const handlePageChange = (event) => {
        event.preventDefault();
        const newPage = event.target.currentPage;
        setCurrentPage(event.target.currentPage);
        setRecordsPerPage(searchRecords.slice((newPage - 1) * itemsPerPage, newPage * itemsPerPage > (searchRecords === null || searchRecords === void 0 ? void 0 : searchRecords.length)
            ? searchRecords === null || searchRecords === void 0 ? void 0 : searchRecords.length
            : newPage * itemsPerPage));
    };
    const debouncedInputValue = useCallback(debounce((event) => {
        const value = event.target.value;
        setSearchInput(value);
        if (!value) {
            return;
        }
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        let appUuid = applicationObject.uuid;
        controllerRef.current = new AbortController();
        const signal = controllerRef.current.signal;
        dispatch(searchUsers({ searchCriteria: value, signal, appUuid }));
    }, 500), []);
    const renderSearchTable = () => {
        // loader
        if (isFetchingSearchRecords) {
            return (_jsx("div", Object.assign({ className: "loader-container" }, { children: _jsx(Loading, {}) })));
        }
        // placeholder
        if (!searchInput) {
            return (_jsx("div", Object.assign({ className: "placeholder-text-container" }, { children: _jsx("div", Object.assign({ className: "search-placeholder-subtext" }, { children: translate("app.programApplications.external.application.sys.admin.records.search.placeholder.subtext") })) })));
        }
        // search table
        if (searchRecords.length > 0) {
            return (_jsxs("div", Object.assign({ className: "search-results" }, { children: [_jsxs("table", Object.assign({ className: "data-table search-table", role: "grid", "aria-readonly": true, tabIndex: 0 }, { children: [_jsx("thead", Object.assign({ className: "dataheader" }, { children: _jsxs("tr", Object.assign({ className: "dataheaderrow", role: "row" }, { children: [headerData.map((header) => (_jsx("th", Object.assign({ className: header.className, tabIndex: 0, role: "columnheader", scope: "col", "data-th": header.label }, { children: header.label }), header.label))), _jsx("th", { className: "btn-header" })] }), "headers") })), _jsx("tbody", Object.assign({ className: "databody", id: "student-table-body", tabIndex: 0 }, { children: recordsPerPage &&
                                    (recordsPerPage === null || recordsPerPage === void 0 ? void 0 : recordsPerPage.length) > 0 &&
                                    (recordsPerPage === null || recordsPerPage === void 0 ? void 0 : recordsPerPage.map((user) => {
                                        const id = `input-${user.uuid}`;
                                        return (_jsxs("tr", Object.assign({ className: "datarow" }, { children: [_jsx("td", Object.assign({ role: "gridcell", className: "td-content" }, { children: _jsxs("div", Object.assign({ className: "student-name" }, { children: [_jsx("input", { type: "radio", name: "student", id: id, value: user.uuid, className: "radio-input", checked: Boolean(selectedMatch && user.uuid == selectedMatch.uuid), onChange: () => setSelectedMatch(user), onKeyDown: (e) => {
                                                                    if (e.key === "Enter") {
                                                                        setSelectedMatch(user);
                                                                    }
                                                                }, tabIndex: 0 }), _jsx("label", Object.assign({ htmlFor: id, className: "radio student-radio-label" }, { children: user === null || user === void 0 ? void 0 : user.firstName }))] })) })), _jsx("td", Object.assign({ role: "gridcell", className: "td-content" }, { children: user === null || user === void 0 ? void 0 : user.lastName })), _jsx("td", Object.assign({ role: "gridcell", className: "td-content" }, { children: user === null || user === void 0 ? void 0 : user.email }))] }), id));
                                    })) }))] })), searchRecords.length > itemsPerPage ? (_jsx("div", Object.assign({ className: "search-table-pagination-footer" }, { children: _jsx(WmPagination, { currentPage: currentPage, itemsPerPage: itemsPerPage, totalItems: searchRecords === null || searchRecords === void 0 ? void 0 : searchRecords.length, onWmPaginationPageClicked: (event) => {
                                handlePageChange(event);
                            }, id: "view-search-students" }, "test" + searchRecords) }))) : ("")] })));
        }
        // no result found
        return (_jsxs("div", Object.assign({ className: "empty-list", role: "alert", "aria-live": "assertive", "aria-atomic": true }, { children: [_jsx("div", Object.assign({ className: "no-results" }, { children: translate("global.noResults") })), _jsx("div", Object.assign({ className: "try-again" }, { children: translate("global.tryAgainSearch") }))] })));
    };
    const renderSearchSystemAdminRecord = () => {
        return (_jsx("div", Object.assign({ className: "system-admin-search-container" }, { children: _jsx(ToggleDisplay, Object.assign({ toggleBtnId: "record-search-toggle", showLabel: showRecordSearchLabel, hideLabel: hideRecordSearchLabel }, { children: _jsxs("div", { children: [_jsx("h4", Object.assign({ className: "record-search-header" }, { children: translate("app.programApplications.external.application.sys.admin.records") })), _jsx("div", Object.assign({ className: "search-students" }, { children: _jsx(WmSearch, { id: "search-student-name", class: "select-student-search", placeholder: showRecordSearchPlaceholder, label: showRecordSearchPlaceholder, numResults: searchRecords === null || searchRecords === void 0 ? void 0 : searchRecords.length, value: searchInput, onInput: debouncedInputValue }) })), renderSearchTable()] }) })) })));
    };
    const renderSystemAdminRecordContent = () => {
        return (_jsxs("div", Object.assign({ className: "system-admin-suggested-match-card" }, { children: [_jsx("div", Object.assign({ className: "card-header flex-row -justified -verticallycentered" }, { children: _jsx("h4", Object.assign({ className: "header-title" }, { children: translate("app.programApplications.external.application.sysadmin.record.match") })) })), _jsxs("div", Object.assign({ className: "card-content" }, { children: [_jsx("div", { children: renderSuggestedMatches() }), _jsx("div", { children: renderSearchSystemAdminRecord() })] }))] })));
    };
    const mainContent = () => {
        return (_jsx("div", Object.assign({ className: "system-admin-match-content immersive-container" }, { children: _jsx("div", Object.assign({ className: "wm-grid" }, { children: _jsxs("div", Object.assign({ className: "wm-grid-row" }, { children: [_jsx("div", Object.assign({ className: "wm-grid-col-4 wm-grid-col-xl-4 wm-grid-col-lg-4 wm-grid-col-md-8 wm-grid-col-sm-8 wm-grid-xs-8" }, { children: renderStudentInfo() })), _jsx("div", Object.assign({ className: "wm-grid-col-8 wm-grid-col-xl-8 wm-grid-col-lg-8 wm-grid-col-md-8 wm-grid-col-sm-8 wm-grid-col-xs-8" }, { children: _jsx(_Fragment, { children: renderSystemAdminRecordContent() }) }))] })) })) })));
    };
    return (_jsxs(_Fragment, { children: [_jsx(Flyout, { id: "system-admin-match-flyout", mainContent: mainContent(), closeFlyoutHandler: (event) => closeHandler(event), heading: flyoutDetails.header, subHeading: flyoutDetails.subHeader, primaryButton: flyoutDetails.primaryButtonText, primaryActionHandler: (event) => {
                    if (!isNullOrEmpty(studentApplicationUuid)) {
                        dispatch(updateCurrentMatchResultForStudentApplication({
                            currentMatch: selectedMatch,
                            studentApplicationUuid: studentApplicationUuid,
                        }));
                        const studApplications = [];
                        const studApplicationObj = {
                            studentApplicationUuid: studentApplicationUuid,
                            studentUuid: (selectedMatch === null || selectedMatch === void 0 ? void 0 : selectedMatch.uuid) || "",
                        };
                        studApplications.push(studApplicationObj);
                        if (source === "openApp") {
                            dispatch(saveMatchedRecords({
                                applicationUuid: applicationObject.uuid,
                                externalStudentApplications: studApplications,
                            }));
                            setTimeout(() => {
                                focusActionMenuComponent(`action-${selectedSubmission.studentApplicationUuid}`);
                                if (openSystemAdminFor === "search") {
                                    if (!(selectedMatch === null || selectedMatch === void 0 ? void 0 : selectedMatch.uuid)) {
                                        dispatch(noRecordMergedSnack());
                                    }
                                    else {
                                        dispatch(mergeSingleRecordSnack());
                                    }
                                }
                                else if (openSystemAdminFor === "swap") {
                                    if ((currentMatch === null || currentMatch === void 0 ? void 0 : currentMatch.uuid) === (selectedMatch === null || selectedMatch === void 0 ? void 0 : selectedMatch.uuid)) {
                                        return;
                                    }
                                    dispatch(swapRecordSnack());
                                }
                            }, 200);
                        }
                        if (source === "MergeApp") {
                            setTimeout(() => {
                                focusButtonComponent(openSystemAdminFor === "search"
                                    ? "search-system-admin"
                                    : "probable-match-" + (probableMatchRecord === null || probableMatchRecord === void 0 ? void 0 : probableMatchRecord.uuid));
                            }, 200);
                        }
                    }
                    closeHandler(event);
                }, breadCrum: "", showSecondaryButton: true, secondaryButton: translate("app.cohort.secondaryButtonText"), secondaryButtonActionHandler: (event) => closeHandler(event), disableActionButtons: false, showStatus: "" }), viewSubmittedApp && (_jsxs(_Fragment, { children: [_jsx("div", { className: "sr-only", id: "dialog-wrapper-top" }), _jsx(ViewSubmittedApplication, { setViewSubmittedApp: setViewSubmittedApp, applicationObject: applicationObject }), _jsx("div", { className: "sr-only", id: "dialog-wrapper-bottom", tabIndex: 0 })] }))] }));
}
export default SystemAdminMatch;
