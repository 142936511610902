import translate from "../../../i18n/translate";

export const TemplateComponentList = [
  {
    value_i18n: translate("app.programApplications.component.standard"),
    tag_i18n: translate("app.programApplications.component.standard"),
    key: "DEFAULT_COMPONENT",
    value: "Default Component",
    id: "default-field",
    category: "STUDENT_INPUT",
  },
  {
    value_i18n: translate("app.programApplications.component.text"),
    tag_i18n: translate("app.programApplications.component.text"),
    key: "TEXT",
    value: "Text",
    id: "text-field",
    category: "RESOURCE_CONTENT"
  },
  {
    value_i18n: translate("app.programApplications.component.small.text.field"),
    tag_i18n: translate("app.programApplications.component.small.text.field"),
    key: "SMALL_TEXT_FIELD",
    value: "Small Text Field",
    id: "small-text-field",
    category: "STUDENT_INPUT"
  },
  {
    value_i18n: translate("app.programApplications.component.large.text.area"),
    tag_i18n: translate("app.programApplications.component.large.text.area"),
    key: "LARGE_TEXT_AREA",
    value: "Large Text Area",
    id: "large-text-area",
    category: "STUDENT_INPUT"
  },
  {
    value_i18n: translate("app.programApplications.component.section.header"),
    tag_i18n: translate("app.programApplications.component.section.header"),
    key: "SECTION_HEADER",
    value: "Section Header",
    id: "section-header-field",
    category: "RESOURCE_CONTENT"
  },
  {
    value_i18n: translate("app.programApplications.component.resource.link"),
    tag_i18n: translate("app.programApplications.component.resource.link"),
    key: "LINK_RESOURCE",
    value: "Link",
    id: "link-resource",
    category: "RESOURCE_CONTENT"
  },
  {
    value_i18n: translate("app.programApplications.component.attachment"),
    tag_i18n: translate("app.programApplications.component.attachment"),
    key: "ATTACHMENT",
    value: "Attachments",
    id: "attachment",
    category: "RESOURCE_CONTENT"
  },
  {
    value_i18n: translate("app.programApplications.component.dropdown.label"),
    tag_i18n: translate("app.programApplications.component.dropdown.label"),
    key: "DROPDOWN",
    value: "Dropdown",
    id: "dropdown",
    category: "STUDENT_INPUT"
  },
  {
    value_i18n: translate("app.programApplications.component.multiselect.checkbox.label"),
    tag_i18n: translate("app.programApplications.component.multiselect.checkbox.label"),
    key: "MULTISELECT_CHECKBOXES",
    value: "Multiselect Checkboxes",
    id: "checkboxes",
    category: "STUDENT_INPUT"
  },
  {
    value_i18n: translate("app.programApplications.component.file.upload.label"),
    tag_i18n: translate("app.programApplications.component.file.upload.label"),
    key: "FILE_UPLOAD",
    value: "File Upload",
    id: "file-upload",
    category: "STUDENT_INPUT"
  },
  {
    value_i18n: translate("app.programApplications.component.link.student.input"),
    tag_i18n: translate("app.programApplications.component.link.student.input"),
    key: "LINK_INPUT",
    value: "Link",
    id: "link-input",
    category: "STUDENT_INPUT"
  },
  {
    value_i18n: translate("app.programApplications.component.acknowledgement.checkbox.label"),
    tag_i18n: translate("app.programApplications.component.acknowledgement.checkbox.label"),
    key: "ACKNOWLEDGEMENT_CHECKBOX",
    value: "Acknowledgement Checkbox",
    id: "acknowledgement-checkbox",
    category: "STUDENT_INPUT"
  },
  {
    value_i18n: translate("app.programApplications.component.date.selector.label"),
    tag_i18n: translate("app.programApplications.component.date.selector.label"),
    key: "DATE_SELECTOR",
    value: "Date Field",
    id: "date-selector",
    category: "STUDENT_INPUT"
  },
  {
    value_i18n: translate("app.programApplications.component.radio.button.label"),
    tag_i18n: translate("app.programApplications.component.radio.button.label"),
    key: "RADIO_BUTTON",
    value: "Radio Button",
    id: "radio-button",
    category: "STUDENT_INPUT"
  }
];