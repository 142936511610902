import translate from "../../../i18n/translate";

export const ExternalApplicationMergeStatus = [
  {
    value_i18n: translate(
      "app.programApplications.external.application.pending"
    ),
    tag_i18n: translate("app.programApplications.external.application.pending"),
    key: "PENDING",
    value: "PENDING",
  },
  {
    value_i18n: translate(
      "app.programApplications.external.application.merged"
    ),
    tag_i18n: translate("app.programApplications.external.application.merged"),
    key: "MERGED",
    value: "MERGED",
  },
  {
    value_i18n: translate(
      "app.programApplications.external.application.failed"
    ),
    tag_i18n: translate("app.programApplications.external.application.failed"),
    key: "ACCEPTED",
    value: "ACCEPTED",
  },
  {
    value_i18n: "-",
    tag_i18n: "-",
    key: "DENIED",
    value: "-",
  },
];
