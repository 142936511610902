import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmModal, WmModalHeader, WmModalFooter, } from "@watermarkinsights/ripple-react";
import translate from "../../i18n/translate";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { isNullOrEmpty } from "../../static/js/commons.js";
import { applicationSelector, } from "./ApplicationSlice";
export default function ScheduleOverlapModal(props) {
    const { applicationSchedulesList } = useSelector(applicationSelector);
    let newScheduleList = applicationSchedulesList === null || applicationSchedulesList === void 0 ? void 0 : applicationSchedulesList.filter(schedule => {
        return new Date(schedule.endDate) > new Date() || isNullOrEmpty(schedule.endDate);
    });
    const modalHeading = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.set.schedule.overlap.modal.header",
    });
    if (!props.isOpen) {
        return _jsx(_Fragment, {});
    }
    else {
        return (_jsxs(WmModal, Object.assign({ open: props.isOpen, elementToFocus: "primary", id: "schedule-overlap-modal", onWmModalCloseTriggered: props.onCloseClick, onWmModalPrimaryTriggered: props.onCloseClick, "aria-label": modalHeading, uid: "" }, { children: [_jsx(WmModalHeader, { heading: modalHeading }), _jsx("div", Object.assign({ id: "content-schedule-overlap-modal", className: "content" }, { children: _jsxs("div", Object.assign({ className: "modal-body" }, { children: [_jsx("p", Object.assign({ className: "overlap-info" }, { children: translate("app.programApplications.distribute.application.schedule.overlap.info.text") })), _jsx("p", Object.assign({ className: "overlap-note" }, { children: translate("app.programApplications.distribute.application.schedule.overlap.info.note") })), !isNullOrEmpty(newScheduleList) &&
                                newScheduleList.map((schedule, index) => {
                                    return (props.renderScheduleCard(schedule, true, index));
                                })] })) })), _jsx(WmModalFooter, { primaryText: "close" })] })));
    }
}
