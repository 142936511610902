import translate from "../../../i18n/translate";

export const LoggedEntriesStatus = [
  {
    value_i18n: translate("app.timelog.requirement.submission.logged.entry.approved"),
    tag_i18n: translate("app.timelog.requirement.submission.logged.entry.approved"),
    key: "APPROVED",
    value: "Approved",
  },
  {
    value_i18n: translate("app.timelog.requirement.submission.logged.entry.rejected"),
    tag_i18n: translate("app.timelog.requirement.submission.logged.entry.rejected"),
    key: "REJECTED",
    value: "Rejected",
  },
  {
    value_i18n: translate("app.timelog.requirement.submission.logged.entry.pending"),
    tag_i18n: translate("app.timelog.requirement.submission.logged.entry.pending"),
    key: "PENDING",
    value: "Pending",
  },
];
