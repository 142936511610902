import translate from "../../../i18n/translate";

export const ApplicationAudience = [
  {
    value_i18n: translate("app.programApplications.applications.filter.internal.audience"),
    tag_i18n: translate("app.programApplications.applications.filter.internal.audience"),
    key: "INTERNAL",
    value: "Internal",
  },
  {
    value_i18n: translate("app.programApplications.applications.filter.external.audience"),
    tag_i18n: translate("app.programApplications.applications.filter.external.audience"),
    key: "EXTERNAL",
    value: "External",
  },
];