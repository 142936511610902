import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import translate from "../../../i18n/translate";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { setIsExpanded } from "../../licensurePlans/TimelineSlice";
export default function ScrollToSelectedSection(props) {
    const dispatch = useDispatch();
    const sectionCurrentValue = Array();
    const doesRequirementExistForSubsection = (section, subSectionParam = {}) => section.subSections.some((subSection) => {
        var _a;
        if (subSectionParam.uuid && subSection.uuid !== subSectionParam.uuid) {
            return false;
        }
        let reqExist = subSection.requirements.filter((req) => req.requirementType === "REQUIREMENT").length > 0;
        if (reqExist) {
            return reqExist;
        }
        let appTypeReqs = subSection.requirements.filter((req) => req.requirementType === "APPLICATION");
        for (let req of appTypeReqs) {
            let openAppExists = props.timelineApplications
                ? ((_a = props.timelineApplications) === null || _a === void 0 ? void 0 : _a.filter((app) => {
                    return (app.uuid == req.applicationUuid &&
                        (app.startDate != null || app.status === "OPEN"));
                }).length) > 0
                : false;
            if (openAppExists) {
                return openAppExists;
            }
        }
        return false;
    });
    const doesRequirementExistForSection = (section, includeChild = true) => {
        var _a;
        let reqExist = section.requirements.filter((req) => req.requirementType === "REQUIREMENT").length > 0;
        if (reqExist) {
            return reqExist;
        }
        let appTypeReqs = section.requirements.filter((req) => req.requirementType === "APPLICATION");
        for (let req of appTypeReqs) {
            let openAppExists = props.timelineApplications
                ? ((_a = props.timelineApplications) === null || _a === void 0 ? void 0 : _a.filter((app) => {
                    return (app.uuid == req.applicationUuid &&
                        (app.startDate != null || app.status === "OPEN"));
                }).length) > 0
                : false;
            if (openAppExists) {
                return openAppExists;
            }
        }
        if (includeChild)
            return doesRequirementExistForSubsection(section);
    };
    const scrolledIntoSectionView = (section, event, isExpanded, isTypeRequirement, index, isTypeCohort) => {
        event.preventDefault();
        dispatch(setIsExpanded({ isExpanded, index, isTypeRequirement, isTypeCohort }));
    };
    const scrolledIntoGeneralSectionView = (section, event) => {
        props.setSideBarClick(true);
        let elem = document.getElementById(section.uuid);
        event.preventDefault();
        elem && elem.scrollIntoView({ behavior: "smooth" });
        props.setSelectedUuid("gen-ed-" + section.uuid);
        setTimeout(() => {
            props.setSideBarClick(false);
        }, 1000);
    };
    const scrolledIntoSubSectionView = (subSection, event) => {
        props.setSideBarClick(true);
        let elem = document.getElementById(subSection.uuid);
        event.preventDefault();
        elem && elem.scrollIntoView({ behavior: "smooth" });
        props.setSelectedUuid(subSection.uuid);
        setTimeout(() => {
            props.setSideBarClick(false);
        }, 1000);
    };
    return (_jsx("ul", Object.assign({ id: "scrollable-timeline", className: "menu", role: "menu" }, { children: props.timelineSections
            ? props.timelineSections.map((section, index) => {
                return (_jsx(Fragment, { children: doesRequirementExistForSection(section) ? (_jsxs("li", Object.assign({ className: "menu-item", role: "none" }, { children: [_jsxs("div", Object.assign({ className: "item requirement-container" +
                                    (section.isExpanded ? " -req-expanded" : "") +
                                    (props.selectedUuid === section.uuid ? " active" : "") +
                                    (section.isExpanded ? " -expanded" : ""), role: "menuitem", onClick: (event) => scrolledIntoSectionView(section, event, section.isExpanded, false, index, false) }, { children: [_jsx("button", { className: "button section-button -align -textonly mdi mdi-play" +
                                            (section.isExpanded ? " mdi-rotate-90" : ""), "aria-expanded": section.isExpanded, title: section.title }), _jsxs("span", Object.assign({ className: "line-clamp timeline-title", title: section.title }, { children: [" ", section.title] }))] })), doesRequirementExistForSection(section, false) &&
                                section.isExpanded ? (_jsx("ul", Object.assign({ className: "sub-menu -req-sub-menu", role: "menu" }, { children: _jsx("li", Object.assign({ className: "sub-menu-item", role: "none" }, { children: _jsx("div", Object.assign({ id: "left-panel-" + section.uuid, className: "item -width" +
                                            ("gen-ed-" + section.uuid == props.selectedUuid
                                                ? " active"
                                                : ""), onClick: (event) => scrolledIntoGeneralSectionView(section, event) }, { children: _jsx("button", Object.assign({ className: "button section-button -textonly", title: "", role: "menuitem", onClick: (event) => scrolledIntoGeneralSectionView(section, event) }, { children: _jsx("div", Object.assign({ className: "line-clamp-requirement" }, { children: translate("app.studentWorkflow.general") })) })) })) }), "gen-ed-" + section.uuid) }))) : (""), section.subSections.map((subSection) => {
                                return (_jsx(Fragment, { children: doesRequirementExistForSubsection(section, subSection) && section.isExpanded ? (_jsx("ul", Object.assign({ className: "sub-menu -req-sub-menu", role: "menu" }, { children: _jsx("li", Object.assign({ className: "sub-menu-item", role: "none" }, { children: _jsx("div", Object.assign({ id: "left-panel-" + subSection.uuid, className: "item -width" +
                                                    (props.selectedUuid == subSection.uuid
                                                        ? " active"
                                                        : ""), onClick: (event) => scrolledIntoSubSectionView(subSection, event) }, { children: _jsx("button", Object.assign({ className: "button section-button -textonly", title: subSection.title, role: "menuitem", onClick: (event) => scrolledIntoSubSectionView(subSection, event) }, { children: _jsx("div", Object.assign({ className: "line-clamp-requirement" }, { children: subSection.title })) })) })) }), "subsection-" + subSection.uuid) }))) : ("") }, "subsection-key-" + subSection.uuid));
                            })] }), "section-" + section.uuid)) : ("") }, "section-key-" + section.uuid));
            })
            : "" })));
}
