import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import translate from "../../i18n/translate";
import { useDispatch } from "react-redux";
export default function TimelineSectionView(props) {
    const dispatch = useDispatch();
    let timelineSections = props.timelineSections;
    const doesRequirementExist = (section) => section.subSections.some((subSection) => {
        return subSection.requirements.length > 0;
    });
    return (_jsx("div", Object.assign({ className: "review-plan" }, { children: timelineSections
            ? timelineSections.map((section, index) => {
                return (_jsxs("div", Object.assign({ className: "review-plan-section" }, { children: [_jsx("div", Object.assign({ className: "section-header flex-row -justified -verticallycentered " +
                                "expanded" }, { children: _jsxs("h2", Object.assign({ className: "title" }, { children: [" ", section.title, " "] })) })), _jsxs("div", Object.assign({ className: "body" }, { children: [section.requirements.length > 0 ? (section.requirements.map((req) => {
                                    var _a, _b;
                                    return (_jsxs("div", Object.assign({ className: "requirement-details" }, { children: [_jsx("p", Object.assign({ className: "requirement-title" }, { children: req.title })), ((_a = req.description) === null || _a === void 0 ? void 0 : _a.trim().length) > 0 ? (_jsx("p", Object.assign({ className: "requirement-description" }, { children: (_b = req.description) === null || _b === void 0 ? void 0 : _b.trim() }))) : ("")] }), "requirement-" + req.uuid));
                                })) : doesRequirementExist(section) ? null : (_jsx("p", Object.assign({ className: "no-sections-info left-indent" }, { children: translate("app.licensurePlans.noReq") }))), _jsx("div", Object.assign({ className: "sub-section-list" +
                                        (doesRequirementExist(section)
                                            ? " no-section-requirements"
                                            : "") }, { children: section.subSections.map((subSection) => {
                                        return (_jsxs("div", Object.assign({ className: "sub-section " + "-expanded" }, { children: [_jsx("div", Object.assign({ className: "sub-section-details" }, { children: _jsx("h3", Object.assign({ className: "accordion-label" }, { children: subSection.title })) })), _jsx("div", Object.assign({ className: "requirement-body" }, { children: subSection.requirements.length > 0 ? (subSection.requirements.map((req) => {
                                                        var _a, _b;
                                                        return (_jsxs("div", Object.assign({ className: "requirement-details" }, { children: [_jsx("p", Object.assign({ className: "requirement-title" }, { children: req.title })), ((_a = req.description) === null || _a === void 0 ? void 0 : _a.trim().length) > 0 ? (_jsx("p", Object.assign({ className: "requirement-description" }, { children: (_b = req.description) === null || _b === void 0 ? void 0 : _b.trim() }))) : ("")] }), "requirement-" + req.uuid));
                                                    })) : (_jsx("p", Object.assign({ className: "no-sections-info left-indent" }, { children: translate("app.licensurePlans.noReq") }))) }))] }), "subSection" + subSection.uuid));
                                    }) }))] }))] }), "section" + section.uuid));
            })
            : null })));
}
