import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useIntl } from "react-intl";
import InformationModal from "../common/component/InformationWmModal";
const DuplicateWarningModal = (props) => {
    const { showDuplicateWarningModal, duplicateRecordObj, closeWarningModal } = props;
    const warningModalHeading = useIntl().formatMessage({
        id: "app.programApplications.external.application.duplicate.warning.modal.heading",
    });
    const modalContent = useIntl().formatMessage({
        id: "app.programApplications.external.application.duplicate.warning.modal.content",
    });
    const modalSubContent = useIntl().formatMessage({
        id: "app.programApplications.external.application.duplicate.warning.modal.subcontent",
    });
    const okButtonText = useIntl().formatMessage({
        id: "app.programApplications.external.application.duplicate.warning.modal.btntext",
    });
    const renderModalContent = () => {
        return (_jsxs("div", Object.assign({ className: "duplicate-modal-content-wrapper", "data-testid": "duplicate-modal-content-wrapper" }, { children: [_jsx("div", Object.assign({ className: "modal-text" }, { children: modalContent })), _jsx("div", Object.assign({ className: "modal-subtext" }, { children: modalSubContent })), _jsx("div", Object.assign({ className: "records-container" }, { children: Object.keys(duplicateRecordObj).map((key) => {
                        const { probableMatchDetails, applicantList } = duplicateRecordObj[key];
                        if (applicantList.length > 1 && probableMatchDetails) {
                            const { firstName = "", lastName = "" } = probableMatchDetails;
                            const probableMatchName = `${firstName} ${lastName}`;
                            return (_jsxs("div", Object.assign({ className: "record", tabIndex: 0 }, { children: [_jsx("div", Object.assign({ className: "record-heading" }, { children: probableMatchName })), _jsx("div", Object.assign({ className: "applicant-list" }, { children: applicantList.map((applicant) => {
                                            const { firstName = "", lastName = "", studentApplicationUuid = "", } = applicant;
                                            const applicantName = `${firstName} ${lastName}`;
                                            return (_jsxs("div", Object.assign({ className: "applicant" }, { children: [_jsx("span", Object.assign({ className: "applicant-header" }, { children: "Associated Applicant:" })), _jsx("span", Object.assign({ className: "applicant-name" }, { children: applicantName }))] }), studentApplicationUuid));
                                        }) }))] }), key));
                        }
                    }) }))] })));
    };
    return (_jsx(InformationModal, { isOpen: showDuplicateWarningModal, onPrimaryClick: closeWarningModal, modalContent: renderModalContent(), modalHeading: warningModalHeading, ariaLabelValue: warningModalHeading, modalId: "duplicate-warning-modal", contentId: "modal-content", primaryButtonText: okButtonText, onCloseClick: closeWarningModal }));
};
export default DuplicateWarningModal;
