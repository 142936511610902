import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
const DropZone = (props) => {
    const dragOverHandler = (event) => {
        event.stopPropagation();
        event.preventDefault();
        let ghostElement = document.getElementById("ghost-element-container");
        if (ghostElement !== null) {
            ghostElement.style.left = event.clientX + 2 + "px";
            ghostElement.style.top = event.clientY + 2 + "px";
        }
    };
    const hideGhostElement = () => {
        let ghostElement = document.getElementById("ghost-element-container");
        if (ghostElement !== null) {
            ghostElement.style.display = "none";
        }
    };
    const dragEndHandler = (event) => {
        hideGhostElement();
    };
    const dropHandler = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (!(event.dataTransfer.getData("uuid") === undefined ||
            event.dataTransfer.getData("uuid").trim() === "")) {
            props.changePosition(event.dataTransfer.getData("uuid"), props.nodeId, props.nodeId === "section-top" ? true : false, props.nodeIdentifier, true);
        }
        props.setDraggedEnterItem("");
        hideGhostElement();
    };
    const dragEnterHandler = (event) => {
        props.setDraggedEnterItem(props.nodeId);
    };
    let flag = props.draggedEnterItem !== props.nodeId &&
        props.nodeIdentifier === "section-outline";
    let attributes = {};
    if (props.nodeId === "section-top") {
        attributes = {
            style: { margin: "0" },
        };
        if (flag) {
            attributes = {
                style: { height: props.height, margin: "0" },
            };
        }
    }
    const dropAllowed = () => {
        if (props.elements === undefined ||
            props.maxElementsAllowed === undefined) {
            return true;
        }
        return !(props.elements &&
            props.maxElementsAllowed &&
            props.elements >= props.maxElementsAllowed);
    };
    return (_jsxs("div", Object.assign({ draggable: props.isDragStarted, onDragOver: dragOverHandler, onDragEnd: dragEndHandler, onDragEnter: dragEnterHandler, onDrop: dropHandler }, attributes, { children: [props.children, props.draggedEnterItem === props.nodeId &&
                props.isDragStarted &&
                dropAllowed() ? (_jsx("div", Object.assign({ className: `drop-zone ${"isDragged"}` }, attributes))) : ("")] })));
};
export default DropZone;
