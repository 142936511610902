import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { isNullOrEmpty } from "../../static/js/commons";
import { WmSearch, WmToggletip } from "@watermarkinsights/ripple-react";
import translate from "../../i18n/translate";
import { cohortSelector } from "../../features/cohorts/CohortSlice";
import { useSelector } from "react-redux";
const SelectCourse = ({ selectedCategoryName, selectedCourses, setSelectedCourses, noneSelectedError, setNoneSelectedError, }) => {
    const { logCategoryCourses } = useSelector(cohortSelector);
    const [filteredCourses, setFilteredCourses] = useState((logCategoryCourses === null || logCategoryCourses === void 0 ? void 0 : logCategoryCourses.length) > 0 ? logCategoryCourses : []);
    const [selectAllCheckboxes, setSelectAllCheckboxs] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const searchPlaceholder = useIntl().formatMessage({
        id: "app.timelog.add.qualifying.courses.search.placeholder",
    });
    const moreInfoLabel = useIntl().formatMessage({
        id: "app.openCohort.moreInfoLabel",
    });
    const toggletipText = useIntl().formatMessage({
        id: "app.timelog.add.qualifying.courses.toggle.tip.text",
    });
    const tableHeaderLabel = useIntl().formatMessage({
        id: "app.timelog.add.qualifying.courses.table.header.text",
    });
    useEffect(() => {
        if (!isNullOrEmpty(searchInput)) {
            setFilteredCourses(logCategoryCourses.filter((courseObject) => courseObject.name.includes(searchInput) ||
                courseObject.catalogCode.includes(searchInput)));
        }
        else {
            setFilteredCourses(logCategoryCourses);
        }
    }, [searchInput]);
    useEffect(() => {
        if (selectedCourses.length) {
            setNoneSelectedError(false);
        }
    }, [selectedCourses]);
    const isSelected = (uuid) => {
        return !isNullOrEmpty(selectedCourses.find((course) => course.uuid == uuid));
    };
    const updatedSelections = (course, checked) => {
        if (checked) {
            setSelectedCourses((courses) => [...courses, course]);
        }
        else {
            setSelectedCourses((courses) => courses.filter((courseObject) => courseObject.uuid != course.uuid));
        }
    };
    const selectAllHandler = (checked) => {
        setSelectAllCheckboxs(checked);
        if (checked) {
            setSelectedCourses(logCategoryCourses);
        }
        else {
            setSelectedCourses([]);
        }
    };
    const renderSearchBox = () => {
        return filteredCourses ? (_jsx("div", Object.assign({ className: "search-div" }, { children: _jsx(WmSearch, { id: "search-course", className: "course-search", placeholder: searchPlaceholder, label: searchPlaceholder, onInput: (event) => {
                    event.preventDefault();
                    setNoneSelectedError(false);
                    setSearchInput(event.target.value);
                }, numResults: logCategoryCourses.length, value: searchInput }) }))) : null;
    };
    const renderTableHeader = () => {
        return (_jsx("thead", Object.assign({ className: "dataheader" }, { children: _jsx("tr", Object.assign({ className: "dataheaderrow", role: "row" }, { children: _jsxs("th", Object.assign({ tabIndex: 0, role: "columnheader", scope: "col", "aria-label": tableHeaderLabel }, { children: [_jsxs("span", Object.assign({ className: "checkbox-option" }, { children: [_jsx("input", { type: "checkbox", className: "chkbx-input", id: "select-all", checked: logCategoryCourses.length > 0 &&
                                        logCategoryCourses.length === selectedCourses.length, onChange: () => selectAllHandler(!selectAllCheckboxes) }), _jsx("label", Object.assign({ className: "chkbx", htmlFor: "select-all" }, { children: tableHeaderLabel }))] })), _jsx(WmToggletip, { className: "toggletip", label: moreInfoLabel, tooltip: toggletipText, tooltipPosition: "bottom-right" })] })) }), "headers") })));
    };
    const renderCourses = () => {
        return (_jsx(_Fragment, { children: (logCategoryCourses === null || logCategoryCourses === void 0 ? void 0 : logCategoryCourses.length) ? (_jsxs(_Fragment, { children: [renderSearchBox(), (filteredCourses === null || filteredCourses === void 0 ? void 0 : filteredCourses.length) ? (_jsxs("table", Object.assign({ className: "data-table course-selection-table", role: "grid", "aria-readonly": true }, { children: [renderTableHeader(), _jsx("tbody", Object.assign({ id: "course-table-body", className: `databody course-selection-tbody immersive-container ${noneSelectedError ? "error-view" : ""}`, tabIndex: 0 }, { children: filteredCourses === null || filteredCourses === void 0 ? void 0 : filteredCourses.map((course) => {
                                    return (_jsx(Fragment, { children: _jsx("tr", Object.assign({ className: "datarow", role: "row" }, { children: _jsx("td", Object.assign({ role: "gridcell", "data-td": course === null || course === void 0 ? void 0 : course.name }, { children: _jsxs("span", Object.assign({ className: "checkbox-option" }, { children: [_jsx("input", { type: "checkbox", className: "chkbx-input", id: "course-" + course.uuid, checked: isSelected(course.uuid), onChange: (event) => {
                                                                if (event.target.checked) {
                                                                    updatedSelections(course, true);
                                                                }
                                                                else {
                                                                    updatedSelections(course, false);
                                                                }
                                                            } }), _jsx("label", Object.assign({ className: "chkbx", htmlFor: "course-" + (course === null || course === void 0 ? void 0 : course.uuid) }, { children: (course === null || course === void 0 ? void 0 : course.catalogCode) + " - " + (course === null || course === void 0 ? void 0 : course.name) }))] })) })) })) }, course.uuid));
                                }) }))] }))) : (_jsxs("section", Object.assign({ className: "empty-list", role: "alert", "aria-live": "assertive", "aria-atomic": true }, { children: [_jsx("table", Object.assign({ className: "data-table", role: "grid", "aria-readonly": true }, { children: _jsx("thead", Object.assign({ className: "dataheader" }, { children: _jsx("tr", { className: "dataheaderrow empty-row", role: "row" }, "headers") })) })), _jsx("div", Object.assign({ className: "no-results" }, { children: translate("app.gatewaysLandingPage.noMatch") })), _jsx("div", Object.assign({ className: "try-again" }, { children: translate("app.gatewaysLandingPage.tryAgain") }))] })))] })) : (_jsxs("section", Object.assign({ className: "empty-flyout-view" }, { children: [_jsx("div", Object.assign({ className: "no-data-label" }, { children: translate("app.timelog.add.qualifying.courses.empty.state.view.primary.text") })), _jsx("div", Object.assign({ className: "no-data-text" }, { children: translate("app.timelog.add.qualifying.courses.empty.state.view.secondary.text") }))] }))) }));
    };
    return (_jsx("section", Object.assign({ className: "categories-main-body" }, { children: _jsxs("div", Object.assign({ className: "course-selection-container" }, { children: [(logCategoryCourses === null || logCategoryCourses === void 0 ? void 0 : logCategoryCourses.length) ? (_jsxs(_Fragment, { children: [_jsx("p", Object.assign({ className: "course-selection-info" }, { children: translate("app.timelog.add.qualifying.courses.help.text", {
                                title: selectedCategoryName,
                            }) })), _jsxs("p", Object.assign({ className: `select-course-text ${noneSelectedError ? "alert-label" : ""}` }, { children: [translate("app.timelog.add.qualifying.courses.select.text"), _jsx("span", Object.assign({ className: "reqd" }, { children: "*" })), noneSelectedError && (_jsx("span", { className: "alert-icon mdi mdi-alert" }))] })), noneSelectedError && (_jsx("p", Object.assign({ className: "none-selected-error error-message", id: "no-selection", tabIndex: 0 }, { children: translate("app.timelog.add.log.category.select.course.error.text") })))] })) : null, renderCourses()] })) })));
};
export default SelectCourse;
