import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmButton } from "@watermarkinsights/ripple-react";
import { useState } from "react";
const ToggleDisplay = ({ toggleBtnId, showLabel, hideLabel, children, }) => {
    const [isCollapse, setIsCollapse] = useState(toggleBtnId === "std-template-fields-toggle" ? false : true);
    return (_jsxs("div", Object.assign({ className: `toggle-btn ${toggleBtnId === "std-template-fields-toggle" && isCollapse
            ? "show"
            : "hide"}` }, { children: [toggleBtnId === "std-template-fields-toggle" && !isCollapse && children, _jsx(WmButton, Object.assign({ buttonType: "textonly", icon: "f40a", iconRotate: toggleBtnId === "std-template-fields-toggle"
                    ? isCollapse
                        ? 90
                        : 270
                    : isCollapse
                        ? 0
                        : 90, id: toggleBtnId, onClick: () => {
                    setIsCollapse(!isCollapse);
                } }, { children: isCollapse ? showLabel : hideLabel })), toggleBtnId !== "std-template-fields-toggle" && !isCollapse && children] })));
};
export default ToggleDisplay;
