var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { API_APPLICATION_ROOT, API_ROOT, SLL_LOGIN } from "../../env-config";
import constants from "../common/constants/applicationConstants.json";
import store from "../../app/store";
import { setError } from "../../features/login/UserSlice";
export const fetchWrapper = (method, url, headers, body, signal) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const token = localStorage.getItem("jwt");
        let csrfToken = {};
        if (method !== "GET" &&
            url.indexOf(API_ROOT) > -1 &&
            localStorage.getItem(constants.gatewaysCsrfHeaderName)) {
            csrfToken = {
                "X-XSRF-TOKEN": localStorage.getItem(constants.gatewaysCsrfHeaderName),
            };
        }
        else if (method !== "GET" &&
            url.indexOf(API_APPLICATION_ROOT) > -1 &&
            localStorage.getItem(constants.applicationsCsrfHeaderName)) {
            csrfToken = {
                "X-XSRF-TOKEN": localStorage.getItem(constants.applicationsCsrfHeaderName),
            };
        }
        const response = yield fetch(url, {
            method,
            headers: Object.assign(Object.assign({ Accepts: "application/json", "Content-Type": "application/json", Authorization: token ? token : "" }, headers), csrfToken),
            body: body && JSON.stringify(body),
            credentials: "include",
            signal,
        });
        if (response.headers)
            for (let header of response.headers) {
                let [key, value] = header;
                if (key === constants.gatewaysCsrfHeaderName ||
                    key === constants.applicationsCsrfHeaderName) {
                    localStorage.setItem(key, value);
                }
            }
        let data = response
            .clone()
            .json()
            .catch(() => response.text());
        if (response.status >= 200 && response.status <= 399) {
            return [data, undefined];
        }
        else {
            let res = yield response.clone().json();
            if ((res === null || res === void 0 ? void 0 : res.message) === "LoggedOut") {
                window.open(SLL_LOGIN, "_self");
            }
            else if ((res === null || res === void 0 ? void 0 : res.message) === "duplicate" ||
                (res === null || res === void 0 ? void 0 : res.message) === "invalid_captcha") {
                return [undefined, res === null || res === void 0 ? void 0 : res.message];
            }
            else {
                store.dispatch(setError(JSON.stringify(res)));
                return [undefined, JSON.stringify(res)];
            }
        }
        throw response;
    }
    catch (e) {
        console.error(e);
        return [undefined, e];
    }
});
