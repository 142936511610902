import translate from "../../../i18n/translate";

export const ApplicationStatus = [
  {
    value_i18n: translate("app.programApplications.application.open"),
    tag_i18n: translate("app.programApplications.application.open"),
    key: "OPEN",
    value: "Open",
  },
  {
    value_i18n: translate("app.programApplications.application.closed"),
    tag_i18n: translate("app.programApplications.application.closed"),
    key: "CLOSED",
    value: "Closed",
  },
];
