import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { WmButton, WmOption, WmSearch, WmSelect, } from "@watermarkinsights/ripple-react";
import { applicationSelector, mergeRecordSnack, getApplication, getProbableMatches, getStudentSubmissionRecord, saveMatchedRecords, noRecordMergedSnack, } from "./ApplicationSlice";
import Loading from "../../features/common/component/Loading";
import translate from "../../i18n/translate";
import StudentMatchRecord from "./StudentMatchRecord";
import { useEffect, useState, useMemo } from "react";
import { focusButtonComponent, isNullOrEmpty } from "../../static/js/commons";
import SystemAdminMatch from "./SystemAdminMatch";
import { useDispatch } from "react-redux";
import { getStudentIdentifierForBackend } from "../../features/common/commonActions";
import { RecordMatchingTagList } from "./data/RecordMatchingTagList";
import { getI18nValueByKey } from "../../features/common/commonActions";
import { RecordOrderOptions } from "./data/RecordOrderOptions";
import DuplicateWarningModal from "./DuplicateWarningModal";
const MergeStudentApplication = () => {
    var _a, _b, _c, _d, _e, _f, _g;
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    let listOfAcceptedStudentApps = (_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.acceptedStudentApplications;
    const [acceptedStudentApps, setAcceptedStudentApps] = useState((_b = location === null || location === void 0 ? void 0 : location.state) === null || _b === void 0 ? void 0 : _b.acceptedStudentApplications);
    let applicationObject = (_c = location === null || location === void 0 ? void 0 : location.state) === null || _c === void 0 ? void 0 : _c.applicationObj;
    const admissionTerm = (_d = location === null || location === void 0 ? void 0 : location.state) === null || _d === void 0 ? void 0 : _d.admissionTerm;
    const searchInfoText = (_e = location === null || location === void 0 ? void 0 : location.state) === null || _e === void 0 ? void 0 : _e.searchInfoText;
    const filteredStatusText = (_f = location === null || location === void 0 ? void 0 : location.state) === null || _f === void 0 ? void 0 : _f.filteredStatusText;
    const { isFetchingData, isFetchingStudentSubmissionRecord, isFetchingProbableMatches, probableMatches, applicationObj, acceptedSubmissionList, } = useSelector(applicationSelector);
    const [matchStatus, setMatchStatus] = useState("All");
    const [sortType, setSortType] = useState("ASC");
    const [searchText, setSearchText] = useState("");
    const [openSystemAdminFor, setOpenSystemAdminFor] = useState("");
    const [selectedSubmission, setSelectedSubmission] = useState();
    const [appObj, setAppObj] = useState(applicationObj);
    const [showDuplicateWarningModal, setShowDuplicateWarningModal] = useState(false);
    const [duplicateRecordsObj, setDuplicateRecordsObj] = useState({});
    const [inProgress, setInProgress] = useState(false);
    const searchPlaceholdler = useIntl().formatMessage({
        id: "app.licensure.plan.extracts.search.students.placeholder",
    });
    const filterByLabel = useIntl().formatMessage({
        id: "app.cohort.filterBy",
    });
    const sortByLabel = useIntl().formatMessage({
        id: "app.programApplications.applications.sort.by.label",
    });
    const isPageLoading = isFetchingData ||
        isFetchingProbableMatches ||
        isFetchingStudentSubmissionRecord;
    const verifiedCount = useMemo(() => {
        let verifiedCount = 0;
        Object.keys(probableMatches).forEach((key) => {
            var _a;
            const data = probableMatches[key];
            if (((_a = data.currentMatch) === null || _a === void 0 ? void 0 : _a.uuid) && data.status === "VERIFIED") {
                verifiedCount += 1;
            }
        });
        return verifiedCount;
    }, [probableMatches]);
    const mergeRecordsHandler = () => {
        setInProgress(true);
        const duplicateRecordsObj = {};
        let duplicateFound = false;
        const externalStudentApplications = [];
        acceptedStudentApps.forEach((student) => {
            const { currentMatch, status } = probableMatches[student.studentApplicationUuid] || {};
            if ((currentMatch === null || currentMatch === void 0 ? void 0 : currentMatch.uuid) && status === "VERIFIED") {
                externalStudentApplications.push({
                    studentApplicationUuid: student.studentApplicationUuid,
                    studentUuid: currentMatch.uuid,
                });
                if (duplicateRecordsObj[currentMatch.uuid]) {
                    duplicateFound = true;
                    duplicateRecordsObj[currentMatch.uuid] = {
                        probableMatchDetails: currentMatch,
                        applicantList: [
                            ...duplicateRecordsObj[currentMatch.uuid].applicantList,
                            student,
                        ],
                    };
                }
                else {
                    duplicateRecordsObj[currentMatch.uuid] = {
                        probableMatchDetails: currentMatch,
                        applicantList: [student],
                    };
                }
            }
        });
        if (duplicateFound) {
            setShowDuplicateWarningModal(true);
            setDuplicateRecordsObj(duplicateRecordsObj);
            return;
        }
        dispatch(saveMatchedRecords({
            applicationUuid: applicationObject.uuid,
            externalStudentApplications,
        }));
        navigate({
            pathname: "/programApplications/application/" + applicationObject.uuid,
        }, {
            state: {
                applicationObj: applicationObject,
                admissionTerm: admissionTerm,
            },
        });
        verifiedCount === 0
            ? dispatch(noRecordMergedSnack())
            : dispatch(mergeRecordSnack({ verifiedCount }));
        focusButtonComponent("bulk-merge-btn");
        setInProgress(false);
    };
    const renderPageHeader = () => {
        return (_jsxs("div", Object.assign({ className: "page-header flex-row -justified -verticallycentered" }, { children: [_jsxs("div", Object.assign({ className: "flex-row -justified -verticallycentered" }, { children: [_jsx(WmButton, { buttonType: "navigational", id: "go-back", className: "cancel hydrated", icon: "f04d", iconSize: "big", customBackground: "dark", onClick: () => {
                                navigate({
                                    pathname: "/programApplications/application/" +
                                        applicationObject.uuid,
                                }, {
                                    state: {
                                        applicationObj: applicationObject,
                                        admissionTerm: admissionTerm,
                                        searchInfoText: searchInfoText,
                                        filteredStatusText: filteredStatusText,
                                    },
                                });
                                setTimeout(() => {
                                    focusButtonComponent("bulk-merge-btn");
                                }, 200);
                            }, tooltip: "Go Back" }), _jsxs("div", Object.assign({ className: "go-back-link program-header flex-row -justified -verticallycentered" }, { children: [_jsxs("div", Object.assign({ className: "bread-crumbs" }, { children: [_jsx(WmButton, Object.assign({ "button-type": "textonly", id: "go-back", customBackground: "dark", className: "link", onClick: (event) => {
                                                event.preventDefault();
                                                navigate({
                                                    pathname: "/programApplications/application/" +
                                                        applicationObject.uuid,
                                                }, {
                                                    state: {
                                                        applicationObj: applicationObject,
                                                        admissionTerm: admissionTerm,
                                                    },
                                                });
                                                setTimeout(() => {
                                                    focusButtonComponent("bulk-merge-btn");
                                                }, 200);
                                            } }, { children: _jsx("span", { children: applicationObject === null || applicationObject === void 0 ? void 0 : applicationObject.title }) })), _jsx("span", Object.assign({ className: "title-desc" }, { children: translate("app.programApplications.external.application.bulk.merge.text") }))] })), _jsx("h1", Object.assign({ className: "merge-page-title" }, { children: translate("app.programApplications.external.application.bulk.verify.text") }))] }))] })), _jsx(WmButton, Object.assign({ buttonType: "primary", customBackground: "dark", id: "merge-btn", onClick: mergeRecordsHandler, disabled: inProgress }, { children: translate("app.programApplications.external.application.merge.count.records.label", {
                        verified: verifiedCount,
                        total: acceptedStudentApps === null || acceptedStudentApps === void 0 ? void 0 : acceptedStudentApps.length,
                    }) }))] })));
    };
    const renderFilterSection = () => {
        return (_jsxs("div", Object.assign({ className: "search-filter-section flex-row -justified -verticallycentered" }, { children: [_jsx("div", Object.assign({ className: "search-bar" }, { children: _jsx(WmSearch, { id: "search-by", placeholder: searchPlaceholdler, label: searchPlaceholdler, onInput: (event) => {
                            event.preventDefault();
                            setSearchText(event.target.value);
                        }, numResults: acceptedStudentApps === null || acceptedStudentApps === void 0 ? void 0 : acceptedStudentApps.length, value: searchText }) })), _jsx("div", Object.assign({ className: "filter-by" }, { children: _jsxs(WmSelect, Object.assign({ label: filterByLabel, labelPosition: "left", id: "filter-match-status", className: "filter-select" }, { children: [_jsx(WmOption, Object.assign({ "aria-label": "All Match Statuses", id: "All", selected: matchStatus === "All", onWmOptionSelected: (event) => {
                                    event.preventDefault();
                                    setMatchStatus(event.detail.id);
                                } }, { children: translate("app.programApplications.external.application.merge.filter.by.all.match.statuses") }), "All"), RecordMatchingTagList === null || RecordMatchingTagList === void 0 ? void 0 : RecordMatchingTagList.map((status) => {
                                const value = getI18nValueByKey(status.key, RecordMatchingTagList);
                                return (_jsx(WmOption, Object.assign({ role: "option", id: status.key, "aria-label": value, selected: matchStatus === status.key, onWmOptionSelected: (event) => {
                                        event.preventDefault();
                                        setMatchStatus(event.detail.id);
                                    } }, { children: value }), "option" + status.key));
                            })] }), "select-MatchStatus") })), _jsx("div", Object.assign({ className: "filter-by" }, { children: _jsx(WmSelect, Object.assign({ label: sortByLabel, labelPosition: "left", id: "sort-by-order", className: "filter-select" }, { children: RecordOrderOptions === null || RecordOrderOptions === void 0 ? void 0 : RecordOrderOptions.map((order) => {
                            return (_jsx(WmOption, Object.assign({ role: "option", id: order.key, "aria-label": order.value, selected: sortType === order.key, onWmOptionSelected: (event) => {
                                    event.preventDefault();
                                    setSortType(event.target.id);
                                } }, { children: getI18nValueByKey(order.key, RecordOrderOptions) }), "option-" + order.key));
                        }) }), "select-SortingOptions") }))] })));
    };
    const closeWarningModalHandler = () => {
        setShowDuplicateWarningModal(false);
        setDuplicateRecordsObj({});
        setTimeout(() => {
            focusButtonComponent("merge-btn");
        }, 200);
    };
    const selectedSubmissionCallback = (selectedSub) => {
        setSelectedSubmission(selectedSub);
        dispatch(getApplication({ applicationUuid: applicationObject === null || applicationObject === void 0 ? void 0 : applicationObject.uuid })).then((data) => {
            var _a;
            dispatch(getStudentSubmissionRecord({
                studentUuid: getStudentIdentifierForBackend(selectedSub),
                applicationUuid: (_a = data.payload) === null || _a === void 0 ? void 0 : _a.uuid,
            }));
            setAppObj(data === null || data === void 0 ? void 0 : data.payload);
        });
    };
    useEffect(() => {
        focusButtonComponent("go-back");
        let studentApplications = [];
        if (acceptedSubmissionList && acceptedSubmissionList.length > 0) {
            let studentAppUuids = acceptedStudentApps
                .filter((sub) => sub.applicationStatus === "ACCEPTED")
                .map((sub) => {
                studentApplications.push(sub);
                return sub.studentApplicationUuid;
            });
            dispatch(getProbableMatches({
                applicationUuid: applicationObject.uuid,
                studentAppUuids,
            }));
        }
    }, []);
    useEffect(() => {
        const filteredStudent = listOfAcceptedStudentApps === null || listOfAcceptedStudentApps === void 0 ? void 0 : listOfAcceptedStudentApps.filter((stud) => {
            var _a, _b;
            const searchTextCondition = isNullOrEmpty(searchText)
                ? true
                : ((_a = stud.firstName) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(searchText.toLowerCase())) ||
                    ((_b = stud.lastName) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes(searchText.toLowerCase())) ||
                    stud.email.toLowerCase().includes(searchText.toLowerCase());
            let matchStatusCondition = true;
            const { currentMatch, isUserUpdated } = probableMatches[stud.studentApplicationUuid] || {};
            if (matchStatus === "NO_MATCH") {
                matchStatusCondition = Boolean(!currentMatch);
            }
            else if (matchStatus === "UPDATED_MATCH") {
                matchStatusCondition = Boolean(currentMatch && isUserUpdated);
            }
            return searchTextCondition && matchStatusCondition;
        });
        filteredStudent === null || filteredStudent === void 0 ? void 0 : filteredStudent.sort((a, b) => {
            if (sortType === "ASC") {
                return (a.firstName + "," + a.lastName).localeCompare(b.firstName + "," + b.lastName);
            }
            else {
                return (b.firstName + "," + b.lastName).localeCompare(a.firstName + "," + a.lastName);
            }
        });
        setAcceptedStudentApps(filteredStudent);
    }, [searchText, matchStatus, sortType]);
    return (_jsxs(_Fragment, { children: [isPageLoading ? (_jsx(Loading, { message: "app.programApplications.external.application.generating.suggested.record.match" })) : openSystemAdminFor && selectedSubmission ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "sr-only", id: "dialog-wrapper-top", tabIndex: 0 }), _jsx(SystemAdminMatch, { source: "MergeApp", selectedSubmission: selectedSubmission, currentMatch: (_g = probableMatches[selectedSubmission.studentApplicationUuid]) === null || _g === void 0 ? void 0 : _g.currentMatch, probableList: probableMatches[selectedSubmission.studentApplicationUuid], studentApplicationUuid: selectedSubmission.studentApplicationUuid, applicationObject: appObj, openSystemAdminFor: openSystemAdminFor, setOpenSystemAdminFor: setOpenSystemAdminFor }), _jsx("div", { className: "sr-only", id: "dialog-wrapper-bottom", tabIndex: 0 })] })) : (_jsxs("main", Object.assign({ className: "merge-application-page" }, { children: [_jsx("section", Object.assign({ className: "immersive-header header merge-page-header" }, { children: renderPageHeader() })), _jsx("section", Object.assign({ className: "immersive-container" }, { children: _jsxs("div", Object.assign({ className: "merge-page-content" }, { children: [_jsx("p", Object.assign({ className: "merge-info-text" }, { children: translate("app.programApplications.external.application.bulk.verify.info.text") })), renderFilterSection(), (acceptedStudentApps === null || acceptedStudentApps === void 0 ? void 0 : acceptedStudentApps.length) > 0 ? (acceptedStudentApps === null || acceptedStudentApps === void 0 ? void 0 : acceptedStudentApps.map((sub) => {
                                    const { currentMatch, status, isUserUpdated = false, } = probableMatches[sub.studentApplicationUuid] || {};
                                    return (_jsx(StudentMatchRecord, { studentRecord: sub, studentMatchDetails: currentMatch, studentMatchStatus: status, isUserUpdated: isUserUpdated, setOpenSystemAdminFor: setOpenSystemAdminFor, selectedSubmissionCallback: selectedSubmissionCallback }, sub.studentUuid + sub.studentApplicationUuid));
                                })) : (_jsxs("div", Object.assign({ className: "empty-list", role: "alert", "aria-live": "assertive", "aria-atomic": true }, { children: [_jsx("div", Object.assign({ className: "no-results" }, { children: translate("global.noResults") })), _jsx("div", Object.assign({ className: "try-again" }, { children: translate("global.tryAgain") }))] })))] })) }))] }))), _jsx(DuplicateWarningModal, { showDuplicateWarningModal: showDuplicateWarningModal, duplicateRecordObj: duplicateRecordsObj, closeWarningModal: closeWarningModalHandler })] }));
};
export default MergeStudentApplication;
