import translate from "../../../i18n/translate";

export const StudentApplicationStatus = [
  {
    value_i18n: translate("app.programApplications.student.application.awaiting.review"),
    tag_i18n: translate("app.programApplications.student.application.awaiting.review"),
    key: "AWAITING_REVIEW",
    value: "AWAITING REVIEW",
  },
  {
    value_i18n: translate( "app.programApplications.student.application.accepted"),
    tag_i18n: translate( "app.programApplications.student.application.accepted"),
    key: "ACCEPTED",
    value: "ACCEPTED",
  },
  {
    value_i18n: translate( "app.programApplications.student.application.conditionally.accepted"),
    tag_i18n: translate( "app.programApplications.student.application.conditionally.accepted"),
    key: "CONDITIONALLY_ACCEPTED",
    value: "CONDITIONALLY ACCEPTED",
  },
  {
    value_i18n: translate( "app.programApplications.student.application.denied"),
    tag_i18n: translate( "app.programApplications.student.application.denied"),
    key: "DENIED",
    value: "DENIED",
  },
  {
    value_i18n: translate( "app.programApplications.student.application.draft"),
    tag_i18n: translate( "app.programApplications.student.application.draft"),
    key: "DRAFT",
    value: "DRAFT",
  },
  {
    value_i18n: translate( "app.programApplications.student.application.not.submitted"),
    tag_i18n: translate( "app.programApplications.student.application.not.submitted"),
    key: "NOT_SUBMITTED",
    value: "NOT SUBMITTED",
  },
];
