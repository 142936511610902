import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmModal, WmModalHeader, WmModalFooter, } from "@watermarkinsights/ripple-react";
import Inputfield from "./InputField";
export default function EditModalWithTwoButton(props) {
    if (!props.isOpen) {
        return _jsx(_Fragment, {});
    }
    else {
        return (_jsxs(WmModal, Object.assign({ open: props.isOpen, elementToFocus: "input-" + props.modalFor, id: "add-section-modal", onWmModalCloseTriggered: props.onSecondaryClick, onWmModalPrimaryTriggered: props.onPrimaryClick, onWmModalSecondaryTriggered: props.onSecondaryClick, "aria-label": props.modalHeading, uid: "" }, { children: [_jsx(WmModalHeader, { heading: props.modalHeading }), _jsx("div", Object.assign({ id: "content-add-section-modal", className: "content" }, { children: _jsx("div", Object.assign({ className: "modal-body" }, { children: _jsx(Inputfield, { titleError: props.titleError, dataLabel: props.dataLabel, modalTitle: props.modalTitle, updateTitleHandler: props.updateTitleHandler, inputRef: props.inputRef, modalFor: props.modalFor, modalId: props.modalFor, required: true }) })) })), _jsx(WmModalFooter, { primaryText: props.primaryText, secondaryText: props.secondaryText })] })));
    }
}
