import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Inputfield from "../../common/component/InputField";
import translate from "../../../i18n/translate";
import { useRef } from "react";
export default function ComponentLabelField({ fieldId, titleError, fieldValue, titleHandler, isRequired, setIsMandatory, errorMessage, isMandatory, }) {
    const titleRef = useRef();
    return (_jsxs("div", Object.assign({ className: "component-input" }, { children: [_jsx(Inputfield, { titleError: titleError, dataLabel: fieldId.toUpperCase() === "SECTION_HEADER"
                    ? "Section Header Text"
                    : "Component Label", modalTitle: fieldValue, updateTitleHandler: titleHandler, inputRef: titleRef, modalFor: fieldId, modalId: fieldId, required: isRequired !== undefined ? isRequired : true, customError: true, customErrorMessage: errorMessage, maxLength: fieldId.toUpperCase() !== "SMALL_TEXT_FIELD" ? 200 : 100 }), fieldId.toUpperCase() !== "SECTION_HEADER" && (_jsx("div", Object.assign({ className: "comp-info-text" }, { children: translate("app.programApplications.student.input.component.info.text") }))), fieldId.toUpperCase() !== "SECTION_HEADER" && (_jsxs("div", Object.assign({ className: "checkbox-option" }, { children: [_jsx("input", { type: "checkbox", className: "chkbx-input", id: "is-required-checkbox", checked: isMandatory, onChange: () => setIsMandatory(!isMandatory) }), _jsxs("label", Object.assign({ className: "chkbx", htmlFor: "is-required-checkbox" }, { children: [translate("app.programApplications.component.template.component.required"), " "] }))] })))] })));
}
