import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { WmButton, WmSearch } from "@watermarkinsights/ripple-react";
import translate from "../../i18n/translate";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { closeFlyout, isNullOrEmpty, openFlyout, } from "../../static/js/commons.js";
import { getApplication, applicationSelector, getAllOpenApplications, } from "../programApplications/ApplicationSlice";
import { createRequirement, updateRequirement } from "./TimelineSlice";
import Loading from "../../features/common/component/Loading";
export default function SelectApplication({ requirementId, setSelectApplication, setSelectRequirementType, timelineUuid, sectionType, currentRequirement, selectedApp, alreadySelectedApps, }) {
    const dispatch = useDispatch();
    const { isFetchingApplications, openApplicationsList } = useSelector(applicationSelector);
    const flyoutCloseToolTip = useIntl().formatMessage({
        id: "app.cohort.secondaryButtonText",
    });
    const searchPlaceholder = useIntl().formatMessage({
        id: "app.viewTimeline.add.application.search.placeholder",
    });
    const reqFlyoutHeadingText = useIntl().formatMessage({
        id: "app.licensure.plan.select.requirement.type.create.requirement",
    }, {
        type: "Application",
    });
    const reqTypeHeading = useIntl().formatMessage({
        id: "app.licensure.plan.select.requirement.type.standard.req.type",
    }, {
        type: "Application",
    });
    let [searchText, setSearchText] = useState("");
    const conditionalSelectedApp = [...alreadySelectedApps];
    if ((selectedApp === null || selectedApp === void 0 ? void 0 : selectedApp.uuid) && alreadySelectedApps.indexOf(selectedApp === null || selectedApp === void 0 ? void 0 : selectedApp.uuid) > -1)
        conditionalSelectedApp.splice(alreadySelectedApps.indexOf(selectedApp === null || selectedApp === void 0 ? void 0 : selectedApp.uuid), 1);
    const findIndexOfFirstElem = () => {
        let index = 0;
        for (let x of openApplicationsList) {
            if (!conditionalSelectedApp.includes(x.uuid)) {
                return index;
            }
            index++;
        }
        return -1;
    };
    const [selectedApplication, setSelectedApplication] = useState(selectedApp
        ? selectedApp
        : (openApplicationsList === null || openApplicationsList === void 0 ? void 0 : openApplicationsList.length) > 0 && findIndexOfFirstElem() > -1
            ? openApplicationsList[findIndexOfFirstElem()]
            : {});
    const [renderFilteredApplications, setRenderFilteredApplications] = useState(openApplicationsList);
    useEffect(() => {
        dispatch(getAllOpenApplications()).then(() => {
            setTimeout(() => openFlyout("select-application-flyout", "", selectedApp ? "input-" + selectedApp.uuid : "add-application-search"), 200);
        });
    }, []);
    useEffect(() => {
        setSelectedApplication(selectedApp
            ? selectedApp
            : (openApplicationsList === null || openApplicationsList === void 0 ? void 0 : openApplicationsList.length) > 0 && findIndexOfFirstElem() > -1
                ? openApplicationsList[findIndexOfFirstElem()]
                : {});
    }, [openApplicationsList]);
    useEffect(() => {
        if (searchText != "") {
            setRenderFilteredApplications(openApplicationsList.filter((app) => {
                return (app.title.toLowerCase().includes(searchText.toLowerCase()) ||
                    (isNullOrEmpty(app.description)
                        ? ""
                        : app.description
                            .toLowerCase()
                            .includes(searchText.toLowerCase())));
            }));
        }
        else {
            setRenderFilteredApplications(openApplicationsList);
        }
    }, [searchText, openApplicationsList]);
    const closeFlyoutHandler = (event) => {
        event.preventDefault();
        !selectedApp && setSelectRequirementType(true);
        setSelectApplication(false);
    };
    const addAppToLP = () => {
        let uuid = null;
        if (currentRequirement.uuid === "") {
            uuid = requirementId.substring(23, requirementId.length);
        }
        else {
            uuid =
                sectionType === "sections"
                    ? currentRequirement.sectionUuid
                    : currentRequirement.subSectionUuid;
        }
        const data = {
            timelineUuid: timelineUuid,
            sectionUuid: sectionType === "sections" ? uuid : null,
            subSectionUuid: sectionType === "sections" ? null : uuid,
            title: selectedApplication.title,
            description: selectedApplication.description,
            isStudentFileUploadRequired: false,
            sectionType: sectionType,
            uuid: currentRequirement.uuid,
            files: [],
            sortOrder: currentRequirement.sortOrder,
            applicationUuid: selectedApplication.uuid,
            requirementType: "APPLICATION",
        };
        if (data.title) {
            if (currentRequirement.uuid === "") {
                dispatch(createRequirement(data));
            }
            else {
                dispatch(updateRequirement(data));
            }
            setSelectApplication(false);
            closeFlyout();
        }
    };
    const renderHeader = () => {
        return (_jsxs("section", Object.assign({ className: "select-app-header immersive-header header flex-row -justified -verticallycentered" }, { children: [_jsxs("div", { children: [_jsx("div", Object.assign({ className: "go-back-link program-header flex-row -justified -verticallycentered" }, { children: _jsxs("div", Object.assign({ className: "bread-crumbs" }, { children: [_jsx(WmButton, Object.assign({ "button-type": "textonly", id: "go-back", className: "link", onClick: () => {
                                            setSelectApplication(false);
                                            setSelectRequirementType(true);
                                        } }, { children: _jsx("span", { children: translate("app.licensure.plan.select.requirement.type.label") }) })), _jsx("span", { children: selectedApp
                                            ? translate("app.viewTimeline.edit.application")
                                            : translate("app.viewTimeline.add.application") })] })) })), _jsx("h4", Object.assign({ className: "select-app-title" }, { children: translate("app.viewTimeline.add.application.flyout.title") }))] }), _jsx(WmButton, { className: "close-btn", buttonType: "navigational", icon: "close", tooltip: flyoutCloseToolTip, tooltipPosition: "bottom", id: "flyout-close", class: "hydrated", onClick: (event) => closeFlyoutHandler(event) })] })));
    };
    const renderFlyoutSearch = () => {
        return (_jsxs("section", Object.assign({ className: "add-app-search" }, { children: [_jsx(WmSearch, { id: "add-application-search", className: "add-application-search", placeholder: searchPlaceholder, label: searchPlaceholder, onInput: (event) => {
                        event.preventDefault();
                        setSearchText(event.target.value);
                    }, numResults: renderFilteredApplications.length, value: searchText }), _jsx("p", Object.assign({ className: "add-app-info" }, { children: translate("app.viewTimeline.add.application.info.text") }))] })));
    };
    const renderMainContent = () => {
        return (_jsx(_Fragment, { children: (openApplicationsList === null || openApplicationsList === void 0 ? void 0 : openApplicationsList.length) > 0 ? (_jsxs(_Fragment, { children: [renderFlyoutSearch(), (renderFilteredApplications === null || renderFilteredApplications === void 0 ? void 0 : renderFilteredApplications.length) > 0 ? (_jsx("ul", Object.assign({ className: "select-apps-list", role: "list" }, { children: renderFilteredApplications.map((application) => {
                            return (_jsxs("li", Object.assign({ className: "select-apps-list-item flex-row -justified -verticallycentered", role: "listitem" }, { children: [_jsxs("div", Object.assign({ className: "app-name" }, { children: [_jsx("input", { type: "radio", name: "Applications", id: "input-" + application.uuid, value: application.uuid, className: "radio-input", checked: selectedApplication.uuid == application.uuid, onChange: () => {
                                                    setSelectedApplication(application);
                                                }, disabled: conditionalSelectedApp.includes(application.uuid) }), _jsx("label", Object.assign({ htmlFor: "input-" + application.uuid, className: "radio app-radio-label" }, { children: _jsxs("div", Object.assign({ className: "app-title" }, { children: [application.title, _jsx("div", Object.assign({ className: "app-description" }, { children: application.description }))] })) }))] })), _jsxs(Link, Object.assign({ className: "preview-link", target: "_blank", to: "/programApplications/studentApplication/" +
                                            application.uuid, onClick: () => {
                                            dispatch(getApplication({
                                                applicationUuid: application.uuid,
                                            }));
                                        } }, { children: [translate("app.viewTimeline.add.application.preview"), " ", _jsx("span", { className: "mdi mdi-launch" })] }))] }), application.uuid));
                        }) }))) : (_jsxs("section", Object.assign({ className: "empty-list", role: "alert", "aria-live": "assertive", "aria-atomic": true }, { children: [_jsx("div", Object.assign({ className: "no-results" }, { children: translate("app.gatewaysLandingPage.noMatch") })), _jsx("div", Object.assign({ className: "try-again" }, { children: translate("app.gatewaysLandingPage.tryAgain") }))] })))] })) : (_jsxs("section", Object.assign({ className: "empty-application-flyout-view" }, { children: [_jsx("div", Object.assign({ className: "no-apps-label" }, { children: translate("app.programApplications.student.application.choose.application.flyout.empty.state.text3") })), _jsx("div", Object.assign({ className: "no-apps-text" }, { children: translate("app.programApplications.student.application.choose.application.flyout.empty.state.text4") }))] }))) }));
    };
    const renderFooter = () => {
        return (_jsx("footer", Object.assign({ className: "select-app-footer" }, { children: (openApplicationsList === null || openApplicationsList === void 0 ? void 0 : openApplicationsList.length) > 0 ? (_jsxs("div", Object.assign({ className: "button-collection" }, { children: [_jsx(WmButton, Object.assign({ buttonType: "secondary", id: "cancel-btn", onClick: (event) => closeFlyoutHandler(event) }, { children: translate("app.cohort.secondaryButtonText") })), _jsx(WmButton, Object.assign({ buttonType: "primary", id: "add-app-btn", onClick: addAppToLP }, { children: selectedApp
                            ? translate("app.requirement.save")
                            : translate("app.requirement.add") }))] }))) : (_jsx(WmButton, Object.assign({ buttonType: "primary", id: "close-btn", onClick: (event) => closeFlyoutHandler(event) }, { children: translate("app.programApplications.template.discardModal.secondayText") }))) })));
    };
    return (_jsxs("div", Object.assign({ className: "flyout-panel half-flyout show", role: "dialog", id: "select-application-flyout", tabIndex: -1, "aria-label": "Select Application" }, { children: [_jsx("div", { className: "overlay" }), _jsx("div", Object.assign({ className: "container" }, { children: isFetchingApplications ? (_jsx(Loading, {})) : (_jsxs(_Fragment, { children: [renderHeader(), renderMainContent(), renderFooter()] })) }))] })));
}
