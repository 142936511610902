var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_APPLICATION_ROOT, API_ROOT } from "../../env-config";
import { fetchWrapper } from "../common/fetchWrapper";
import constants from "../common/constants/applicationConstants.json";
export const loginUser = createAsyncThunk("users/login", ({ username, password }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield fetch(API_ROOT + "auth/login", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                username,
                password,
            }),
        });
        let data = yield response.json();
        for (let header of response.headers) {
            let [key, value] = header;
            if (key === constants.gatewaysCsrfHeaderName ||
                key === constants.applicationsCsrfHeaderName) {
                localStorage.setItem(key, value);
            }
        }
        if (response.status === 200) {
            localStorage.setItem("jwt", data.jwt);
            localStorage.setItem("username", username);
            localStorage.setItem("orgUuid", data.organizationUuid);
            localStorage.setItem("orgName", data.organizationName);
            return data;
        }
        else {
            let myData = Object.assign({}, data);
            if (data.message == "") {
                myData = Object.assign(Object.assign({}, data), { message: "Invalid username or password" });
            }
            return thunkAPI.rejectWithValue(myData);
        }
    }
    catch (e) {
        thunkAPI.rejectWithValue(e.response.data);
    }
}));
export const getUserRecords = createAsyncThunk("/getUserRecords", ({ orgUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_ROOT}api/organizations/${orgUuid}/user`, {}, null);
    return data ? data : error;
}));
export const getPAUserRecords = createAsyncThunk("/getUserRecords", ({ orgUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_APPLICATION_ROOT}api/organizations/${orgUuid}/user`, {}, null);
    return data ? data : error;
}));
export const getLoggedInUserInfo = createAsyncThunk("/logged-in-user-info", (thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const [data, error] = yield fetchWrapper("GET", `${API_ROOT}api/logged-in-user-info`, {}, null);
    // set orgUuid in local Storage to be used in rest of the pages
    let resData = yield data;
    if (data) {
        let userInfo = resData;
        if ((_a = userInfo.gatewaysUser) === null || _a === void 0 ? void 0 : _a.pinnedOrg) {
            localStorage.setItem("username", userInfo.gatewaysUser.email);
            localStorage.setItem("orgUuid", userInfo.gatewaysUser.pinnedOrg.uuid);
            localStorage.setItem("orgName", userInfo.gatewaysUser.pinnedOrg.title);
        }
    }
    return data ? data : error;
}));
export const getLoggedInUserInfoProgramApp = createAsyncThunk("/logged-in-user-info-prog-app", (thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    const [data, error] = yield fetchWrapper("GET", `${API_APPLICATION_ROOT}api/logged-in-user-info`, {}, null);
    // set orgUuid in local Storage to be used in rest of the pages
    let resData = yield data;
    if (data) {
        let userInfo = resData;
        if ((_b = userInfo.applicationsUser) === null || _b === void 0 ? void 0 : _b.pinnedOrg) {
            localStorage.setItem("username", userInfo.applicationsUser.email);
            localStorage.setItem("orgUuid", userInfo.applicationsUser.pinnedOrg.uuid);
            localStorage.setItem("orgName", userInfo.applicationsUser.pinnedOrg.title);
        }
    }
    return data ? data : error;
}));
export const logoutGateways = createAsyncThunk("/logoutGateways", (thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_ROOT}auth/logout`, {}, null);
    return data ? data : error;
}));
export const logoutProgramApplications = createAsyncThunk("/logoutProgramApplications", (thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_APPLICATION_ROOT}auth/logout`, {}, null);
    return data ? data : error;
}));
export const logoutSll = createAsyncThunk("/logoutSll", ({ url }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("DELETE", url, {}, null);
    return data ? data : error;
}));
export const userSliceInitialState = {
    uuid: "",
    username: "",
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    firstName: "",
    lastName: "",
    user: {},
    userInfo: {},
    redirectTo: "",
    userInfoProgApp: {},
    hasError: false,
    errorMsg: "",
};
export const userSlice = createSlice({
    name: "userData",
    initialState: userSliceInitialState,
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isFetching = false;
            return state;
        },
        setUsername: (state, { payload }) => {
            state.username = payload.name;
        },
        setError: (state, { payload }) => {
            state.hasError = true;
            state.errorMsg = payload;
        },
        resetError: (state) => {
            state.hasError = false;
            state.errorMsg = "";
        },
        // Reducer comes here
    },
    extraReducers: {
        // Extra reducer comes here
        "users/login/rejected": (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload
                ? payload.message
                : "Sorry, something went wrong.";
        },
        "users/login/pending": (state) => {
            state.isFetching = true;
        },
        "users/login/fulfilled": (state, { payload }) => {
            state.username = payload.name;
            state.firstName = payload.firstName;
            state.lastName = payload.lastName;
            state.isFetching = false;
            state.isSuccess = true;
        },
        "/getUserRecords/pending": (state, { payload }) => {
            state.isFetching = true;
        },
        "/getUserRecords/fulfilled": (state, { payload }) => {
            state.user = payload;
            state.isFetching = false;
            state.firstName = payload.firstName;
            state.lastName = payload.lastName;
        },
        "/logged-in-user-info/pending": (state, { payload }) => {
            state.isFetching = true;
        },
        "/logged-in-user-info/fulfilled": (state, { payload }) => {
            state.isFetching = false;
            state.userInfo = payload;
        },
        "/logoutGateways/fulfilled": (state, { payload }) => {
            state.isFetching = false;
            state.redirectTo = payload;
        },
        "/logged-in-user-info-prog-app/fulfilled": (state, { payload }) => {
            state.isFetching = false;
            state.userInfoProgApp = payload;
        },
        "/logoutProgramApplications/fulfilled": (state, { payload }) => {
            state.isFetching = false;
            state.redirectTo = payload;
        },
    },
});
export default userSlice.reducer;
export const clearState = userSlice.actions.clearState;
export const setUsername = userSlice.actions.setUsername;
export const setError = userSlice.actions.setError;
export const resetError = userSlice.actions.resetError;
export const userSelector = (state) => state.userData;
