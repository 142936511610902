import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from "react";
import Inputfield from "../../../features/common/component/InputField";
import { useIntl } from "react-intl";
import { isNullOrEmpty } from "../../../static/js/commons";
import { WmOption, WmSelect } from "@watermarkinsights/ripple-react";
import translate from "../../../i18n/translate";
export const inputFieldsDataId = {
    firstName: "default-comp-first-name",
    lastName: "default-comp-last-name",
    email: "default-comp-institution-email",
    admissionTerm: "default-comp-admission-term",
};
const CompositeComponent = ({ user, admissionTerm, dropList, appTemplateView, cohortTermPresent, canEdit, updateAdmissionTerm, firstName, lastName, email, updateFirstName, updateLastName, updateEmail, errorField, setErrorField, componentObj, isExternal, }) => {
    let disableComponent = appTemplateView
        ? appTemplateView
        : canEdit
            ? false
            : true;
    const firstNameLabel = useIntl().formatMessage({
        id: "app.programApplications.template.standard.template.field.name",
    }, {
        value: "First",
    });
    const lastNameLabel = useIntl().formatMessage({
        id: "app.programApplications.template.standard.template.field.name",
    }, {
        value: "Last",
    });
    const emailLabel = useIntl().formatMessage({
        id: "app.programApplications.template.standard.template.field.institution.email",
    });
    const termLabel = useIntl().formatMessage({
        id: "app.programApplications.template.standard.template.field.admission.term",
    });
    const requiredErrorMessage = useIntl().formatMessage({
        id: "app.programApplications.student.application.card.response.required.error.message",
    });
    const dropdownRequiredErrorMessage = useIntl().formatMessage({
        id: "app.programApplications.student.application.dropdown.required.error.message",
    });
    const wrongEmailErrorMessage = useIntl().formatMessage({
        id: "app.programApplications.small.text.field.component.type.email.error.message",
    });
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const emailRef = useRef();
    const firstNameTemplate = (_jsx(_Fragment, { children: _jsx(Inputfield, { titleError: errorField === null || errorField === void 0 ? void 0 : errorField.get(componentObj.componentType.toLowerCase() +
                inputFieldsDataId.firstName +
                componentObj.uuid), required: true, dataLabel: firstNameLabel, modalTitle: firstName ? firstName : "", updateTitleHandler: (event) => titleHandler(event, 0), modalFor: firstNameLabel, modalId: inputFieldsDataId.firstName + appTemplateView, disabled: disableComponent, inputRef: firstNameRef, customError: true, customErrorMessage: requiredErrorMessage }) }));
    const lastNameTemplate = (_jsx(_Fragment, { children: _jsx(Inputfield, { titleError: errorField === null || errorField === void 0 ? void 0 : errorField.get(componentObj.componentType.toLowerCase() +
                inputFieldsDataId.lastName +
                componentObj.uuid), required: true, dataLabel: lastNameLabel, modalTitle: lastName ? lastName : "", updateTitleHandler: (event) => titleHandler(event, 1), modalFor: lastNameLabel, modalId: inputFieldsDataId.lastName + appTemplateView, disabled: disableComponent, inputRef: lastNameRef, customError: true, customErrorMessage: requiredErrorMessage }) }));
    const emailTemplate = (_jsx(_Fragment, { children: _jsx(Inputfield, { titleError: errorField === null || errorField === void 0 ? void 0 : errorField.get(componentObj.componentType.toLowerCase() +
                inputFieldsDataId.email +
                componentObj.uuid), required: true, dataLabel: emailLabel, modalTitle: email ? email : "", updateTitleHandler: (event) => titleHandler(event, 2), modalFor: emailLabel, modalId: inputFieldsDataId.email + appTemplateView, disabled: disableComponent, inputRef: emailRef, customError: true, customErrorMessage: isNullOrEmpty(email === null || email === void 0 ? void 0 : email.trim())
                ? requiredErrorMessage
                : wrongEmailErrorMessage }) }));
    const admissionTermTemplate = useMemo(() => (_jsxs(WmSelect, Object.assign({ label: termLabel, labelPosition: "top", id: "term-dropdown-" + "default-comp-admission-term", disabled: cohortTermPresent ? cohortTermPresent : disableComponent, requiredField: isExternal, errorMessage: (errorField === null || errorField === void 0 ? void 0 : errorField.get(componentObj.componentType.toLowerCase() +
            inputFieldsDataId.admissionTerm +
            componentObj.uuid))
            ? dropdownRequiredErrorMessage
            : "" }, { children: [_jsx(WmOption, Object.assign({ id: "option-" + "default-comp-admission-term" + "-SelectOption", selected: isNullOrEmpty(admissionTerm), value: "" }, { children: translate("app.programApplications.select.option") }), "empty"), dropList === null || dropList === void 0 ? void 0 : dropList.map((option) => (_jsx(WmOption, Object.assign({ id: `option-${option.uuid}`, selected: option.uuid == admissionTerm, value: option.uuid, onWmOptionDeselected: (event) => {
                    event.preventDefault();
                    if (updateAdmissionTerm != undefined) {
                        updateAdmissionTerm(event.detail.changedOption.value);
                    }
                    errorField === null || errorField === void 0 ? void 0 : errorField.set(componentObj.componentType.toLowerCase() +
                        inputFieldsDataId.admissionTerm +
                        componentObj.uuid, false);
                    setErrorField && setErrorField(errorField);
                } }, { children: option.name }), "option" + option.name)))] }), "select-" + "default-comp-admission-term")), [dropList, admissionTerm, errorField]);
    const [inputFields, setInputFields] = useState([
        {
            dataLabel: firstNameLabel,
            dataId: "default-comp-first-name",
            dataValue: user == undefined
                ? firstName !== undefined
                    ? firstName
                    : ""
                : user === null || user === void 0 ? void 0 : user.firstName,
        },
        {
            dataLabel: lastNameLabel,
            dataId: inputFieldsDataId.lastName,
            dataValue: user == undefined
                ? lastName !== undefined
                    ? lastName
                    : ""
                : user === null || user === void 0 ? void 0 : user.lastName,
        },
        {
            dataLabel: emailLabel,
            dataId: inputFieldsDataId.email,
            dataValue: user == undefined ? (email !== undefined ? email : "") : user === null || user === void 0 ? void 0 : user.email,
        },
    ]);
    const titleHandler = (event, id) => {
        const updatedInputFields = [...inputFields];
        let updatedVal = event.target.value;
        updatedInputFields[id].dataValue = updatedVal;
        setInputFields(updatedInputFields);
        errorField.set(componentObj.componentType.toLowerCase() +
            updatedInputFields[id].dataId +
            componentObj.uuid, false);
        if (setErrorField)
            setErrorField(errorField);
        switch (id) {
            case 0:
                updateFirstName && updateFirstName(updatedVal);
                break;
            case 1:
                updateLastName && updateLastName(updatedVal);
                break;
            case 2:
                updateEmail && updateEmail(updatedVal);
                break;
            default:
        }
    };
    useEffect(() => {
        let updatedInputFields = [...inputFields];
        updatedInputFields[0].dataValue = firstName ? firstName : "";
        updatedInputFields[1].dataValue = lastName ? lastName : "";
        updatedInputFields[2].dataValue = email ? email : "";
        setInputFields(updatedInputFields);
    }, [firstName, lastName, email]);
    const renderInputField = (field) => {
        switch (field.dataId) {
            case inputFieldsDataId.firstName:
                return firstNameTemplate;
            case inputFieldsDataId.lastName:
                return lastNameTemplate;
            case inputFieldsDataId.email:
                return emailTemplate;
            case inputFieldsDataId.admissionTerm:
                return admissionTermTemplate;
        }
        return "";
    };
    return (_jsx("div", Object.assign({ className: "composite-body" }, { children: _jsx("div", Object.assign({ className: "composite-components-list" }, { children: inputFields.map((field, index) => (_jsx("div", Object.assign({ className: "composite-components-item" }, { children: renderInputField(field) }), index + field.dataId))) })) })));
};
export default CompositeComponent;
