import translate from "../../../i18n/translate";

export const RecordOrderOptions = [
  {
    value_i18n: translate("app.programApplications.external.application.merge.sort.by.alphabetical.ascending"),
    tag_i18n: translate("app.programApplications.external.application.merge.sort.by.alphabetical.ascending"),
    key: "ASC",
    value: " ASCENDING",
  },
  {
    value_i18n: translate("app.programApplications.external.application.merge.sort.by.alphabetical.descending"),
    tag_i18n: translate("app.programApplications.external.application.merge.sort.by.alphabetical.descending"),
    key: "DSC",
    value: "DESCENDING",
  },
];