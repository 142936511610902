import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { applicationSelector } from "../../programApplications/ApplicationSlice";
import { isNullOrEmpty } from "../../../static/js/commons";
const useSearchFilter = ({ searchTxt, selectedFilterBy, selectedAudienceType, selectedSortBy, selectedTab, }) => {
    const { applicationTemplateList, applicationsList } = useSelector(applicationSelector);
    const [searchText, setSearchText] = useState(searchTxt !== null && searchTxt !== void 0 ? searchTxt : "");
    const [selectedStatus, setSelectedStatus] = useState(selectedFilterBy !== null && selectedFilterBy !== void 0 ? selectedFilterBy : "ALL");
    const [selectedAudience, setSelectedAudience] = useState(selectedAudienceType !== null && selectedAudienceType !== void 0 ? selectedAudienceType : "ALL");
    const [selectedSortOption, setSelectedSortOption] = useState(selectedSortBy !== null && selectedSortBy !== void 0 ? selectedSortBy : {
        sortBy: "Title",
        isAscending: true,
    });
    const [renderFilteredList, setRenderFilteredList] = useState(applicationsList);
    const [renderApplicationTemplateList, setrenderApplicationTemplateList] = useState(applicationTemplateList);
    useEffect(() => {
        setRenderFilteredList(applicationsList);
    }, [applicationsList]);
    useEffect(() => {
        setrenderApplicationTemplateList(applicationTemplateList);
    }, [applicationTemplateList]);
    const getFilteredListByAudience = (list) => {
        return list === null || list === void 0 ? void 0 : list.filter((app) => {
            if (selectedAudience === "ALL") {
                return true;
            }
            else {
                return (app === null || app === void 0 ? void 0 : app.type) === selectedAudience;
            }
        });
    };
    const getFilteredListByStatus = (list) => {
        return list === null || list === void 0 ? void 0 : list.filter((app) => {
            var _a;
            if (selectedStatus === "ALL") {
                return true;
            }
            else {
                return selectedTab === "item-1"
                    ? app.status === selectedStatus
                    : ((_a = app.template) === null || _a === void 0 ? void 0 : _a.status) === selectedStatus;
            }
        });
    };
    const sortOrderHandler = (list) => {
        return selectedSortOption.isAscending ? list : list.reverse();
    };
    useEffect(() => {
        let updatedAppsList, updatedAppTemplatesList;
        if (!isNullOrEmpty(searchText)) {
            if (selectedTab === "item-1") {
                updatedAppsList = sortListHandler(getFilteredListByAudience(getFilteredListByStatus(applicationsList)).filter((app) => {
                    var _a, _b;
                    return (((_a = app === null || app === void 0 ? void 0 : app.title) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(searchText.toLowerCase())) ||
                        ((_b = app === null || app === void 0 ? void 0 : app.organizationObject) === null || _b === void 0 ? void 0 : _b.title.toLowerCase().includes(searchText.toLowerCase())));
                }));
                setRenderFilteredList(sortOrderHandler(updatedAppsList));
            }
            else {
                updatedAppTemplatesList = sortListHandler(getFilteredListByStatus(applicationTemplateList).filter((appTemplate) => {
                    var _a, _b;
                    return (_b = (_a = appTemplate === null || appTemplate === void 0 ? void 0 : appTemplate.template) === null || _a === void 0 ? void 0 : _a.title) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes(searchText.toLowerCase());
                }));
                setrenderApplicationTemplateList(sortOrderHandler(updatedAppTemplatesList));
            }
        }
        else {
            updatedAppsList = sortListHandler(getFilteredListByAudience(getFilteredListByStatus(applicationsList)));
            updatedAppTemplatesList = sortListHandler(getFilteredListByStatus(applicationTemplateList));
            setRenderFilteredList(sortOrderHandler(updatedAppsList));
            setrenderApplicationTemplateList(sortOrderHandler(updatedAppTemplatesList));
        }
    }, [
        selectedTab,
        searchText,
        applicationsList,
        applicationTemplateList,
        selectedStatus,
        selectedAudience,
        selectedSortOption,
    ]);
    const sortListHandler = (sortedList) => {
        var _a, _b, _c, _d;
        if (selectedSortOption.sortBy) {
            switch (selectedSortOption.sortBy) {
                case "Organization":
                    sortedList === null || sortedList === void 0 ? void 0 : sortedList.sort((a, b) => {
                        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                        return selectedTab === "item-1"
                            ? (_b = (_a = a.organizationObject) === null || _a === void 0 ? void 0 : _a.title) === null || _b === void 0 ? void 0 : _b.toLowerCase().localeCompare((_d = (_c = b.organizationObject) === null || _c === void 0 ? void 0 : _c.title) === null || _d === void 0 ? void 0 : _d.toLowerCase())
                            : (_g = (_f = (_e = a === null || a === void 0 ? void 0 : a.template) === null || _e === void 0 ? void 0 : _e.organizationObj) === null || _f === void 0 ? void 0 : _f.title) === null || _g === void 0 ? void 0 : _g.toLowerCase().localeCompare((_j = (_h = b === null || b === void 0 ? void 0 : b.template) === null || _h === void 0 ? void 0 : _h.organizationObj) === null || _j === void 0 ? void 0 : _j.title.toLowerCase());
                    });
                    break;
                case "Title":
                    sortedList === null || sortedList === void 0 ? void 0 : sortedList.sort((a, b) => {
                        var _a, _b, _c, _d;
                        return selectedTab === "item-1"
                            ? (_a = a.title) === null || _a === void 0 ? void 0 : _a.toLowerCase().localeCompare((_b = b.title) === null || _b === void 0 ? void 0 : _b.toLowerCase())
                            : (_c = a === null || a === void 0 ? void 0 : a.template) === null || _c === void 0 ? void 0 : _c.title.toLowerCase().localeCompare((_d = b === null || b === void 0 ? void 0 : b.template) === null || _d === void 0 ? void 0 : _d.title.toLowerCase());
                    });
                    break;
                case "Status":
                    sortedList.sort((a, b) => {
                        var _a, _b;
                        return (_a = a === null || a === void 0 ? void 0 : a.template) === null || _a === void 0 ? void 0 : _a.status.localeCompare((_b = b === null || b === void 0 ? void 0 : b.template) === null || _b === void 0 ? void 0 : _b.status);
                    });
                    break;
                case "UpdatedAt":
                    sortedList === null || sortedList === void 0 ? void 0 : sortedList.sort((a, b) => {
                        var _a, _b, _c, _d;
                        if (((_a = a === null || a === void 0 ? void 0 : a.template) === null || _a === void 0 ? void 0 : _a.updatedAt) && ((_b = b === null || b === void 0 ? void 0 : b.template) === null || _b === void 0 ? void 0 : _b.updatedAt)) {
                            return (((_c = a === null || a === void 0 ? void 0 : a.template) === null || _c === void 0 ? void 0 : _c.updatedAt) + "").localeCompare(((_d = b === null || b === void 0 ? void 0 : b.template) === null || _d === void 0 ? void 0 : _d.updatedAt) + "");
                        }
                        else {
                            return 0;
                        }
                    });
                    break;
                case "Last Closed":
                    sortedList = (_a = sortedList === null || sortedList === void 0 ? void 0 : sortedList.filter((app) => app.status === "CLOSED")) === null || _a === void 0 ? void 0 : _a.sort((a, b) => {
                        if (a.lastApplicationClosedAt && b.lastApplicationClosedAt) {
                            return (b.lastApplicationClosedAt + "").localeCompare(a.lastApplicationClosedAt + "");
                        }
                        else {
                            return 0;
                        }
                    }).concat((_b = sortedList
                        .filter((app) => app.status === "OPEN")) === null || _b === void 0 ? void 0 : _b.sort((a, b) => {
                        if (a.lastApplicationClosedAt && b.lastApplicationClosedAt) {
                            return (b.lastApplicationClosedAt + "").localeCompare(a.lastApplicationClosedAt + "");
                        }
                        else {
                            return 0;
                        }
                    }));
                    break;
                case "Last Opened":
                    sortedList = (_c = sortedList
                        .filter((app) => app.status === "OPEN")) === null || _c === void 0 ? void 0 : _c.sort((a, b) => {
                        if (a.lastApplicationOpenedAt && b.lastApplicationOpenedAt) {
                            return (b.lastApplicationOpenedAt + "").localeCompare(a.lastApplicationOpenedAt + "");
                        }
                        else {
                            return 0;
                        }
                    }).concat((_d = sortedList
                        .filter((app) => app.status === "CLOSED")) === null || _d === void 0 ? void 0 : _d.sort((a, b) => {
                        if (a.lastApplicationOpenedAt && b.lastApplicationOpenedAt) {
                            return (b.lastApplicationOpenedAt + "").localeCompare(a.lastApplicationOpenedAt + "");
                        }
                        else {
                            return 0;
                        }
                    }));
                    break;
                case "Last Submission":
                    sortedList.sort((a, b) => {
                        if (b.lastSubmissionAt && a.lastSubmissionAt) {
                            return (b.lastSubmissionAt + "").localeCompare(a.lastSubmissionAt + "");
                        }
                        else {
                            return 0;
                        }
                    });
                    break;
                case "Last Updated":
                    sortedList.sort((a, b) => {
                        if (b.updatedAt && a.updatedAt) {
                            return (b.updatedAt + "").localeCompare(a.updatedAt + "");
                        }
                        else {
                            return 0;
                        }
                    });
                    break;
                default:
                    sortedList;
            }
        }
        return sortedList;
    };
    return {
        searchText,
        setSearchText,
        selectedStatus,
        setSelectedStatus,
        renderFilteredList,
        selectedSortOption,
        setSelectedSortOption,
        renderApplicationTemplateList,
        selectedAudience,
        setSelectedAudience,
    };
};
export default useSearchFilter;
