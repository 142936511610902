var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, useEffect, useRef, useState } from "react";
import translate from "../../i18n/translate";
import { WmOption, WmSelect, WmButton } from "@watermarkinsights/ripple-react";
import { useIntl } from "react-intl";
import { StudentApplicationStatus } from "./data/StudentApplicationStatus.js";
import { getI18nValueByKey, getValueByKey } from "../common/commonActions";
import { openFlyout, focusButtonComponent, closeFlyout, isNullOrEmpty, } from "../../static/js/commons.js";
import { useDispatch } from "react-redux";
import { updateStudentSubmissionStatus, } from "./ApplicationSlice";
import TextAreaField from "../../features/common/component/TextAreaField";
import { HOST_NAME } from "../../env-config";
export default function ChangeSubmissionStatus({ setOpenChangeStatus, studentUuid, applicationObject, oldStatus, studentApplicationId, }) {
    var _a;
    const selectNewStatus = useIntl().formatMessage({
        id: "app.openStudentReview.selectNewStatus",
    });
    const [statusError, setStatusError] = useState(false);
    const [noteError, setNoteError] = useState(false);
    const [newStatus, setNewStatus] = useState("SelectOption");
    const [noteForStudent, setNoteForStudent] = useState("");
    const [allowResubmission, setAllowResubmission] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        setTimeout(() => openFlyout("view-changestatus-flyout", "status-change-btn", "close-change-status-btn"), 200);
    }, []);
    const noteInConditionallyAccepted = useIntl().formatMessage({
        id: "app.programApplications.student.review.cond.accepted.modal.note",
    }, {
        petitioner: applicationObject.type == "INTERNAL" ? "students" : "applicant",
    });
    const statusChangeModalInputLabel = useIntl().formatMessage({
        id: "app.programApplications.student.review.status.change.modal.text.area.label",
    }, {
        petitioner: applicationObject.type == "INTERNAL" ? "Student" : "Applicant",
    });
    const noteErrorText = useIntl().formatMessage({
        id: "app.programApplications.student.review.note.to.student.error.text",
    });
    const firstUpdate = useRef(true);
    const updateNewStatusHandler = (event, value) => {
        event.preventDefault();
        if (value !== "SelectOption") {
            setStatusError(false);
        }
        setNewStatus(value);
        if (newStatus === "SelectOption" && firstUpdate.current) {
            firstUpdate.current = false;
            if (value === "CONDITIONALLY_ACCEPTED") {
                setAllowResubmission(true);
            }
            else {
                setAllowResubmission(false);
                setNoteForStudent("");
            }
        }
        setNoteError(false);
    };
    const closeFlyoutHandler = (event) => {
        event.preventDefault();
        setOpenChangeStatus(false);
        closeFlyout();
    };
    const changeStatus = (event) => __awaiter(this, void 0, void 0, function* () {
        var _b, _c;
        event.preventDefault();
        if (newStatus === "SelectOption") {
            setStatusError(true);
            (_b = document.getElementById("new-status-select")) === null || _b === void 0 ? void 0 : _b.focus();
            return;
        }
        else if (newStatus === "CONDITIONALLY_ACCEPTED" &&
            isNullOrEmpty(noteForStudent.trim())) {
            setNoteError(true);
            (_c = document.getElementById("status-change-note-field")) === null || _c === void 0 ? void 0 : _c.focus();
            return;
        }
        else {
            setNoteError(false);
        }
        if (newStatus !== "SelectOption") {
            let link = null;
            if (applicationObject.type == "EXTERNAL") {
                link = `${HOST_NAME}/externalApplications/${applicationObject.uuid}/submission-id/${studentApplicationId}`;
            }
            dispatch(updateStudentSubmissionStatus({
                studentUuid: studentUuid,
                applicationUuid: applicationObject.uuid,
                applicationStatus: newStatus,
                allowResubmission,
                noteForStudent,
                resubmissionLink: link,
            }));
            closeFlyoutHandler(event);
        }
        setTimeout(() => {
            focusButtonComponent("status-change-btn");
        }, 100);
    });
    return (_jsxs("div", Object.assign({ className: "flyout-panel half-flyout show", role: "dialog", id: "view-changestatus-flyout", tabIndex: -1, "aria-label": "Change Status Flyout" }, { children: [_jsx("div", { className: "overlay" }), _jsxs("div", Object.assign({ className: "container" }, { children: [_jsxs("section", Object.assign({ className: "immersive-header header change-status-header flexible-header flyout-header-change-status" }, { children: [_jsx("div", Object.assign({ className: "title" }, { children: translate("app.openStudentReview.change.status") })), _jsx(WmButton, { buttonType: "navigational", icon: "close", tooltip: "Cancel", tooltipPosition: "bottom", id: "close-change-status-btn", class: "hydrated", onClick: closeFlyoutHandler })] })), _jsx("section", Object.assign({ className: "content change-status-content" }, { children: _jsxs("div", Object.assign({ className: "content-card" }, { children: [_jsxs("div", Object.assign({ className: "required-field" }, { children: [translate("app.cohort.requiredText.prefix"), _jsx("span", Object.assign({ className: "reqd" }, { children: "*" })), translate("app.cohort.requiredText.suffix")] })), _jsxs("div", { children: [_jsxs(WmSelect, Object.assign({ label: "New Status ", requiredField: true, id: "new-status-select", errorMessage: statusError ? selectNewStatus : "", "data-testid": "status-select" }, { children: [_jsx(WmOption, Object.assign({ "aria-label": "Select Option", id: "option-SelectOption", selected: newStatus === "SelectOption" }, { children: translate("app.requirement.file_type_select_option") }), "option-Select Option"), StudentApplicationStatus.map((resultStatus) => resultStatus.key).map((status) => {
                                                    if (status === "NOT_SUBMITTED")
                                                        return "";
                                                    return (_jsx(Fragment, { children: status !== "DRAFT" ? (_jsx(WmOption, Object.assign({ "aria-label": getValueByKey(status, StudentApplicationStatus), id: "option-" + status, value: newStatus, disabled: oldStatus === status, selected: status === newStatus, "data-testid": `status-option-${status}`, onWmOptionSelected: (event) => {
                                                                updateNewStatusHandler(event, event.target.id.slice(7));
                                                            } }, { children: getI18nValueByKey(status, StudentApplicationStatus) }), "option" + status)) : ("") }, "key-" + status));
                                                })] })), _jsxs(_Fragment, { children: [_jsx(TextAreaField, { fieldLabel: statusChangeModalInputLabel, fieldId: "status-change-note-field", isRequired: (_a = newStatus === "CONDITIONALLY_ACCEPTED") !== null && _a !== void 0 ? _a : false, textareaError: noteError, fieldValue: noteForStudent, onChangeHandler: (event) => {
                                                        setNoteError(false);
                                                        setNoteForStudent(event.target.value);
                                                    }, placeHolder: newStatus === "CONDITIONALLY_ACCEPTED"
                                                        ? noteInConditionallyAccepted
                                                        : "", maxLength: 1000, errorMessage: noteErrorText }), (newStatus === "CONDITIONALLY_ACCEPTED" ||
                                                    (newStatus === "DENIED" &&
                                                        applicationObject.type != "EXTERNAL")) && (_jsxs("div", Object.assign({ className: "checkbox-option" }, { children: [_jsx("input", { type: "checkbox", className: "chkbx-input", id: "is-allow-resubmission", checked: allowResubmission, onChange: (e) => {
                                                                setAllowResubmission(e.target.checked);
                                                            }, "data-testid": "allow-resubmission-checkbox" }), _jsx("label", Object.assign({ className: "chkbx", htmlFor: "is-allow-resubmission" }, { children: translate("app.programApplications.student.review.status.change.modal.resubmission.checkbox.label") }))] })))] })] })] })) })), _jsxs("section", Object.assign({ className: "change-status-flyout-footer change-status-footer" }, { children: [_jsx(WmButton, Object.assign({ buttonType: "secondary", id: "close-flyout-button", onClick: closeFlyoutHandler }, { children: translate("app.cohort.secondaryButtonText") })), _jsx(WmButton, Object.assign({ buttonType: "primary", id: "change-statuses-button", onClick: changeStatus }, { children: translate("app.cohort.saveButtonText") }))] }))] }))] })));
}
