import { combineReducers } from "@reduxjs/toolkit";
import userData from "../features/login/UserSlice";
import timelineSliceData from "../features/licensurePlans/TimelineSlice";
import cohortSliceData from "../features/cohorts/CohortSlice";
import applicationSliceData from "../features/programApplications/ApplicationSlice";
const rootReducer = combineReducers({
    userData,
    timelineSliceData,
    cohortSliceData,
    applicationSliceData,
});
export default rootReducer;
