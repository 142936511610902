import translate from "../../../i18n/translate";

export const ExtractStatus = [
  {
    value_i18n: translate("app.licensure.plan.extracts.status.creating.extract"),
    value: "pending",
    key: "PENDING",
  },
  {
    value_i18n: translate("global.completed"),
    value: "completed",
    key: "COMPLETED",
  },
  {
    value_i18n: translate("global.failed"),
    value: "failed",
    key: "FAILED",
  },
];