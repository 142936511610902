import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import translate from "../../i18n/translate";
import { WmButton } from "@watermarkinsights/ripple-react";
import { useIntl } from "react-intl";
import { closeFlyout, focusButtonComponent, openFlyout, } from "../../static/js/commons";
import { format } from "date-fns";
import { ExtractStatus } from "./data/ExtractStatus";
import { downloadExtract } from "./CohortSlice";
import { getI18nValueByKey, getValueByKey, } from "../../features/common/commonActions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
const ExtractDetails = ({ extract, setOpenExtractDetails, focusId, }) => {
    const flyoutCloseToolTip = useIntl().formatMessage({
        id: "app.cohort.secondaryButtonText",
    });
    const dispatch = useDispatch();
    useEffect(() => {
        setTimeout(() => openFlyout("extract-details-flyout", focusId, "flyout-close"), 200);
    }, []);
    const closeFlyoutHandler = () => {
        setOpenExtractDetails(false);
        closeFlyout();
        focusButtonComponent(focusId);
    };
    const renderContent = () => {
        var _a, _b;
        return (_jsxs("div", Object.assign({ className: "extract-details-content" }, { children: [_jsxs("section", Object.assign({ className: "header-content flex-row -justified -verticallycentered" }, { children: [_jsx("h4", Object.assign({ className: "heading" }, { children: translate("app.licensure.plan.extracts.details") })), _jsx(WmButton, { className: "download-extract", buttonType: "navigational", icon: "close", tooltip: flyoutCloseToolTip, tooltipPosition: "bottom", id: "flyout-close", class: "hydrated", onClick: closeFlyoutHandler })] })), _jsxs("section", Object.assign({ className: "body-content" }, { children: [_jsxs("div", Object.assign({ className: "row flex-row -justified -verticallycentered" }, { children: [_jsx("div", Object.assign({ className: "col" }, { children: _jsx("h5", Object.assign({ className: "col-title" }, { children: translate("global.creation.date") })) })), _jsx("div", Object.assign({ className: "col col-value" }, { children: (extract === null || extract === void 0 ? void 0 : extract.status) !== "COMPLETED" ? (_jsx("span", Object.assign({ className: getValueByKey(extract === null || extract === void 0 ? void 0 : extract.status, ExtractStatus) }, { children: getI18nValueByKey(extract === null || extract === void 0 ? void 0 : extract.status, ExtractStatus) }))) : ((extract === null || extract === void 0 ? void 0 : extract.createdAt) &&
                                        translate("global.timestamp", {
                                            date: format(new Date(extract === null || extract === void 0 ? void 0 : extract.createdAt), "P"),
                                            time: format(new Date(extract === null || extract === void 0 ? void 0 : extract.createdAt), "p"),
                                        })) }))] })), _jsxs("div", Object.assign({ className: "row flex-row -justified -verticallycentered" }, { children: [_jsx("div", Object.assign({ className: "col" }, { children: _jsx("h5", Object.assign({ className: "col-title" }, { children: translate("app.openCohort.students") })) })), _jsx("div", Object.assign({ className: "col col-value" }, { children: (_a = extract === null || extract === void 0 ? void 0 : extract.users) === null || _a === void 0 ? void 0 : _a.map((user) => (_jsx("div", { children: `${user === null || user === void 0 ? void 0 : user.firstName} ${user === null || user === void 0 ? void 0 : user.lastName}` }, user === null || user === void 0 ? void 0 : user.uuid))) }))] })), _jsxs("div", Object.assign({ className: "row flex-row -justified -verticallycentered" }, { children: [_jsx("div", Object.assign({ className: "col" }, { children: _jsx("h5", Object.assign({ className: "col-title" }, { children: translate("app.licensure.plan.extracts.licensure.plan.req.text") })) })), _jsx("div", Object.assign({ className: "col col-value" }, { children: (_b = extract === null || extract === void 0 ? void 0 : extract.extractRequirement) === null || _b === void 0 ? void 0 : _b.map((extractReq) => {
                                        var _a;
                                        return (_jsx("div", { children: (_a = extractReq === null || extractReq === void 0 ? void 0 : extractReq.requirement) === null || _a === void 0 ? void 0 : _a.title }));
                                    }) }))] })), (extract === null || extract === void 0 ? void 0 : extract.status) === "COMPLETED" ? (_jsx(WmButton, Object.assign({ className: "extract-download-button", buttonType: "secondary", icon: "f1da", id: `download-${extract.uuid}`, onClick: () => {
                                downloadExtract({
                                    fileUuid: extract.uuid,
                                    filePath: extract.zipFilePath,
                                });
                            } }, { children: translate("app.licensure.plan.extracts.download") }))) : null] }))] })));
    };
    return (_jsxs("div", Object.assign({ className: "flyout-panel half-flyout show", role: "dialog", id: "extract-details-flyout", tabIndex: -1, "aria-label": "Extract Details" }, { children: [_jsx("div", { className: "overlay" }), _jsx("div", Object.assign({ className: "container" }, { children: renderContent() }))] })));
};
export default ExtractDetails;
