import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmButton } from "@watermarkinsights/ripple-react";
import { getIdByKey, getValueByKey } from "../../common/commonActions";
import Inputfield from "../../common/component/InputField";
import translate from "../../../i18n/translate";
import { useRef } from "react";
import { focusButtonComponent, isNullOrEmpty, } from "../../../static/js/commons";
import { TemplateComponentList } from "../data/TemplateComponentList.js";
import { useIntl } from "react-intl";
export default function TemplateComponentOptions({ optionList, setOptionList, componentType, }) {
    const inputRef = useRef();
    const dropdownRequiredErrorMsg = useIntl().formatMessage({
        id: "app.programApplications.component.dropdown.options.required.msg",
    });
    const checkboxRequiredErrorMsg = useIntl().formatMessage({
        id: "app.programApplications.component.checkbox.options.required.msg",
    });
    const radioRequiredErrorMsg = useIntl().formatMessage({
        id: "app.programApplications.component.radio.options.required.msg",
    });
    const addOption = (event) => {
        event.preventDefault();
        setOptionList([
            ...optionList,
            {
                uuid: "",
                templateComponentUuid: "",
                text: "",
                sortOrder: optionList.length,
                valid: false,
            },
        ]);
        setTimeout(() => {
            var _a;
            (_a = document
                .getElementById("input-option-" + (optionList.length + 1))) === null || _a === void 0 ? void 0 : _a.focus();
        }, 10);
    };
    const deleteOption = (event, index) => {
        event.preventDefault();
        let options = [...optionList];
        options.splice(index, 1);
        options = options.map((opt, i) => {
            return Object.assign(Object.assign({}, opt), { sortOrder: i });
        });
        setOptionList(options);
        if (optionList.length === 3) {
            focusButtonComponent("add-option-btn");
        }
        else if (optionList.length > 3) {
            focusButtonComponent("remove-option-btn-" + (index - 1));
        }
    };
    return (_jsxs("div", Object.assign({ className: "options-component" }, { children: [_jsx("h5", Object.assign({ className: "options-label" }, { children: translate("app.programApplications.component.options.label", {
                    componentName: componentType === "MULTISELECT_CHECKBOXES"
                        ? "Checkbox"
                        : getValueByKey(componentType, TemplateComponentList),
                }) })), _jsx("p", Object.assign({ className: "options-info" }, { children: translate("app.programApplications.component.options.info", {
                    componentName: componentType === "RADIO_BUTTON"
                        ? "Radio Buttons"
                        : getIdByKey(componentType, TemplateComponentList),
                }) })), optionList === null || optionList === void 0 ? void 0 : optionList.map((elem, index) => {
                return (_jsxs("div", Object.assign({ className: "options-container flex-row -verticallycentered -justified" }, { children: [_jsx("div", Object.assign({ id: "options-input-" + index, className: "options-input" }, { children: _jsx(Inputfield, { titleError: elem.valid, dataLabel: `Option ${index + 1} `, required: true, modalTitle: elem.text, maxLength: 50, updateTitleHandler: (event) => {
                                    event.preventDefault();
                                    const list = [...optionList];
                                    list[index] = Object.assign(Object.assign({}, list[index]), { text: event.target.value, valid: isNullOrEmpty(event.target.value.trim())
                                            ? true
                                            : false, sortOrder: index });
                                    setOptionList(list);
                                }, inputRef: inputRef, modalFor: "option-" + (index + 1), modalId: "option-" + (index + 1), customError: true, customErrorMessage: componentType === "DROPDOWN"
                                    ? dropdownRequiredErrorMsg
                                    : componentType === "RADIO_BUTTON"
                                        ? radioRequiredErrorMsg
                                        : checkboxRequiredErrorMsg }) })), optionList.length > 2 ? (_jsx(WmButton, { id: `remove-option-btn-${index}`, className: elem.valid ? "" : "remove-option-btn", "button-type": "icononly", tooltip: "Remove Option", tooltipPosition: "top", icon: "f1c0", onClick: (event) => {
                                deleteOption(event, index);
                            } })) : null] }), "option-" + index));
            }), optionList.length < 20 ? (_jsx(WmButton, Object.assign({ id: "add-option-btn", className: "add-option-btn", "button-type": "textonly", icon: "f419", onClick: (event) => {
                    addOption(event);
                } }, { children: translate("app.programApplications.component.template.component.options.add.button") }))) : null] })));
}
