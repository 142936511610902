import { isNullOrEmpty } from "../../static/js/commons";
export const filterByAdmissionTerm = (list, admissionTermUuid) => {
    if (!Array.isArray(list)) {
        return [];
    }
    return list.filter((item) => isNullOrEmpty(admissionTermUuid) ||
        item.admissionTermUuid === admissionTermUuid);
};
export const sortByCreatedAt = (list) => {
    return list.sort((first, second) => {
        const dateA = new Date(first.createdAt || "");
        const dateB = new Date(second.createdAt || "");
        return dateB.getTime() - dateA.getTime();
    });
};
export const formatDateInMMDDYYYY = (date) => {
    if (date instanceof Date && !isNaN(date.getTime())) {
        const year = date.getFullYear();
        const month = (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1);
        const day = (date.getDate() < 10 ? "0" : "") + date.getDate();
        return `${month}/${day}/${year}`;
    }
    return "";
};
// Regular expression to match 24-hour format time (HH:MM)
export const is24HourFormat = (timeString) => {
    const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    return Boolean(timeString) && regex.test(timeString);
};
export const convertTo12HourFormat = (time24) => {
    if (!is24HourFormat(time24)) {
        return time24;
    }
    // Split the time string into hours and minutes
    const [hours24, minutes] = time24.split(":");
    // Convert hours to integer
    let hours = parseInt(hours24);
    // Determine AM/PM
    const ampm = hours >= 12 ? "PM" : "AM";
    // Adjust hours to 12-hour format
    if (hours > 12) {
        hours -= 12;
    }
    else if (hours === 0) {
        hours = 12;
    }
    // Format the time in 12-hour format
    const time12 = hours + ":" + minutes + " " + ampm;
    return time12;
};
export const formatTimeInHHMM = (date) => {
    if (date instanceof Date && !isNaN(date.getTime())) {
        const hours = (date.getHours() < 10 ? "0" : "") + date.getHours();
        const minutes = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
        return `${hours}:${minutes}`;
    }
    return "";
};
