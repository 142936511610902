import translate from "../../../i18n/translate";

export const cohortTimelineStatus = [
  {
    value_i18n: translate("app.cohortTimelineStatus.unpublished"),
    tag_i18n: translate("app.cohortTimelineStatus.tag_unpublished"),
    key: "UNPUBLISHED",
    value: "Not Connected",
  },
  {
    value_i18n: translate("app.cohortTimelineStatus.published"),
    tag_i18n: translate("app.cohortTimelineStatus.tag_connected"),
    key: "PUBLISHED",
    value: "Connected",
  },
  {
    value_i18n: translate("app.cohortTimelineStatus.archived"),
    tag_i18n: translate("app.cohortTimelineStatus.tag_archived"),
    key: "ARCHIVED",
    value: "Connected",
  },
  {
    value_i18n: translate("app.cohortTimelineStatus.scheduled"),
    tag_i18n: translate("app.cohortTimelineStatus.tag_scheduled"),
    key: "SCHEDULED",
    value: "Scheduled",
  },
  {
    value_i18n: translate("app.cohortTimelineStatus.all"),
    tag_i18n: translate("app.cohortTimelineStatus.all"),
    key: "ALL",
    value: "All",
  },
];
