import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { WmButton } from "@watermarkinsights/ripple-react";
import Footer from "../../features/common/footer/Footer";
import Header from "../../features/common/header/Header";
import SideNav from "../../features/common/sidenav/SideNav";
import translate from "../../i18n/translate";
import { useEffect, useState } from "react";
import ChooseApplication from "./ChooseApplication";
import { applicationSelector, getAllStudentApplications, getStudentResponses, getOrganizationAndChildOrganizations, getStudentApplication, } from "./ApplicationSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Card from "../../features/common/component/Card";
import { getI18nValueByKey, getValueByKey, } from "../../features/common/commonActions";
import { StudentApplicationStatus } from "./data/StudentApplicationStatus";
import { format } from "date-fns";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { focusButtonComponent, isNullOrEmpty } from "../../static/js/commons";
import { getPAUserRecords, getUserRecords, userSelector, } from "../login/UserSlice";
import { cohortSelector, getProgram, getStudentApplicationCohort, getTerms, } from "../cohorts/CohortSlice";
import ViewMore from "../common/component/ViewMore";
import Loading from "../../features/common/component/Loading";
const StudentLandingPage = (props) => {
    document.title = "Program Applications | Watermark";
    const navigate = useNavigate();
    const { studentApplicationResponseList, isFetchingStudetApplicationData, studentApplicationList, isFetchinStudentApplicationResponseData, } = useSelector(applicationSelector);
    const { user, userInfoProgApp } = useSelector(userSelector);
    const { studentApplicationCohort } = useSelector(cohortSelector);
    const [chooseApplication, setChooseApplication] = useState(false);
    const [expandIndex, setExpandIndex] = useState(null);
    const dispatch = useDispatch();
    useEffect(() => {
        var _a, _b, _c, _d;
        let orgUuid = localStorage.getItem("orgUuid");
        if ((_b = (_a = userInfoProgApp === null || userInfoProgApp === void 0 ? void 0 : userInfoProgApp.sllUser) === null || _a === void 0 ? void 0 : _a.available_apps) === null || _b === void 0 ? void 0 : _b.includes("gateways")) {
            dispatch(getUserRecords({ orgUuid }));
        }
        else {
            dispatch(getPAUserRecords({ orgUuid }));
        }
        dispatch(getStudentResponses());
        dispatch(getAllStudentApplications());
        dispatch(getOrganizationAndChildOrganizations());
        if (!isNullOrEmpty(orgUuid) &&
            ((_d = (_c = userInfoProgApp === null || userInfoProgApp === void 0 ? void 0 : userInfoProgApp.sllUser) === null || _c === void 0 ? void 0 : _c.available_apps) === null || _d === void 0 ? void 0 : _d.includes("gateways"))) {
            dispatch(getTerms({
                orgUuid: orgUuid,
            }));
        }
    }, []);
    useEffect(() => {
        if (!chooseApplication) {
            dispatch(getAllStudentApplications());
        }
    }, [chooseApplication]);
    const lastUpdatedLabel = useIntl().formatMessage({
        id: "app.programApplications.student.application.card.last.updated",
    });
    const lastSubmittedLabel = useIntl().formatMessage({
        id: "app.programApplications.student.application.card.submitted",
    });
    const redirectToLP = (userRequirementResultUuid, studentApplicationUuid) => {
        if (userRequirementResultUuid != null) {
            dispatch(getStudentApplicationCohort({ userRequirementResultUuid }))
                .then((data) => {
                let cohort;
                cohort = data.payload;
                if (!isNullOrEmpty(cohort)) {
                    let programUuid = cohort.programUuid;
                    dispatch(getProgram({ programUuid })).then((result) => {
                        var _a, _b;
                        let program = result.payload;
                        navigate({
                            pathname: `/timeline/${(_a = cohort === null || cohort === void 0 ? void 0 : cohort.cohortTimeline) === null || _a === void 0 ? void 0 : _a.timelineUuid}/cohort/${cohort === null || cohort === void 0 ? void 0 : cohort.uuid}/student/${user === null || user === void 0 ? void 0 : user.uuid}`,
                        }, {
                            state: {
                                selectedUuid: studentApplicationUuid,
                                timelineUuid: (_b = studentApplicationCohort === null || studentApplicationCohort === void 0 ? void 0 : studentApplicationCohort.cohortTimeline) === null || _b === void 0 ? void 0 : _b.timelineUuid,
                                currentCohort: cohort,
                                programUuid: programUuid,
                                student: user,
                                fromStudentLandingPage: true,
                                currentProgramData: program,
                                fromProgramApplication: true,
                            },
                        });
                    });
                }
            })
                .then(() => {
                focusButtonComponent("go-to-previous-state");
            });
        }
    };
    const getInfoTextByStatus = (status, note, isResubmissionAllowed, index) => {
        switch (status) {
            case "DRAFT":
                return renderStatusWiseContent(status, "mdi-flag-variant", note, "app.programApplications.student.application.card.draft.info.text", isResubmissionAllowed, index);
            case "AWAITING_REVIEW":
                return renderStatusWiseContent(status, "mdi-account-clock", note, "app.programApplications.student.application.card.awaiting.review.info.text", isResubmissionAllowed, index);
            case "ACCEPTED":
                return renderStatusWiseContent(status, "mdi-seal", note, "app.programApplications.student.application.card.accepted.info.text", isResubmissionAllowed, index);
            case "CONDITIONALLY_ACCEPTED":
                return renderStatusWiseContent(status, "mdi-progress-check", note, "app.programApplications.student.application.card.conditionally.accepted.info.text", isResubmissionAllowed, index);
            case "DENIED":
                return renderStatusWiseContent(status, "mdi-alert-octagram", note, "app.programApplications.student.application.card.denied.info.text", isResubmissionAllowed, index);
            default:
                return "";
        }
    };
    const renderStatusWiseContent = (status, iconTag, note, defaultNote, isResubmissionAllowed, index) => {
        return (_jsxs(_Fragment, { children: [_jsx("span", { className: `status-icon mdi ${iconTag}` }), _jsx("span", Object.assign({ className: `status-text ${status.toLowerCase()}` }, { children: !isNullOrEmpty(note === null || note === void 0 ? void 0 : note.trim()) ? (_jsx(ViewMore, Object.assign({ truncationLimit: isResubmissionAllowed ? 100 : 150, showContent: expandIndex === index, setIndex: () => {
                            if (expandIndex === index) {
                                setExpandIndex(null);
                            }
                            else
                                setExpandIndex(index);
                        } }, { children: note.trim() }))) : (translate(defaultNote)) }))] }));
    };
    const renderEmptyViewForApplications = () => {
        return (_jsxs("section", Object.assign({ className: "empty-applications-view student-empty-app-view" }, { children: [_jsx("span", { className: "mdi mdi-file-document app-icon student-app-icon" }), _jsx("div", Object.assign({ className: "no-apps-label" }, { children: translate("app.programApplications.student.empty.applications") })), _jsx("div", Object.assign({ className: "no-apps-text" }, { children: translate("app.programApplications.student.empty.applications.next.step") })), _jsx(WmButton, Object.assign({ buttonType: "primary", id: "submit-application", onClick: () => {
                        setChooseApplication(true);
                    } }, { children: translate("app.programApplications.student.applications.start.application") }))] })));
    };
    const enterStudentApplication = (event, studentApplication) => {
        event.preventDefault();
        let applicationUuid = studentApplication.application.uuid;
        dispatch(getStudentApplication({ applicationUuid }));
        navigate({
            pathname: "/programApplications/studentApplication/" +
                studentApplication.application.uuid,
        }, {
            state: {
                applicationObj: studentApplication.application,
                sourcePage: "StudentLandingPage",
            },
        });
        if (!(studentApplication.status === "ACCEPTED" ||
            studentApplication.status === "AWAITING_REVIEW")) {
            focusButtonComponent("save-as-draft-btn");
        }
        else {
            focusButtonComponent("close-btn");
        }
    };
    const getApplicationTitle = (studentApplication) => {
        return (_jsx("a", Object.assign({ href: "", "button-type": "textonly", className: "application-card-title", id: "application-title-" + studentApplication.application.uuid, onClick: (event) => enterStudentApplication(event, studentApplication) }, { children: studentApplication.application.title })));
    };
    const renderApplicationCards = () => {
        return (_jsx("section", Object.assign({ className: "application-cards" }, { children: _jsx("div", Object.assign({ className: "wm-grid" }, { children: _jsx("div", Object.assign({ className: "wm-grid-row" }, { children: studentApplicationResponseList.map((studentApplication, index) => {
                        return (_jsx("div", Object.assign({ className: "wm-grid-col-6 wm-grid-col-lg-6 wm-grid-col-xl-9 wm-grid-col-md-10 wm-grid-col-sm-10 wm-grid-col-xs-10" }, { children: _jsxs(Card, Object.assign({ headerTitle: getApplicationTitle(studentApplication), classList: `student-application-card ${expandIndex !== index ? "fixed-card" : ""}` }, { children: [_jsxs("div", Object.assign({ className: "flex-row -justified -verticallycentered label-stamp" }, { children: [_jsxs("span", Object.assign({ className: "status-timestamp" }, { children: [studentApplication.status === "DRAFT"
                                                        ? lastUpdatedLabel
                                                        : lastSubmittedLabel, format(new Date(studentApplication.statusUpdateDate
                                                        ? studentApplication.statusUpdateDate
                                                        : studentApplication.updatedAt), "MMM d, yyyy h:mm a")] })), _jsx("span", Object.assign({ className: `status-label ${studentApplication.status.toLowerCase()}` }, { children: getI18nValueByKey(studentApplication.status, StudentApplicationStatus) }))] })), _jsx("div", Object.assign({ className: "flex-row -justified -verticallycentered status-content" }, { children: getInfoTextByStatus(studentApplication.status, studentApplication.notes, (getValueByKey(studentApplication.status, StudentApplicationStatus) === "ACCEPTED" &&
                                            studentApplication.userRequirementResultUuid !=
                                                null) ||
                                            ((getValueByKey(studentApplication.status, StudentApplicationStatus) === "CONDITIONALLY ACCEPTED" ||
                                                getValueByKey(studentApplication.status, StudentApplicationStatus) === "DENIED") &&
                                                studentApplication.allowResubmission &&
                                                studentApplication.application.status === "OPEN"), index) })), _jsxs("div", Object.assign({ className: "card-btn" }, { children: [getValueByKey(studentApplication.status, StudentApplicationStatus) === "ACCEPTED" &&
                                                studentApplication.userRequirementResultUuid !=
                                                    null && (_jsx(WmButton, Object.assign({ buttonType: "secondary", id: "view-lp-btn-" + studentApplication.uuid, onClick: (event) => redirectToLP(studentApplication.userRequirementResultUuid, studentApplication.uuid) }, { children: translate("app.programApplications.student.view.licensure.plan") }))), (getValueByKey(studentApplication.status, StudentApplicationStatus) === "CONDITIONALLY ACCEPTED" ||
                                                getValueByKey(studentApplication.status, StudentApplicationStatus) === "DENIED") &&
                                                studentApplication.allowResubmission &&
                                                studentApplication.application.status === "OPEN" && (_jsx(WmButton, Object.assign({ buttonType: "secondary", id: "resubmit-app-btn-" + studentApplication.uuid, onClick: (event) => enterStudentApplication(event, studentApplication) }, { children: translate("app.programApplications.student.resubmit.application") })))] }))] })) }), studentApplication.uuid));
                    }) })) })) })));
    };
    return (_jsxs("div", Object.assign({ className: "pagewrapper" }, { children: [_jsx(Header, { activeItem: "programApplication" }), _jsxs("div", Object.assign({ className: "nav-main" }, { children: [_jsx(SideNav, { activeItem: "programApplication" }), _jsxs("div", Object.assign({ className: "contentwrapper" }, { children: [_jsx("main", Object.assign({ id: "maincontent", className: "page-content-card prog-app-landing-page" }, { children: !isFetchingStudetApplicationData &&
                                    !isFetchinStudentApplicationResponseData ? (_jsxs(_Fragment, { children: [_jsxs("section", Object.assign({ className: "paper-card heading-card student-heading-card flex-row -justified -verticallycentered" }, { children: [_jsxs("div", { children: [_jsx("h1", Object.assign({ className: "heading-title" }, { children: translate("app.programApplications.heading") })), _jsx("p", Object.assign({ className: "empty-state-content heading-description" }, { children: translate("app.programApplications.student.info") }))] }), studentApplicationResponseList &&
                                                    studentApplicationResponseList.length > 0 && (_jsx(WmButton, Object.assign({ buttonType: "primary", id: "submit-application", onClick: () => {
                                                        setChooseApplication(true);
                                                    } }, { children: translate("app.programApplications.student.applications.start.application") })))] })), studentApplicationResponseList &&
                                            studentApplicationResponseList.length > 0
                                            ? renderApplicationCards()
                                            : renderEmptyViewForApplications(), _jsx("div", { className: "sr-only", id: "dialog-wrapper-top", tabIndex: 0 }), chooseApplication ? (_jsx(ChooseApplication, { setChooseApplication: setChooseApplication })) : null, _jsx("div", { className: "sr-only", id: "dialog-wrapper-bottom", tabIndex: 0 })] })) : (_jsx(Loading, {})) })), _jsx(Footer, {})] }))] }))] })));
};
export default StudentLandingPage;
