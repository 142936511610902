import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { WmModal, WmModalHeader, WmModalFooter, WmTagInput, WmTagOption, } from "@watermarkinsights/ripple-react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { timelineSelector } from "../../../features/licensurePlans/TimelineSlice";
import Loading from "./Loading";
export default function TagModal(props) {
    const { isFetchingFaculties } = useSelector(timelineSelector);
    const searchText = useIntl().formatMessage({
        id: "app.TagModal.search.text",
    });
    const save = useIntl().formatMessage({
        id: "app.requirement.save",
    });
    const cancel = useIntl().formatMessage({
        id: "app.cohort.secondaryButtonText",
    });
    const primaryClickHandler = (event) => {
        event.preventDefault();
        props.onPrimaryClick(event);
    };
    const getSelectedTags = () => {
        let selectedTagList = props.selectedTags.map((tag) => "user-" + tag);
        return selectedTagList.toString();
    };
    const personClickHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();
        props.onPersonClick(event);
    };
    const modalContent = (userList) => {
        return (_jsx("div", Object.assign({ id: "tag-modal-body", className: "body tag-modal-body" }, { children: isFetchingFaculties ? (_jsx(Loading, {})) : (_jsx(WmTagInput, Object.assign({ id: "searchbox", className: "taginput", tagInputType: "table", placeholder: props.selectedTags.length < 10 ? searchText : "", label: props.modalHeading, "label-position": "none", colHeaders: "Name,Email", colWidths: "50%,50%", colWrap: "wrap,trunc", maxTags: 10, "message-config": '{"maxTagsReached": "No more faculties can be added because the limit has been reached", "tagAreaInstructions": "faculty selection. Press Backspace or Delete to remove a faculty", "tagsAdded":"Faculty added"}' }, { children: userList.length > 0 &&
                    (userList === null || userList === void 0 ? void 0 : userList.map((user) => {
                        return (_jsx(WmTagOption, { id: "user-" + user.uuid, col1: `${user.firstName} ${user.lastName}`, col2: `${user.email}`, onWmTagOptionSelected: (event) => {
                                if (props.selectedTags.length < 10) {
                                    event.target.selected = true;
                                    event.target.addNew = true;
                                    personClickHandler(event);
                                }
                                else {
                                    event.target.addNew = false;
                                }
                            }, selected: props.selectedTags.includes(user.uuid), onWmTagOptionDeselected: (event) => {
                                event.target.selected = false;
                                personClickHandler(event);
                            } }, user.uuid));
                    })) }))) })));
    };
    if (!props.isOpen) {
        return _jsx(_Fragment, {});
    }
    else {
        return (_jsxs(WmModal, Object.assign({ open: props.isOpen, id: props.modalId, elementToFocus: "searchbox", "aria-label": props.ariaLabelValue, onWmModalCloseTriggered: props.onSecondaryClick, onWmModalPrimaryTriggered: primaryClickHandler, onWmModalSecondaryTriggered: props.onSecondaryClick, uid: "" }, { children: [_jsx(WmModalHeader, { heading: props.modalHeading, subheading: props.subHeading }), modalContent(props.contentList), _jsx(WmModalFooter, { primaryText: save, secondaryText: cancel })] })));
    }
}
