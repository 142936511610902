import translate from "../../../i18n/translate";

export const SmallTextFieldType = [
    {
      value_i18n: translate("app.programApplications.small.text.field.component.type.standard"),
      key: "STANDARD",
      value: "STANDARD",
    },
    {
      value_i18n: translate("app.programApplications.small.text.field.component.type.email"),
      key: "EMAIL",
      value: "EMAIL",
    },
    {
      value_i18n: translate("app.programApplications.small.text.field.component.type.phone.number"),
      key: "PHONE_NUMBER",
      value: "PHONE_NUMBER",
    },

]