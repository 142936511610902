export const openFlyout = (flyoutId, focusAfterClosed, focusFirst) => {
  window.requestAnimationFrame(() => {
    let topFlyout = wmrk.getCurrentFlyout();
    if (
      typeof topFlyout == "undefined" ||
      topFlyout.flyoutNode.id != flyoutId
    ) {
      let flyout = new wmrk.Flyout(flyoutId, focusAfterClosed, focusFirst);
    }
  });
};

export const navigateToElement = (id) => {
  window.requestAnimationFrame(() => {
    let element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  });
};

export const closeFlyout = () => {
  window.requestAnimationFrame(() => {
    let topFlyout = wmrk.getCurrentFlyout();

    if (typeof topFlyout != "undefined" && topFlyout != null) {
      topFlyout.close();
    }
  });
};

export const focusButtonComponent = (id) => {
  wmrk.Utils.focusButtonComponent(id);
};

export const focusActionMenuComponent = (id) => {
  wmrk.Utils.focusActionMenuComponent(id);
};

export const focusUploaderComponentButton = (id) => {
  wmrk.Utils.focusUploaderComponentButton(id);
};

export const toggleNav = () => {
  wmrk.toggleNav();
};

export const debounce = (...params) => {
  return wmrk.Utils.debounce(...params);
};

function handleAdministratorsKeyEvt(evt) {
  let nextId = wmrk.Utils.handleAssessmentLeadsKeyEvt(evt);
  if (nextId !== undefined && nextId !== "") {
    let nextIdElement = document.getElementById(nextId);
    nextIdElement.focus();
  }
}

export const handleAdministratorKeydown = () => {
  let searchInput;
  let container;
  let candidates;
  let tagContainer;

  window.requestAnimationFrame(() => {
    searchInput = document.getElementById("searchbox");
    container = document.getElementById("suggestedcandidates");
    candidates = container.querySelectorAll(".person");
    tagContainer = document.getElementById("non-editable-leads-tag");
    let tags = tagContainer.querySelectorAll(".tag");
    if (container) {
      container.addEventListener("mouseout", unfocus);
      wmrk.Utils.forEach(candidates, function (i, val) {
        candidates[i].addEventListener(
          "keydown",
          handleAdministratorsKeyEvt,
          true
        );
      });
    }

    if (searchInput) {
      searchInput.removeEventListener(
        "keydown",
        handleAdministratorsKeyEvt,
        true
      );
      searchInput.addEventListener("keydown", handleAdministratorsKeyEvt, true);

      container.addEventListener("mouseout", unfocus);
    } else {
      wmrk.Utils.handleKeyDownWithMaxAssessmentLeads();
    }
  });

  function unfocus() {
    container.removeAttribute("aria-activedescendant");
  }
};

export const focusNextListItem = (direction) => {
  const DOWN_ARROW_KEY_CODE = 40;
  const UP_ARROW_KEY_CODE = 38;
  const listItems = document.querySelectorAll(".dropdown-list-item");
  const listItemIds = [];
  listItems.forEach((item) => listItemIds.push(item.id));

  const activeElementId = document.activeElement.id;
  if (activeElementId === "dropdown__selected") {
    document.querySelector(`#${listItemIds[0]}`).focus();
  } else {
    const currentActiveElementIndex = listItemIds.indexOf(activeElementId);
    if (direction === DOWN_ARROW_KEY_CODE) {
      const currentActiveElementIsNotLastItem =
        currentActiveElementIndex < listItemIds.length - 1;
      if (currentActiveElementIsNotLastItem) {
        const nextListItemId = listItemIds[currentActiveElementIndex + 1];
        document.querySelector(`#${nextListItemId}`).focus();
      }
    } else if (direction === UP_ARROW_KEY_CODE) {
      const currentActiveElementIsNotFirstItem = currentActiveElementIndex > 0;
      if (currentActiveElementIsNotFirstItem) {
        const nextListItemId = listItemIds[currentActiveElementIndex - 1];
        document.querySelector(`#${nextListItemId}`).focus();
      }
    }
  }
};

export const isNullOrEmpty = (value) => {
  if (value != null && value != "" && value != "undefined") {
    return false;
  } else {
    return true;
  }
};

export const getWordCount = (str) => {
  return str.split(" ").filter((num) => {
    return num != "";
  }).length;
};

export const updateClassList = (parentId, childId, isfocused) => {
  const el = document.getElementById(parentId);
  const elChild = document.getElementById(childId);
  if (el != null && elChild != null) {
    if (isfocused) {
      el.style.paddingBottom = "38px";
      elChild.style.border = "none";
      elChild.style.boxShadow = "none";
      el.style.boxShadow = "0 0 0 1px rgb(32, 203, 212)";
      el.style.borderColor = "rgb(32, 203, 212)";
    } else {
      el.style.paddingBottom = "0";
      elChild.style.boxShadow = "none";
      el.style.boxShadow = "none";
      el.style.border = "1px solid rgba(35, 35, 35, 0.6)";
    }
  }
};

export const urlPatternValidation = (url) => {
  const regex = new RegExp(
    "^((ftp|https?):\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))|" + // OR ip (v4) address
      "localhost" + // OR localhost
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return regex.test(url);
};

export const getLink = (url) => {
  return url.indexOf("://") != -1 &&
    url.substring(0, url.indexOf("://")) != null
    ? url
    : "http://" + url;
};

export const validateEmail = (email) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export const validatePhoneNumber = (phoneNumber) => {
  const regex = /^[0-9][-\_/0-9]*$/g;
  return regex.test(phoneNumber);
};

export const getMessageForActivityTime = (
  diffFromNow,
  lessThanMintAgo,
  mintAgo,
  mintsAgo,
  hourAgo,
  hoursAgo
) => {
  if (diffFromNow == 0) {
    return lessThanMintAgo;
  } else if (diffFromNow === 1) {
    return `${diffFromNow} ${mintAgo}`;
  } else if (diffFromNow < 60) {
    return `${diffFromNow} ${mintsAgo}`;
  } else if (diffFromNow > 1440) {
    return "";
  } else {
    const timeGapHrs = Math.floor(diffFromNow / 60);
    return timeGapHrs == 1
      ? `${timeGapHrs} ${hourAgo}`
      : `${timeGapHrs} ${hoursAgo}`;
  }
};

export const toHoursAndMinutes = (totalMinutes) => {
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);

  return `${padTo2Digits(hours)}h ${padTo2Digits(minutes)}m`;
};

const padTo2Digits = (num) => {
  return num.toString().padStart(2, "0");
};

//Check if a value is a UUID.
 export const isValidUuid = (value) =>{
    const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
    return uuidPattern.test(value);
  }
