import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./app/store";
import { IntlProvider } from "react-intl";
import ENGLISH from "./i18n/languages/en-US.json";
function render() {
    const App = require("./app/App").default;
    const rootElement = document.getElementById("root");
    if (!rootElement)
        throw new Error("Failed to find the root element");
    const root = ReactDOM.createRoot(rootElement);
    root.render(_jsx(Provider, Object.assign({ store: store }, { children: _jsx(IntlProvider, Object.assign({ locale: "en-US", messages: ENGLISH }, { children: _jsx(App, {}) })) })));
}
render();
if (process.env.NODE_ENV === "development" && module.hot) {
    module.hot.accept("./app/App", render);
}
