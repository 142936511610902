import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
const DraggableItem = (props) => {
    const dragStartHandler = (event) => {
        var _a;
        event.stopPropagation();
        event.dataTransfer.effectAllowed = "move";
        (_a = event.dataTransfer) === null || _a === void 0 ? void 0 : _a.setData("uuid", props.object.uuid);
        let ghostElement = document.getElementById("ghost-element-container");
        if (ghostElement !== null) {
            let firstChild = ghostElement.firstChild;
            if (firstChild !== null) {
                firstChild.innerHTML = props.object.title;
            }
            ghostElement.style.position = "absolute";
            ghostElement.style.zIndex = "2000";
            ghostElement.style.left = event.clientX + 2 + "px";
            ghostElement.style.top = event.clientY + 2 + "px";
            ghostElement.style.display = "flex";
            ghostElement.style.visibility = "hidden";
            setTimeout(() => {
                if (ghostElement !== null) {
                    ghostElement.style.visibility = "visible";
                }
            }, 1);
            event.dataTransfer.setDragImage(ghostElement, -10000, -10000);
        }
        setTimeout(() => {
            props.setDraggedItem(props.object.uuid);
            props.setDraggedEnterItem(props.object.uuid);
            props.setIsDragStarted(true);
            props.setCategory(props.categoryType);
        }, 10);
    };
    const dragOverHandler = (event) => {
        event.stopPropagation();
        event.preventDefault();
        let ghostElement = document.getElementById("ghost-element-container");
        if (ghostElement !== null) {
            ghostElement.style.left = event.clientX + 2 + "px";
            ghostElement.style.top = event.clientY + 2 + "px";
        }
    };
    const hideGhostElement = () => {
        let ghostElement = document.getElementById("ghost-element-container");
        if (ghostElement !== null) {
            ghostElement.style.display = "none";
        }
    };
    const dragEndHandler = (event) => {
        props.setIsDragStarted(false);
        hideGhostElement();
    };
    const dragEnterHandler = (event) => {
        props.setDraggedEnterItem(props.object.uuid);
    };
    const dropHandler = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (props.draggedItem !== props.draggedEnterItem &&
            dropAllowed() &&
            !(event.dataTransfer.getData("uuid") === undefined ||
                event.dataTransfer.getData("uuid").trim() === "")) {
            props.changePosition(event.dataTransfer.getData("uuid"), props.object.uuid, false, "", true);
        }
        props.setDraggedItem("");
        props.setDraggedEnterItem("");
        hideGhostElement();
        props.setIsDragStarted(false);
    };
    const DropAtTopHandler = (event) => {
        event.stopPropagation();
        event.preventDefault();
        props.setDraggedItem("");
        props.setDraggedEnterItem("");
        props.setIsDragStarted(false);
        if (dropAllowed() &&
            !(event.dataTransfer.getData("uuid") === undefined ||
                event.dataTransfer.getData("uuid").trim() === ""))
            props.changePosition(event.dataTransfer.getData("uuid"), props.object.uuid, true, "", true);
        hideGhostElement();
    };
    const dropAllowed = () => {
        if (props.numberOfElements === undefined ||
            props.maxElementsAllowed === undefined) {
            return true;
        }
        return !(props.numberOfElements &&
            props.maxElementsAllowed &&
            props.numberOfElements >= props.maxElementsAllowed);
    };
    let hideElement = false;
    if (!props.isElementReoderUsingKeyboard &&
        props.draggedItem !== "" &&
        props.draggedItem === props.object.uuid) {
        hideElement = true;
    }
    let showDropZone = false;
    if ((props.draggedEnterItem === props.object.uuid ||
        (props.draggedEnterItem === props.draggedItem &&
            props.draggedItem === props.object.uuid)) &&
        dropAllowed() &&
        props.isDragStarted) {
        showDropZone = true;
    }
    return (_jsxs("div", Object.assign({ id: props.object.uuid, draggable: true, onDragStart: dragStartHandler, onDragOver: dragOverHandler, onDragEnd: dragEndHandler, onDragEnter: dragEnterHandler, onDrop: dropHandler }, { children: [!hideElement ? (_jsx("div", Object.assign({ className: `draggable-element content ${props.isDragStarted ? "drag-started" : ""} ${props.isElementReoderUsingKeyboard &&
                    props.keysDraggedEnterItem === props.object.uuid
                    ? "keyboard-grabbed"
                    : "no-keyboard-grab"}` }, { children: props.children }))) : (""), _jsx("div", { className: `drop-zone ${props.categoryType == props.category && showDropZone
                    ? "isDragged"
                    : ""} ${props.categoryType === "section" ? "section-drop-zone" : ""}` })] }), props.draggedEnterItem + props.keysDraggedEnterItem));
};
export default DraggableItem;
