import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmModal, WmModalHeader, WmModalFooter, } from "@watermarkinsights/ripple-react";
export default function DiscardModal(props) {
    if (!props.isOpen) {
        return _jsx(_Fragment, {});
    }
    else {
        return (_jsxs(WmModal, Object.assign({ open: props.isOpen, modalType: "dialog", id: props.modalId, elementToFocus: "secondary", "aria-label": props.ariaLabelValue, onWmModalCloseTriggered: props.onClose, onWmModalPrimaryTriggered: props.onPrimaryClick, onWmModalSecondaryTriggered: props.onSecondaryClick, uid: "" }, { children: [_jsx(WmModalHeader, { heading: props.modalHeading }), _jsx("div", Object.assign({ id: props.contentId, className: "body" }, { children: props.modalContent })), _jsx(WmModalFooter, { primaryText: props.primaryText, secondaryText: props.secondayText, deleteStyle: false })] })));
    }
}
