import { jsx as _jsx } from "react/jsx-runtime";
import "./App.scss";
import { BrowserRouter, } from "react-router-dom";
import { defineCustomElements, applyPolyfills, } from "@watermarkinsights/ripple/dist/loader";
import ApplicationRoutes from "./ApplicationRoutes";
export default function App() {
    applyPolyfills().then(() => {
        defineCustomElements();
        global.Buffer = global.Buffer || require("buffer").Buffer;
    });
    return (_jsx("div", { children: _jsx(BrowserRouter, { children: _jsx(ApplicationRoutes, {}) }) }));
}
