import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import translate from "../../i18n/translate";
import { useIntl } from "react-intl";
export default function StudentCohortCards(props) {
    const multiCohorts = useIntl().formatMessage({
        id: "app.licensurePlanLandingPage.tab.cards.multiCohorts",
    });
    const singleCohort = useIntl().formatMessage({
        id: "app.licensurePlanLandingPage.tab.cards.singleCohort",
    });
    return (_jsxs("div", { children: [_jsx("h2", Object.assign({ className: "licensure-plan-label" }, { children: props.studentCohorts.length > 1 ? multiCohorts : singleCohort })), _jsx("div", Object.assign({ className: "wm-grid student-cohorts-grid" }, { children: _jsx("div", Object.assign({ className: "wm-grid-row" }, { children: props.studentCohorts.map((cohort) => {
                        return (_jsx("div", Object.assign({ className: "wm-grid-col-4 wm-grid-col-4 wm-grid-col-lg-4 wm-grid-col-xl-5  wm-grid-col-md-5 wm-grid-col-sm-5 cohort-details-container " }, { children: _jsxs("div", Object.assign({ className: "paper-card -card", tabIndex: 0 }, { children: [_jsx("div", Object.assign({ className: "textfields" }, { children: cohort.programName })), _jsx("div", Object.assign({ className: "title" }, { children: cohort.cohortName })), _jsxs("div", Object.assign({ className: "cohort-contents" }, { children: [_jsxs("div", Object.assign({ className: "field-data" }, { children: [_jsxs("div", Object.assign({ className: "textfieldlabel" }, { children: [translate("app.cohort.entryTerm"), ":", " "] })), _jsx("div", Object.assign({ className: "textfieldvalue" }, { children: cohort.entryTerm }))] })), cohort.projectedEndTerm ? (_jsxs("div", Object.assign({ className: "field-data" }, { children: [_jsxs("div", Object.assign({ className: "textfieldlabel" }, { children: [translate("app.cohort.projectedEndTerm"), ":", " "] })), _jsxs("div", Object.assign({ className: "textfieldvalue" }, { children: [" ", cohort.projectedEndTerm] }))] }))) : ("")] }))] })) }), "program" + cohort.cohortUuid));
                    }) })) }))] }));
}
