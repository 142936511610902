var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import translate from "../../i18n/translate";
import { WmOption, WmSelect, WmButton } from "@watermarkinsights/ripple-react";
import { useIntl } from "react-intl";
import { requirementResultStatus } from "./data/RequirementResultStatus";
import { getI18nValueByKey, getValueByKey } from "../common/commonActions";
import { openFlyout, focusButtonComponent } from "../../static/js/commons.js";
import { changeBulkStatus } from "./CohortSlice";
import { useDispatch } from "react-redux";
export default function ChangeStudentStatusView({ removeChangeStatusView, changeStatusHandler, currentSubmission, programUuid, }) {
    const selectNewStatus = useIntl().formatMessage({
        id: "app.openStudentReview.selectNewStatus",
    });
    const charEntered = useIntl().formatMessage({
        id: "app.bulkStatusReview.charEntered",
    });
    const charLimit = useIntl().formatMessage({
        id: "app.bulkStatusReview.charLimit",
    });
    let [statusError, setStatusError] = useState(false);
    const [newStatus, setNewStatus] = useState("Select Option");
    let [enteredNote, setEnteredNote] = useState("");
    let [isFocused, setFocused] = useState(null);
    const dispatch = useDispatch();
    const focusHandler = (event, isfocus) => {
        event.preventDefault();
        if (isfocus) {
            let temp_value = event.target.value;
            event.target.value = "";
            event.target.value = temp_value;
        }
        setFocused(isfocus);
    };
    useEffect(() => {
        focusButtonComponent("close-change-status-btn");
        setTimeout(() => openFlyout("view-changestatus-flyout", "change-status-btn", "close-change-status-btn"), 200);
    }, []);
    const updateNewStatusHandler = (event, value) => {
        event.preventDefault();
        if (value !== "Select Option") {
            setStatusError(false);
        }
        setNewStatus(value);
    };
    const updateNoteHandler = (event) => {
        event.preventDefault();
        setEnteredNote(event.target.value);
    };
    const closeFlyout = () => {
        removeChangeStatusView();
    };
    const changeStatus = (event) => __awaiter(this, void 0, void 0, function* () {
        event.preventDefault();
        if (newStatus === "Select Option") {
            setStatusError(true);
        }
        if (newStatus !== "Select Option") {
            let orgUuid = localStorage.getItem("orgUuid");
            let userUuid = [currentSubmission.userUuid];
            removeChangeStatusView();
            yield Promise.all([
                dispatch(changeBulkStatus({
                    orgUuid,
                    programUuid: programUuid,
                    cohortUuid: currentSubmission.cohortUuid,
                    note: enteredNote,
                    newStatus,
                    requirementUuid: currentSubmission.requirementUuid,
                    users: userUuid.map((uuid) => {
                        return { uuid };
                    }),
                })),
            ]);
            changeStatusHandler(newStatus);
        }
    });
    return (_jsxs("div", Object.assign({ className: "flyout-panel half-flyout show", role: "dialog", id: "view-changestatus-flyout", tabIndex: -1, "aria-label": "Change Status Flyout" }, { children: [_jsx("div", { className: "overlay" }), _jsxs("div", Object.assign({ className: "container" }, { children: [_jsxs("section", Object.assign({ className: "immersive-header header change-status-header flexible-header" }, { children: [_jsx("div", Object.assign({ className: "title" }, { children: translate("app.openStudentReview.change.status") })), _jsx(WmButton, { buttonType: "navigational", icon: "close", tooltip: "Cancel", tooltipPosition: "bottom", id: "close-change-status-btn", class: "hydrated", onClick: removeChangeStatusView })] })), _jsx("section", Object.assign({ className: "content" }, { children: _jsxs("div", Object.assign({ className: "content-card" }, { children: [_jsxs("div", Object.assign({ className: "required-field" }, { children: [translate("app.cohort.requiredText.prefix"), _jsx("span", Object.assign({ className: "reqd" }, { children: "*" })), translate("app.cohort.requiredText.suffix")] })), _jsx("div", { children: _jsxs(WmSelect, Object.assign({ label: "New Status", requiredField: true, errorMessage: statusError ? selectNewStatus : "" }, { children: [_jsx(WmOption, Object.assign({ "aria-label": "Select Option", selected: newStatus === "Select Option", onWmOptionSelected: (event) => updateNewStatusHandler(event, "Select Option") }, { children: translate("app.requirement.file_type_select_option") }), "option" + "Select Option"), requirementResultStatus
                                                .map((resultStatus) => resultStatus.key)
                                                .map((status) => {
                                                return (_jsx(_Fragment, { children: status !== "NOT_SUBMITTED_DRAFT" ? (_jsx(WmOption, Object.assign({ "aria-label": getValueByKey(status, requirementResultStatus), selected: newStatus === status, onWmOptionSelected: (event) => updateNewStatusHandler(event, status) }, { children: getI18nValueByKey(status, requirementResultStatus) }), "option" + status)) : ("") }));
                                            })] })) }), _jsxs("div", Object.assign({ className: "status-block" }, { children: [_jsx("label", Object.assign({ className: "textfieldlabel ", htmlFor: "notes-info" }, { children: translate("global.note") })), _jsx("div", Object.assign({ className: isFocused ? "textfield is-focused" : "textfield" }, { children: _jsxs("div", Object.assign({ className: "charcount -textarea" }, { children: [_jsx("textarea", { className: "textfieldinput autosize-textarea", id: "notes-info", value: enteredNote, maxLength: 500, "aria-describedby": "field-notes", onChange: updateNoteHandler, onFocus: (event) => focusHandler(event, true), onBlur: (event) => focusHandler(event, false), "aria-label": "Note" }), _jsx("div", Object.assign({ className: "count", "aria-live": "polite", "aria-atomic": true, "aria-label": enteredNote.length < 500 ? charEntered : charLimit }, { children: String(enteredNote.length) + "/500" })), _jsx("span", Object.assign({ id: "field-notes", className: "max-count-label" }, { children: translate("app.bulkStatusReview.noteChar") }))] })) }))] })), _jsx("span", Object.assign({ className: "helper-text" }, { children: translate("app.cohort.notesHelperText") }))] })) })), _jsxs("div", Object.assign({ className: "change-status-flyout-footer" }, { children: [_jsx(WmButton, Object.assign({ buttonType: "secondary", id: "close-flyout-button", onClick: closeFlyout }, { children: translate("app.cohort.secondaryButtonText") })), _jsx(WmButton, Object.assign({ buttonType: "primary", id: "change-statuses-button", onClick: changeStatus }, { children: translate("app.cohort.saveButtonText") }))] }))] }))] })));
}
